import React from "react";
import {
  Container,
  Button,
  // Customizable Area Start
  Typography,
  Box,
  TextField,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Carousel from "react-material-ui-carousel";
import "../../productdescription/src/ProductDescription.css";
import Loader from "../../../components/src/Loader.web";
import { profile ,leftArrowIconWhite, wallpaper} from "./assets";
import moment from "moment";
import { Allstyle} from "../../favourites/src/FavouritesWeb.web";
import CommunityForumController, {
  Props,ICommunityObject
} from "./CommunityForumController";
import { ButtonStyle,AutocompleteComponent } from "../../../blocks/categoriessubcategories/src/Categories.web";
// Customizable Area End

export default class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start

    super.componentDidMount();
    this.alldata();
    this.getAnimalData()

    // Customizable Area End
  }

  alldata = async () => {
    this.getAllUpadatedPost_Api();
  };

  renderPostFlatlistCell = (item: ICommunityObject, index: number) => {
    const timeSlot = moment(item && item.attributes?.created_at).fromNow();

    return (
      <Card key={index} style={webStyle.card}>
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar src={profile} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6">
                    {item && item.attributes?.account?.attributes?.user_name}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {timeSlot}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{item && item.attributes?.name}</Typography>
              <Typography>{item && item.attributes?.description}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box>
              
              <Loader loading={this.state.isLoading } />
                <ImaegStyle >
                  <img src={this.state.chatRoomBannerImage || wallpaper} style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                <HomeGrid2 >

      <Box style={{ ...Allstyle.headingBoxStyle,alignItems:"center" }}>
        <Typography style={{ ...Allstyle.hedingStyle, paddingRight: "6px", cursor: "pointer" ,color:"#FFF"}}
        data-test-id="navigate"
         onClick={() => {
          this.props.navigation.navigate("LandingPageWeb")
        }}>
          Home
        </Typography>
      
<img src={leftArrowIconWhite}/>
        <Typography
          style={{
            ...Allstyle.hedingStyle,
            color:"#FFF",
            paddingInline: "6px",
          }}
        >
         Community Chat Room 
        </Typography>
      </Box>
      <HeadingBox
        style={{
          ...Allstyle.headingBoxStyle,
          justifyContent: "space-between",
          
        }}
      >
        <MainHeadingStyle style={{fontWeight:700  ,}}>
        Community Chat Room 
        </MainHeadingStyle>
     
       </HeadingBox>
                </HomeGrid2>
                </ImaegStyle>
                <AutocompleteBox >
        <AutocompleteComponent
          freeSolo
          id="free-solo"
          style={{background:"white",borderRadius:"8px"}}
          options={[]}
          data-test-id='searchInputOuter'
          onChange={(e:any)=>{
            this.setState({serachTopic:e.target.value})
          }}
          onKeyDown={(e:any)=>{this.handleForSearchEnterKey(e)}}
          renderInput={(params: any) => (
            <TextFieldCustom
              {...params}
              variant="outlined"
              placeholder="Search topics by keyword"
              margin="normal"
              data-test-id='searchInput'
              
              onChange={(e:any)=>{
                this.setState({serachTopic:e.target.value})
              }}
              InputProps={{
                ...params.InputProps, type: 'search', startAdornment: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                      fill="#94A3B8"
                    />
                  </svg>
                ),
              }}
            />
          )}
        />

        <ButtonStyle variant="contained" data-test-id='searchButton' style={{fontFamily:"Inter", borderRadius: "8px" ,minWidth:"119px"}}  onClick={()=>{
          if(this.state.serachTopic!==""){
            
            this.props.navigation.navigate("PetootiCommunityFormTopic" ,{ searchTopic: this.state.serachTopic })
          }
  }}>
          Search
        </ButtonStyle>
      </AutocompleteBox>
              <HomeGrid >
   
      <Box >
        <ButtonSearchTopic >
          <ButtonSearch740ABLE>

        <Button variant="contained"
data-test-id="Show_all_topics2" style={{
 color:"#0A2239",
 fontSize:"16px",
 fontWeight:600,
 lineHeight:"24px",
 boxShadow:"unset",
 height:"56px",
 width:"189px",
 borderRadius:"8px",
 textTransform:"inherit",
 fontFamily:"Inter",
 minWidth:"189px",
 background:"#E8D658",

}} onClick={()=>{
  

  this.props.navigation.navigate("PetootiCommunityFormTopic")
}}>
  <span style={{marginRight:"8px"}}>
  Show all topics
  </span>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.00001 15.8766L12.88 11.9966L9.00001 8.11656C8.81275 7.92973 8.70752 7.67608 8.70752 7.41156C8.70752 7.14704 8.81275 6.89339 9.00001 6.70656C9.39001 6.31656 10.02 6.31656 10.41 6.70656L15 11.2966C15.39 11.6866 15.39 12.3166 15 12.7066L10.41 17.2966C10.02 17.6866 9.39001 17.6866 9.00001 17.2966C8.62001 16.9066 8.61001 16.2666 9.00001 15.8766Z" fill="#0A2239"/>
</svg>
</Button>
          </ButtonSearch740ABLE>

<Typography style={{
   fontSize:"36px",
   fontWeight:700,
   fontFamily:"Cardenio Modern",
   lineHeight:"44px",
   letterSpacing:"0.72px",
   textAlign:"center",
  color:"#074F57"
}}>
Search topics by <span style={{
   color:"#FF5E5B"
}}>
  animal type
  </span>
</Typography>
<ButtonSearch740DISABLE>

<Button variant="contained"
data-test-id="Show_all_topics" style={{
 color:"#0A2239",
 height:"56px",
 width:"189px",
 minWidth:"189px",
 background:"#E8D658",
 borderRadius:"8px",
 textTransform:"inherit",
 fontFamily:"Inter",
 fontSize:"16px",
 fontWeight:600,
 lineHeight:"24px",
 boxShadow:"unset"

}} onClick={()=>{
  

  this.props.navigation.navigate("PetootiCommunityFormTopic")
}}>
  <span style={{marginRight:"8px"}}>
  Show all topics
  </span>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M9.00001 15.8766L12.88 11.9966L9.00001 8.11656C8.81275 7.92973 8.70752 7.67608 8.70752 7.41156C8.70752 7.14704 8.81275 6.89339 9.00001 6.70656C9.39001 6.31656 10.02 6.31656 10.41 6.70656L15 11.2966C15.39 11.6866 15.39 12.3166 15 12.7066L10.41 17.2966C10.02 17.6866 9.39001 17.6866 9.00001 17.2966C8.62001 16.9066 8.61001 16.2666 9.00001 15.8766Z" fill="#0A2239"/>
</svg>
</Button>
</ButtonSearch740DISABLE>
        </ButtonSearchTopic>
<BoxStyle style={{marginTop:"40px",justifyContent:"center",gap:"23px",marginBottom:"124px",flexWrap:"wrap"}}>
{ 
 this.state.animalData.map((item:any,index:number)=>{
  const animalInfo = this.state.allAnimalData.find(animal => animal.name === item.name);
    return <ImageBox  key={`${item.name}${index}`}    data-test-id={`mouseEnter${index}`} onMouseEnter={() => this.handleMouseEnter(item?.name)}
    onMouseLeave={() => this.handleMouseLeave(item?.name)}>
      <img 
        src={this.state.setHovered[item?.name] ? animalInfo.imageSrcFilled : animalInfo.imageSrc}
      style={{paddingBottom:"10px",width:"100px",height:"100px"}} 
      data-test-id="petname" onClick={()=>{
        

this.props.navigation.navigate("PetootiCommunityFormTopic",{ petName: item?.id })
  }}/>
      <Typography style={{
        color:"#0A2239",
        textAlign:"center",
        paddingBottom:"16px",
        fontFamily:"Cardenio Modern",
        fontWeight:700,
        fontSize:"20px",
        lineHeight:"28px",
        letterSpacing:"0.4px"
      }}>
        {item.name}
      </Typography>
    </ImageBox>
  })
}

</BoxStyle>
<AllAnimalCarousel className="parent-element">
<Carousel  className="Carousel-root">
{ 
 this.state.animalData.map((item:any,index:number)=>{
  const animalInfo = this.state.allAnimalData.find(animal => animal.name === item.name);
    return <ImageBox  
    key={`${item.name}${index}`}   
    onMouseLeave={() => this.handleMouseLeave(item?.name)}
     data-test-id={`mouseEnter${index}`} onMouseEnter={() => this.handleMouseEnter(item?.name)}
    >
      
      <img 
      style={{paddingBottom:"10px",width:"100px",height:"100px"}} 
        src={this.state.setHovered[item?.name] ? animalInfo.imageSrcFilled : animalInfo.imageSrc}
        onClick={()=>{
  this.props.navigation.navigate("PetootiCommunityFormTopic",{ petName: item?.id })
    }}
      data-test-id="petname" 
  />
      <Typography style={{
        color:"#0A2239",
        fontWeight:700,
        fontSize:"20px",
        lineHeight:"28px",
        paddingBottom:"16px",
        fontFamily:"Cardenio Modern",
        textAlign:"center",
        letterSpacing:"0.4px"
      }}>
        {item.name}
      </Typography>
    </ImageBox>
  })
}
  </Carousel>

</AllAnimalCarousel>

      </Box>
       </HomeGrid>
              
</Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const HomeGrid = styled(Grid)({
  marginTop: "48px",
  marginInline:"auto",
  maxWidth: "1168px",
  minWidth: "1168px",
  '@media(max-width: 1440px)': {
    marginInline: "135px",
  },
  '@media(max-width: 1400px)': {
    maxWidth: "unset",
  marginInline: "20px",
  minWidth:"unset"

  },
 
  '@media(max-width: 400px)': {
    maxWidth: "unset",
    marginInline: "10px",
  minWidth:"unset"

  },

})

const TextFieldCustom = styled(TextField)({
    "& .MuiInputBase-input::placeholder" :{
        color: "#94A3B8",
        opacity: 1,
  }
})

const ImageBox=styled(Box)({
  width:"175px",height:"180px",
  cursor:"pointer",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
  background: "rgba(232, 214, 88, 0.02)",

boxShadow:"0px 4px 12px 0px rgba(0, 0, 0, 0.16)",

   borderRadius:"16px",
   "&:hover": {
  background: "var(--Primary-color-5, #E8D658)",

   }
})
const HeadingBox=styled(Box)({
  marginTop: "24px",
  '@media(max-width: 800px)': {
   marginTop:"unset"
   },

})
const ButtonSearchTopic=styled(Box)({
  paddingTop:"25px",
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  '@media(max-width: 740px)': {
   flexDirection:"column",
   gap:"20px"
    },
    '@media(max-width: 417px)': {
      flexDirection:"column",
   gap:"20px",
  paddingTop:"15px",


    }

})
const BoxStyle=styled(Box)({
  display:"flex",
  '@media(max-width: 412px)': {
    display:"none"
      },
})
const AllAnimalCarousel = styled(Box)({
  justifyContent:"center",
  display:"flex",
  marginBottom:"30px",
  "@media (min-width:413px)":{
    display:"none"
     
   }
})
const ButtonSearch740ABLE=styled(Box)({
  display:"none",
  '@media( max-width: 740px)': {
  display:"block"
    },

})
const ButtonSearch740DISABLE=styled(Box)({
  display:"block",
  '@media(max-width: 740px)': {
  display:"none"
    },

})
const MainHeadingStyle=styled(Typography)({
  fontSize:"60px !important",
  lineHeight:"130% !important",  
  fontFamily:"Cardenio Modern !important" ,
  color:"#FFF",

'@media(max-width: 800px)': {
  fontSize:"30px !important",
  },
})
const HomeGrid2 = styled(Grid)({
  marginInline:"auto",
  maxWidth: "1168px",
  position:"absolute",
  top:48,
  left:142,
  '@media(max-width: 1440px)': {
   left:"unset",
  minWidth: "unset",

    marginInline: "135px",
  },
  // '@media(max-width: 1430px)': {
  //  left:"unset",
  //  minWidth: "unset",

  //   maxWidth: "unset",
  // marginInline: "60px",
  // },
  '@media(max-width: 1430px)': {
   left:"unset",

    maxWidth: "unset",
  marginInline: "20px",
  minWidth:"unset"
  },
  '@media(max-width: 400px)': {
   left:"unset",

    maxWidth: "unset",
    marginInline: "10px",
  minWidth:"unset"

  },

})
const AutocompleteBox = styled(Box)({
  display: "flex", 
  paddingTop: "46px",
justifyContent: "center", 
alignItems: "center",
paddingBottom:"46px",
backgroundColor:"#074F57",
  '@media(max-width: 800px)': {
  paddingInline:"30px",
  },

})
export const ImaegStyle2=styled(Box)({
  position:"relative",
  
  width:"100%",
  minWidth:"360px",
  height:"565px",
  maxWidth:"100%" ,
  '@media ( max-width:1440px )': {
    height:"565px",
    maxWidth:"1440px" ,
  },
 
 '@media ( max-width:800px )': {
  maxWidth:"1440px" ,
  height:"253px"
},
'@media ( max-width:500px )': {
  maxWidth:"1440px" ,
  height:"150px"
},
})
const ImaegStyle=styled(Box)({
  position:"relative",
  
  width:"100%",
  height:"700px",

  minWidth:"360px",
  '@media ( max-width:2200px )': {
    height:"650px",
    },
  '@media ( max-width:2000px )': {
    height:"600px",
    },
    '@media ( max-width:1800px )': {
      height:"550px",
    },
    '@media ( max-width:1650px )': {
      height:"500px",
    },
      '@media ( max-width:1440px )': {
    height:"448px",
    maxWidth:"1440px" ,
  },
 
 '@media ( max-width:800px )': {
  maxWidth:"1440px" ,
  height:"253px"
},
'@media ( max-width:500px )': {
  maxWidth:"1440px" ,
  height:"150px"
},
})



const webStyle = {
  card: {
    margin: 20,
    width: "100%",
    position: "relative" as "relative",
    left: 0,
  },

  giveaway: {
    width: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  },
    sortStyle: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "22px",
  },

  fixbutton: {
    bottom: 50,
    right: 50,
    position: "fixed" as "fixed",
    width: "100px",
    height: "100px",
    zIndex: 9999,
    backgroundColor: "#f6f6f6",
  },

  tag: {
    padding: 10,
  },
};
// Customizable Area End
