import React from "react";

// Customizable Area Start
import { lazy } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Input,
  FormControl,
  Select,
  MenuItem,
  InputAdornment ,
 
} from "@material-ui/core";

import {styled } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-number-input'
const Calendar = lazy(() => import('react-date-range').then(mod => ({ default: mod.Calendar })));
import { enUS } from "date-fns/locale";
import {calender,fillcheckBoxRadioButton,radiobuttons,checkbox_Checked,checkbox_UnChecked,checkMark} from './assets'
import { KeyboardArrowDown} from "@material-ui/icons";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { PrivacyPolicy } from "../../../components/src/WhoAreWe";
import moment from "moment";

// Customizable Area End

import RequestManagementController, {
  Props,
  configJSON,
} from "./RequestManagementController";

export default class RequestManagement extends RequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleTermsAndConditonIsClose=()=>{
    this.setState({termsAndConditonPopUpOpen:false})
  }
  hanldeSvg = () => {
    return <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
        fill="#64748B"
      />
    </svg>

  }
  importantField = () => {
    return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B"
    }}>
      {" "}    *
    </span>
  }
  renderPhoneInput = () => {
    return (
      <PhoneNumStyle
        data-test-id="txtInputPhonenumber2"
        borderColor={this.handleColorPhoneInput()}
        className="custominput"
        value={this.state.phone_Number}
        defaultCountry="AE"
        countries={[]}
        international
        onChange={(e: any) => {
          this.handleChangePhoneNumber(e)
        }}
        disabled={this.state.pathName!=='/generic-foster-form'}
      />
    )

  }
  renderWhatsAppInput = () => {
    return (
      <PhoneNumStyle
        data-test-id="txtInputPhonenumber2"
        borderColor={this.handleColorPhoneInput()}
        className="custominput"
        value={this.state.whatsAppNumber}
        defaultCountry="AE"
        countries={[]}
        international
        onChange={(e: any) => {
          this.handleChangeWhatsAppNumber(e)
        }}
        disabled={this.state.pathName!=='/generic-foster-form'}
      />
    )

  }
  hanldePhonePlaceholder = () => {
    return this.state.phone_Number == "" && "00000 00000"
  }
  hanldeWhatsAppPlaceholder = () => {
    return this.state.whatsAppNumber == "" && "00000 00000"
  }
  renderDobirth = () => {
    const dateParts = this.state.dateofbirthForRef !==""?this.state.dateofbirthForRef.split('/'): moment.parseZone(new Date()).format('MM/DD/YYYY').split('/') ;
    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[0]) - 1;
    const day = parseInt(dateParts[1]);
    const date = new Date(year, month, day);
    return (

      <DateBoxStyle style={{ position: "relative" }}>
        <Box style={{ position: "relative" }}>

          <DateStyle
            placeholder="DD/MM/YYYY"
            disabledStyle={this.state.dateofbirthErr}
            value={this.convertDateFormat()}
            fullWidth
            data-test-id="dateofbirth"
            disableUnderline
            name="companyName"
            disabled={this.state.pathName!=='/generic-foster-form'}
          />

          <CalenderImage
            src={calender}
            data-test-id='user-date'
            style={{cursor:this.state.pathName=='/generic-foster-form'?'pointer':'unset'}}
            id="user-date"
            onClick={this.state.pathName=='/generic-foster-form'? () =>this.handleOpenDatePicker():()=>('')}
          />
          {this.state.isDatePickerOpen && (
            <div data-test-id='isDatePickDiv' style={{ position: 'absolute', top: 60, zIndex: 1000 }}>
              <StyleDateBox
                locale={enUS}
                data-test-id='chooseDate'
                maxDate={new Date()} 
                onChange={this.handleChangesForDate}
            date={date}  editableDateInputs={false} />
            </div>
          )}

        </Box>
        <Typography style={{...Allstyle.errorTextStyle,marginTop:'2px'}}>{this.renderErrFun(this.state.dateofbirthErr)}</Typography>
      </DateBoxStyle>
    )
  }
  renderOptionalField=()=>{
return(
  <span style={{...Allstyle.optionalStyle}}> (Optional)</span>
)
  }
  handleErrorFields = (error: boolean) => {
    return error && (
      <Typography
        style={Allstyle.errorTextStyle}
      >
        Please select an option
      </Typography>)
  }
  hanldeDropDowns = (countryData: any) => {
    return countryData?.data?.length > 0 && countryData?.data.map((option: any) => (
      <MenuItem
      value={option?.id}
        key={option?.id}
        style={{
          fontSize: "16px",
          fontFamily: "Lato",
          fontWeight: 400,
        }}
      >{`${option?.name}`}</MenuItem>
    ))
  }
 
   renderSelectCountry = () => {
    return (
      <SelectField  >
        <Select
          fullWidth
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Lato",
            color: this.handleColorStyleForSelect("country")
          }}
          IconComponent={KeyboardArrowDown}
          error={this.state.countryError}
          variant="outlined"
          value={this.state.country}
          onChange={(e: any) => {
            this.hanldeChange("country", e.target.value, "countryError");
          }}
          data-test-id="country"
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "10px",
                maxHeight: 398,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,

          }}
        >
          <MenuItem
            value="Select"
            style={{
              display: "none",
              fontSize: "16px",
              fontFamily: "Lato",
              fontWeight: 400,
              color: "#94A3B8",
            }}
          >
            {configJSON.selectCountry}
          </MenuItem>
          {this.hanldeDropDowns(this.state.countryList)}
        </Select>
        {this.handleErrorFields(this.state.countryError)}
      </SelectField>
    )
  }
  handleMenuRadio = (heading: string, option: any[], setItem: any, optional: boolean, selectedMenu: any, errorState?: boolean) => {
   
    return <MainBoxForItem >
      <NameParentBoxRadioAndCheck>
        <Typography style={{ ...Allstyle.nameStyling }}>
          {heading}
          {
                        optional ?
                            <span style={Allstyle.optionalStyle}>

                                {`  (Optional)`}
                            </span>
                            :
                            this.importantField()
                    }
        </Typography>
        {errorState && <Typography style={Allstyle.errorTextStyle}>
                    Please select an option
                </Typography>}
      </NameParentBoxRadioAndCheck>
      <MainBoxForItem2 >
        {
          option.map((item) => {
            const isChecked =
              selectedMenu &&
              ((typeof selectedMenu === "number" && selectedMenu === item?.id) ||
                (typeof selectedMenu === "string" && selectedMenu.includes(item?.name)));

            const imgSrc = isChecked ? fillcheckBoxRadioButton : radiobuttons;

            return <Typography key={item.id}style={Allstyle.optionStyle2} data-test-id='radioOption' id="optionalSpecific" onClick={() => {
                     setItem(item)
            }}>
              <img src={imgSrc} style={{ marginRight: "12px", width: "20px" }} />
              {item?.name}
            </Typography>
          })
        }
        {(this.state.householdActivityLevel==4&&heading=='What is your household activity level?')&& (
            <Box>

              <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px", }}>
                {configJSON.pleaseExplain} {this.importantField()}
              </Typography>
              <InputFields
                error={this.state.pleaseExplainErr}
                helperText={this.handleOtherErroMessage(this.state.pleaseExplainErr)}
                placeholder="Write something"
                variant="outlined"
                data-test-id='reasonInput2'
                id=""
                onChange={(e:any)=>this.hanldeChange("pleaseExplain", e.target.value, "pleaseExplainErr")}
                value={this.state.pleaseExplain}
                style={{color:'rgba(15, 23, 42, 1)'}}
              />  </Box>
          )}
          {(this.state.expPetOwner==4&&heading=='What is your experience as a pet owner?') && (
            <Box>

              <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px", }}>
                {configJSON.pleaseExplain} {this.importantField()}
              </Typography>
              <InputFields
                error={this.state.pleaseExplainErr2}
                helperText={this.handleOtherErroMessage(this.state.pleaseExplainErr2)}
                placeholder="Write something"
                variant="outlined"
                data-test-id='reasonInput3'
                id=""
                onChange={(e:any)=>this.hanldeChange("pleaseExplain2", e.target.value, "pleaseExplainErr2")}
                value={this.state.pleaseExplain2}
                style={{color:'rgba(15, 23, 42, 1)'}}
              />  </Box>
          )}
      </MainBoxForItem2>
    </MainBoxForItem>
  }
  handleMenuMultiCheck = (heading: string, option: any[], setItem: any, optional: boolean, selectedMenu: any, errorState?: any) => {

    const isOtherChecked = selectedMenu?.some((item: any) => item === 'Other');
    return (
      <MainBoxForItem>
        <NameParentBoxRadioAndCheck>
          <Typography style={{ ...Allstyle.nameStyling }}>
            {heading}
            {  optional ?
                            <span style={Allstyle.optionStyle2}>

                                {`  (Optional)`}
                            </span>
                            :
              this.importantField()
            }
          </Typography>
          {errorState && <Typography style={Allstyle.errorTextStyle}>
                    Please select an option
                </Typography>}
        </NameParentBoxRadioAndCheck>
        <MainBoxForItem2 >
          {option.map((item) => {
            const isChecked = selectedMenu?.includes(item.name);

            const Src = isChecked ? checkbox_Checked : checkbox_UnChecked;

            return (
              <Typography key={item.id} data-test-id='checkBoxOption' style={Allstyle.optionStyle2}
                id="optionalManual" onClick={() => {
                  setItem(item);
                }}>
                <img src={Src} style={{ marginRight: "12px", width: "20px" }} />
                {item.name}
              </Typography>
            );
          })}
          {isOtherChecked && (
            <Box>

              <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px", }}>
                {configJSON.pleaseExplain} {this.importantField()}
              </Typography>
              <InputFields
                error={this.state.otherError}
                helperText={this.handleOtherErroMessage(this.state.otherError)}
                placeholder="Write something"
                variant="outlined"
                data-test-id='reasonInput'
                id=""
                onChange={(e:any)=>this.hanldeChange("reasonValue", e.target.value, "otherError")}
                value={this.state.reasonValue}
                style={{color:'rgba(15, 23, 42, 1)'}}
              />  </Box>
          )}

        </MainBoxForItem2>
      </MainBoxForItem >
    );
  };

  renderPhoneError = () => {
    return (
      this.state.phoneNumError && (
        <Typography
          style={{ ...Allstyle.errorTextStyle, marginTop:'1px' }}
        >
          {"Please enter a valid phone number"}
        </Typography>
      )
    )
  }
  renderWhatsAppError = () => {
    return (
      this.state.whatsAppNumberErr && (
        <Typography
          style={{ ...Allstyle.errorTextStyle, marginTop:'1px'}}
        >
          {"Please enter a valid whatsapp number"}
        </Typography>
      )
    )
  }
  livingAloneStyle = () => { return this.state.livingAlone ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "unset" }
  livingAloneStyleText = () => { return this.state.livingAlone ? "#64748B" : "#334155" }
  renderErrFun=(isErr:any)=>{
    if(isErr){
return "Please enter a value"
    }
   
  }
  returnMenu = () => {
    return (
      <>
        {(this.state.pathName == "/adoption-form"||this.state.pathName == "/adoption-template" )&& this.handleMenuRadio(configJSON.how_Long_Have_You_Lived_At_Your_Address, configJSON.howLongLived, this.handleHowLongLived, false, this.state.howLongLived, this.state.howLongLivedErr)}
        {(this.state.pathName == "/foster-template"||this.state.pathName == "/foster-form" || this.state.pathName == "/generic-foster-form") && this.handleMenuRadio("How long can you foster for? ", configJSON.How_long_can_you_foster_for, this.handleHowLongFoster, false, this.state.fosterenLive, this.state.howLongLivedFosterErr)}
        {this.handleMenuRadio(configJSON.do_You, configJSON.doYou, this.handlDoYou, false, this.state.doYou, this.state.doYouErr)}

        {this.handleMenuRadio(this.handleHeadingaccommodation(), configJSON.pleaseDescribeAccommodation, this.handlDescribeAccommodation, false, this.state.descAccommodation, this.state.descAccommodationErr)}
        {this.state.descAccommodation == 2 && this.handleMenuRadio(configJSON.apartment_Type_heading, configJSON.apartment_Type, this.handlApartment, false, this.state.apartMent, this.state.apartMentErr)}
      </>
    )
  }
  renderMenu2=()=>{
    return(
      (this.state.pathName == "/adoption-form"||this.state.pathName == "/adoption-template" )&& 
  this.returnCommonRadioField('yesCheckBox',configJSON.does_Your_Home_Have_Access_to_A_Private_Garden,false,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.accessToPrivateGarden,this.handleAccessToPrivateGarden,false)
    )
  }
  renderMenu3=()=>{
    return(
    (this.state.pathName=="/foster-template"||this.state.pathName=="/foster-form"|| this.state.pathName=="/generic-foster-form")&& 
    <>
   
    {this.returnCommonRadioField('handlebalconyGardenClose',"Is the balcony/garden enclosed or cat proofed?",true,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.fosterbalconygarden,this.handlebalconyGardenClose,this.state.fosterbalconygardenErr)}
   
    {!this.state.fosterbalconygarden&&this.state.fosterbalconygarden!==null&&this.returnCommonRadioField('handlefosterenclosed',configJSON.if_not_do_you_agree,true,{radioText1:"Yes, I agree",radioText2:configJSON.no_Text},this.state.fosterenclosed,this.handlefosterenclosed,this.state.fosterenclosedErr)}

    </>
     )
  }
  renderMenu4 = () => {
    return (this.state.pathName == "/adoption-form"||this.state.pathName == "/adoption-template") && this.handleMenuRadio(configJSON.what_Is_Your_Household_Activity_Level, configJSON.householdActivityLevel, this.handleHouseholdActivityLevel, false, this.state.householdActivityLevel, this.state.householdActivityLevelErr)

  }
  renderLivingAlonCheckBox = () => {
    return (
      this.returnCommonRadioField('livingAloneCheckBox',configJSON.list_All_People_Who_Will_be_Living_With_The_New_Pet,true,{radioText1:configJSON.i_am_Living_Alone,radioText2:configJSON.i_Live_With_Others},this.state.livingAlone,this.handlelivingAlone,this.state.livingAloneErr)
    )
  }
  renderMenu5 = () => {
    return (
      (this.state.pathName == "/foster-template" ||this.state.pathName == "/foster-form" || this.state.pathName == "/generic-foster-form") && this.handleMenuRadio(configJSON.what_Is_Your_Household_Activity_Level, configJSON.householdActivityLevel, this.handleHouseholdActivityLevel, false, this.state.householdActivityLevel, this.state.householdActivityLevelErr)
    )
  }
  renderIfYouHaveChildAndOtherAnimalAndPet = () => {
    return (
      (this.state.pathName == "/foster-template" ||this.state.pathName == "/foster-form" || this.state.pathName == "/generic-foster-form") &&
      <>
       
        {this.returnCommonRadioField('handleFosterChildren',"If you have children, do you understand that the pet will need space away from the children at times?",false,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.fosterchildrenattimes,this.handleFosterChildren,false)}
      
        {this.returnCommonRadioField('otherAnimalYesCheckBox',"Do you have other animals at home?",true,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.fosteranimalshome,this.handlefosteranimalshome,this.state.fosteranimalshomeErr)}

        {this.state.fosteranimalshome &&<MainBoxForItem >

           <Typography style={{ ...Allstyle.nameStyling }}>
            {configJSON.list_All_Pets_Currently_Living_With_You} {this.importantField()}
            </Typography>

         <MainBoxForPersonItem>
          {this.state.newPetEntry.map((entry: any, index: any) => (
            <PetEntryComponent
            onChange={(field: string, value: any) =>
              this.handleNewPetChange(index, field, value,'manualy')
            }
            handleRemovePet={this.handleRemovePet}
            errorsPetArr={this.state.errorsPetArr}
            entry={entry}
            importantField={this.importantField}
            key={index}
            data-test-id='newPetEntries'
            index={index}
              state={this.state.newPetEntry}
              list={this.state.animalTypeList}
              breedList={this.state.breedList}
              hanldeDropDowns={this.hanldeDropDowns}
              handleErrorFields={this.handleErrorFields}
              selectedSterilized={this.state.selectedSterilized}
              setItem={this.hanldeSterilized}
              patheName={this.state.pathName}
              isOptional={this.renderOptionalField}
            />
          ))}
          <AddAnotherBox >
            <Typography
              style={{
                ...Allstyle.addAnotherTypo,
                cursor: 'pointer',
              }}
              data-test-id='addAnother'
              id="addone"
              onClick={this.handleNewAddAnotherPet}

            >
              <AddCircleOutlineIcon
                style={{ marginRight: "4px" ,color: "rgba(3, 181, 170, 1)"}}
              />{" "}
              {configJSON.add_Another}
            </Typography>
          </AddAnotherBox>

        </MainBoxForPersonItem>
        </MainBoxForItem>}
        {this.state.pathName == "/generic-foster-form" && this.handleMenuRadio("Do you have a preference of gender to foster?", configJSON.gender_handle, this.handleGenderFoster, false, this.state.foseterGenericGender, this.state.foseterGenericGenderErr)}
        {this.state.pathName == "/generic-foster-form" && 
        this.returnCommonRadioField('handlefosterWilling',"Are you willing to foster a pet of any age?",false,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.willingFosterAnimal,this.handlefosterWilling,false)
        }
        {!this.state.willingFosterAnimal&&this.state.willingFosterAnimal!==null&&this.state.pathName == "/generic-foster-form" && this.handleMenuRadio("If not, what age would you consider", configJSON.age_handle, this.handleAgeFoster, true, this.state.foseterGenericAge, this.state.foseterGenericAgeErr)}

        {this.returnCommonRadioField('handlefosterappointments',"Do you drive or have access to a vehicle to bring your foster pet to events and appointments?",false,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.fosterappointments,this.handlefosterappointments,false)}
      
        {this.returnCommonRadioField('handlfosterapplicable',"Are you willing and able to medicate your foster pet, if applicable?",false,{radioText1:configJSON.yes_Text,radioText2:configJSON.no_Text},this.state.fosterapplicable,this.handlfosterapplicable,false)}

      </>

    )
  }
  renderhandleMenuMultiCheck = () => {
    return (

     ( this.state.pathName == "/adoption-form"|| this.state.pathName == "/adoption-template") && this.handleMenuMultiCheck(configJSON.what_Is_Your_Rreason_For_Wanting_To_Adopt_New_pet, configJSON.resonForAdopt, this.handleReasonForAdopt, false, this.state.reasonForAdopt, this.state.reasonForAdoptErr)
    )
  }
  renderMenu6 = () => {
    return (
      ( this.state.pathName == "/adoption-form"|| this.state.pathName == "/adoption-template") && this.handleMenuRadio(configJSON.what_Is_Your_Experience_As_A_Pet_Owner, configJSON.expPetOwner, this.handlExpPetOwner, false, this.state.expPetOwner, this.state.expPetOwnerErr)
    )
  }
  returnMainTopArrowHeading = () => {
    return <MainTopArrowHeading >
      <Typography
        style={{ ...Allstyle.hedingsStyle, paddingInline: "6px", cursor: "pointer" }}
        data-test-id="home"
        onClick={() => this.hanldeNavigation('LandingPageWeb')}
      >{configJSON.home} </Typography>
      {(this.state.pathName !== "/generic-foster-form") && this.hanldeSvg()}

      {this.state.pathName !== "/generic-foster-form" && <Typography
        style={{
          ...Allstyle.hedingsStyle,
          cursor: "pointer",
          paddingInline: "6px"
        }}
        onClick={() => this.state.pathName.includes('template') ? this.props.navigation.navigate('MySubmittedRequests') : this.props.navigation.navigate('ProductDescriptionPublic', { isSnackBarOpen: false, msg: '', id: this.state.petId })}

        data-test-id="page"
      >{this.state.pathName.includes('template') ? configJSON.my_Submitted_Requests : configJSON.postDetails} </Typography>}
      {this.hanldeSvg()}
      <Typography
        style={{
          ...Allstyle.hedingsStyle,
          color: 'var(--Neutrals-Cool-gray-900, #0F172A)',
          paddingInline: "6px"

        }} >  {this.state.heading} </Typography>
    </MainTopArrowHeading>
  }
  returnMainTopArrowHeadingForSmallSreen = () => {
    return <MainTopArrowHeadingForSmallSreen >
      <Typography
        style={{ ...Allstyle.hedingsStyle, cursor: "pointer", paddingInline: "unset" }}
        onClick={() => this.hanldeNavigation('LandingPageWeb')}
        data-test-id="home2"
      >
        {configJSON.home}
      </Typography>
      {(this.state.pathName !== "/generic-foster-form") && this.hanldeSvg()}

      {this.state.pathName !== "/generic-foster-form" && <Typography
        style={{
          ...Allstyle.hedingsStyle,
          paddingInline: "6px"
          , cursor: "pointer",

        }}
        data-test-id="page"
        onClick={() => this.state.pathName.includes('template') ? this.props.navigation.navigate('MySubmittedRequests') : this.props.navigation.navigate('ProductDescriptionPublic', { isSnackBarOpen: false, msg: '', id: this.state.petId })}


      >
        {this.state.pathName.includes('template') ? configJSON.my_Submitted_Requests : configJSON.postDetails}
      </Typography>
      }
      {this.hanldeSvg()}
      <Typography
        style={{
          ...Allstyle.hedingsStyle,
          paddingInline: "6px",

          color: 'var(--Neutrals-Cool-gray-900, #0F172A)'
        }}


      >
        {this.state.heading}
      </Typography>
    </MainTopArrowHeadingForSmallSreen>
  }
  returnCursorType=()=>{
    return this.state.livingAlone ? "unset" : 'pointer'
  }
  
  returnTermsCondition=()=>{
    return      (this.state.pathName == "/foster-template" ||this.state.pathName=="/foster-form" ||this.state.pathName=="/generic-foster-form") &&
    <MainBoxForItem >
    <NameParentBox>
      <Typography style={Allstyle.nameStyling}>
        {"By submitting a foster form you agree to "}
        <span style={{color:"#03B5AA",cursor:'pointer'}} data-test-id='termsAndCondition' onClick={()=>this.setState({termsAndConditonPopUpOpen:true})}>
        terms and conditions
        </span>
        {this.importantField()}
      </Typography>
    </NameParentBox>
    <BoxForYesIAgree >
      <Typography style={{ ...Allstyle.checkStyle, display: 'flex', alignItems: "center" }} data-test-id='handlefosterTermsConditon' id="accessToPrivateGarden" onClick={this.handlefosterTermsConditon}>
        <img src={this.state.fosterTermsConditon ? checkbox_Checked : checkbox_UnChecked} style={{ marginRight: "12px" }} />
        {"Yes, I agree"}
      </Typography>
      {this.state.fosterTermsConditonErr && <Typography style={{...Allstyle.errorTextStyle,marginTop:'8px'}}>
      Please accept terms and conditions
               </Typography>}
    </BoxForYesIAgree>

  </MainBoxForItem>

  }
  returnHowToContact=()=>{
    return this.handleMenuMultiCheck(configJSON.howToContactHeading, configJSON.howToContactConfigArr, this.handleHowToContact, false, this.state.howToContactArr, this.state.howToContactErr)
  }
  returnCommonRadioField=(datatestId:string,leftHeading:string,isImportant:boolean,text:{radioText1:string,radioText2:string},state:boolean|null,handleRadioFunction:Function,errorState:boolean)=>{
    return <MainBoxForItem >
    <NameParentBoxForCommonRadio>
      <Typography style={Allstyle.nameStyling}>
        {leftHeading}{isImportant?this.importantField():this.renderOptionalField()}
      </Typography>
      {errorState && <Typography style={Allstyle.errorTextStyle}>
                    Please select an option
                </Typography>}
    </NameParentBoxForCommonRadio>
    <BoxForCheckForCommonRadio >
      <Typography data-test-id={datatestId} style={{ ...Allstyle.checkStyle, display: 'flex', alignItems: "center" }}  id="accessToPrivateGarden" onClick={()=>handleRadioFunction(true)}>
        <img src={state? fillcheckBoxRadioButton : radiobuttons} style={{ marginRight: "12px" }} />
        {text.radioText1}
      </Typography>
      <Typography data-test-id={datatestId} style={{ ...Allstyle.checkStyle, display: 'flex', alignItems: "center" }}  id="accessToPrivateGarden" onClick={()=>handleRadioFunction(false)}>
        <img  src={!state&&state!==null? fillcheckBoxRadioButton : radiobuttons} style={{ marginRight: "12px" }} />
        {text.radioText2}
      </Typography>
    </BoxForCheckForCommonRadio>
  </MainBoxForItem>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
     
      <>
           <ParentContainer >
         {this.returnMainTopArrowHeading()}
          <MainBoxForm >
            {this.returnMainTopArrowHeadingForSmallSreen()}
          
            <Typography style={Allstyle.Textstyle}>

            {this.state.heading}
            </Typography>
            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.firstName}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="firstName"
                name='firstName'
                variant="outlined" placeholder="Your First Name"
                value={this.state.first_Name}
                onChange={(e:any)=>this.hanldeChange("first_Name", e.target.value, "first_Name_Err")}
                helperText={this.renderErrFun(this.state.first_Name_Err)}
                error={this.state.first_Name_Err}
                data-test-id='firstName'
                disabled={this.state.pathName!=='/generic-foster-form'}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.lastName}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="lastName"
                variant="outlined" placeholder="Your Last Name"
                value={this.state.last_Name}
                onChange={(e:any)=>this.hanldeChange("last_Name", e.target.value, "last_Name_Err")}
                helperText={this.renderErrFun(this.state.last_Name_Err)}
                error={this.state.last_Name_Err}
                data-test-id='lastName'
                disabled={this.state.pathName!=='/generic-foster-form'}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.email}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="Email"
                variant="outlined" placeholder="Your Email"
                value={this.state.email}
                onChange={(e:any)=>this.hanldeChange("email", e.target.value, "email_Err")}
                helperText={this.renderErrFun(this.state.email_Err)}
                error={this.state.email_Err}
                data-test-id='email'
                disabled={this.state.pathName!=='/generic-foster-form'}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.phoneNumber}{this.importantField()}
                </Typography>
              </NameParentBox>
              <div 
              style={
                Allstyle.divStyle
              }
              >
                <label 
                htmlFor="" 
                style={
                  Allstyle.labelStyle
                  } >{this.hanldePhonePlaceholder()}</label>

                {this.renderPhoneInput()} 
                {this.renderPhoneError()}
              </div>
                
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.whatsAppNumber}{this.importantField()}
                </Typography>
              </NameParentBox>
              <div style={Allstyle.divStyle}>
                <label style={Allstyle.labelStyle} htmlFor="">{this.hanldeWhatsAppPlaceholder()}</label>
                {this.renderWhatsAppInput()}
              {this.renderWhatsAppError()}
              </div>
            </MainBoxForItem>
            {this.returnHowToContact()}
            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.age}{this.importantField()}
                </Typography>
              </NameParentBox>
              {this.renderDobirth()}
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.addressLine1}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="addressline1"
                variant="outlined" placeholder="Enter address line 1"
                value={this.state.address1}
                onChange={(e:any)=>this.hanldeChange("address1", e.target.value, "address1_Err")}
                helperText={this.renderErrFun(this.state.address1_Err)}
                error={this.state.address1_Err}
                data-test-id='addressLine1'
                style={{color:'rgba(15, 23, 42, 1)'}}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.addressLine2} {this.renderOptionalField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="addressline2"
                variant="outlined" placeholder="Enter address line 2"
                value={this.state.address2}
                onChange={(e:any)=>this.hanldeChange("address2", e.target.value, "address2_Err")}
                data-test-id='addressLine2'
                style={{color:'rgba(15, 23, 42, 1)'}}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.city}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="City"
                variant="outlined" placeholder="Enter city/area"
                value={this.state.city}
                onChange={(e:any)=>this.hanldeChange("city", e.target.value, "city_Err")}
                helperText={ this.renderErrFun(this.state.city_Err)}
                error={this.state.city_Err}
                data-test-id='city'
                style={{color:'rgba(15, 23, 42, 1)'}}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.county}{this.importantField()}
                </Typography>
              </NameParentBox>
              <InputFields
                id="County"
                variant="outlined" placeholder="Enter state/emirate"
                value={this.state.county}
                onChange={(e:any)=>this.hanldeChange("county", e.target.value, "county_Err")}
                helperText={ this.renderErrFun(this.state.county_Err)}
                error={this.state.county_Err}
                data-test-id='county'
                style={{color:'rgba(15, 23, 42, 1)'}}
              />
            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.postalCode} {this.renderOptionalField()}
                </Typography>
              </NameParentBox>
              <PostalCodeInputParentBox >
                <ShortInputFields
                  id="postalcode"
                  value={this.state.postalCode}
                  onChange={(e:any)=>this.hanldeChange("postalCode", e.target.value, "postalCode_Err")}
                  variant="outlined" placeholder="Enter postal code"
                data-test-id='postalcode'
                   error={this.state.postalCode_Err}
                   helperText={this.state.postalCode_Err&&this.state.postalCode_Err_value}
                />
              </PostalCodeInputParentBox>

            </MainBoxForItem>

            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.country}{this.importantField()}
                </Typography>
              </NameParentBox>
              {this.renderSelectCountry()}
            </MainBoxForItem>

            {this.returnMenu()}
            {this.renderMenu2()}
            {this.renderMenu3()}
            {this.renderMenu4()}
            {this.renderLivingAlonCheckBox()}
           
            {!this.state.livingAlone&&this.state.livingAlone!==null&&<MainBoxForPersonItem>

              {this.state.newPersonEntry.map((entry: any, index: any) => (
                <PersonNewEntryComponent
                  entry={entry}
                  onChange={(field: string, value: any) =>
                    this.handleNewEntryChange(index, field, value)
                  }
                  errorsArr={this.state.errorsArr}
                  index={index}
                  key={index}
                  data-test-id='newEntries'
                  importantField={this.importantField}
                  handleRemovePerson={this.handleRemovePerson}
                  state={this.state.newPersonEntry}
                  livingAlone={this.state.livingAlone}
                  livingAloneStyleText={this.livingAloneStyleText}
                  livingAloneStyle={this.livingAloneStyle}
                />
              ))}
              <AddAnotherBox >
                <Typography
                  id="addone"
                  data-test-id='addAnother1'
                  style={{
                    cursor: this.returnCursorType(),
                   ...Allstyle.addAnotherTypo
                  }}
                  onClick={this.handleNewAddAnotherPerson}

                >
                  <AddCircleOutlineIcon
                    style={{ color: "rgba(3, 181, 170, 1)", marginRight: "4px" }}
                  />{" "}
                  {configJSON.add_Another}
                </Typography>
              </AddAnotherBox>

            </MainBoxForPersonItem>}

            {this.renderMenu5()}
            {this.renderIfYouHaveChildAndOtherAnimalAndPet()}
            {this.renderhandleMenuMultiCheck()}
            {this.renderMenu6()}
          
           {(this.state.expPetOwner==3&&this.state.pathName=="/adoption-form"||this.state.expPetOwner==3&&this.state.pathName=="/adoption-template")&& <MainBoxForItem >
           <Typography style={{ ...Allstyle.nameStyling }}>{configJSON.list_All_Pets_Currently_Living_With_You} {this.importantField()}</Typography>
            <MainBoxForPersonItem>
            
             {this.state.newPetEntry.map((entry:any, index:any) => (
               <PetEntryComponent
          entry={entry}
          onChange={(field: string, value: any) =>
            this.handleNewPetChange(index, field, value,'manualy')
          }
          errorsPetArr={this.state.errorsPetArr}
            index={index}
            key={index}
            data-test-id='newPetEntries2'
            state={this.state.newPetEntry}  
            importantField={this.importantField}
            list={this.state.animalTypeList} 
            breedList={this.state.breedList}   
            hanldeDropDowns={this.hanldeDropDowns}
            handleErrorFields={this.handleErrorFields}
            isOptional={this.renderOptionalField}
            setItem={this.hanldeSterilized}
            handleRemovePet={this.handleRemovePet}
            selectedSterilized={this.state.selectedSterilized}
            patheName={this.state.pathName}
          />
        ))}
              <AddAnotherBox >
                <Typography
                  style={{
                    ...Allstyle.addAnotherTypo,
                    cursor: 'pointer'
                  
                  }}
                  id="addone"
                  onClick={this.handleNewAddAnotherPet}
                  data-test-id='addAnother2'
                  
                >
                  <AddCircleOutlineIcon
                    style={{ color: "rgba(3, 181, 170, 1)", marginRight: "4px" }}
                  />{" "}
                  {configJSON.add_Another}
                </Typography>
              </AddAnotherBox>

            </MainBoxForPersonItem>
            </MainBoxForItem>}
            
            <MainBoxForItem >
              <NameParentBox>
                <Typography style={Allstyle.nameStyling}>
                  {configJSON.How_Many_Total_Hours_Will_Your_Pet_Be_Left_Alone_During_The_Day} {this.importantField()}
                </Typography>
              </NameParentBox>
              <ShortInputFieldsPetAloneHoursBox >
                <ShortInputFields
                  id="totalHours"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (<InputAdornment position="end"><Typography style={Allstyle.endAdornmentStyle}>{configJSON.hours_Per_Day}</Typography></InputAdornment >)
                  }}
                  value={this.state.hours}
                  onChange={(e:any)=>this.hanldeChange("hours", e.target.value, "hours_Err")}
                  helperText={this.returnHourseErr()}
                  error={this.state.hours_Err}
                  data-test-id='hoursPerDay'
                  onInput={(e:any) => {
                    e.target.value = e.target.value.replace(/[^\d]/g, '')
                  }}
                />
              </ShortInputFieldsPetAloneHoursBox>
            </MainBoxForItem>
             {this.returnTermsCondition()}   
       
            {(this.state.pathName=="/adoption-form"||this.state.pathName=="/adoption-template")&&this.handleMenuRadio(configJSON.will_Your_Pet_Be_Allowed_Outside, configJSON.allowOutSide, this.handleallowOutSide, false, this.state.allowOutSide, this.state.allowOutSideErr)}
            {(this.state.pathName=="/adoption-form"||this.state.pathName=="/adoption-template")&&this.handleMenuRadio(configJSON.have_You_Considered_The_Relocation_Cost_For_Your_New_Pet, configJSON.relocationCost, this.handleRelocationCost, false, this.state.reLocationCost, this.state.reLocationCostErr)}
             
          </MainBoxForm>
          <ButtonsParentBox >
            <ButtonsChildBox >
              <BottonComponentComPo variant="contained"
                data-test-id="submit"
                style={{ ...Allstyle.buttonStyling }}
                onClick={()=>this.handleSubmit()}
                disabled={this.state.isButtonDisabled}
              >
                <img src={checkMark} style={{ marginRight: "8px" }} />
                {this.state.pathName.includes('template')?configJSON.submit_Changes:configJSON.submit_Request }
              </BottonComponentComPo>
              <ButtonCom variant="outlined"
                data-test-id="cancelid"
                style={{ width: "80px" }} onClick={()=>this.hanldeNavigationForCancel()} >
                {configJSON.cancel}
              </ButtonCom>
            </ButtonsChildBox>
          </ButtonsParentBox>
        </ParentContainer>
        {this.state.termsAndConditonPopUpOpen&&<PrivacyPolicy 
      firstHeading="TERMS & CONDITIONS!" secondHeading=""privacyAndTerms={this.state.termsAndConditonPopUpOpen} handleClose={this.handleTermsAndConditonIsClose}
       handleCloseButton={this.handleTermsAndConditonIsClose} data-test-id='PrivacyPolicy'/>
                }
        
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const PersonNewEntryComponent = ({ entry, onChange, errorsArr, index, importantField, handleRemovePerson, state ,livingAlone,livingAloneStyleText,livingAloneStyle}: any) => {

  return (
    <MainBoxForPersonItem>
      <PersonHeadingBox >
        <Box >
          <Typography data-test-id='personHeading' style={{
            color:'#64748B' ,
            fontFamily: "Lato",
            lineHeight: "22px",
            fontSize: "14px",
            fontWeight: 700,
            textShadow:livingAloneStyle()
          }}>{configJSON.person}{" "}{index + 1}</Typography>
        </Box>
        {state?.length > 1 && <Typography data-test-id='removePerson' onClick={() => { handleRemovePerson(index) }} style={{

          color: "#FF5E5B",
          fontFamily: "Lato",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "22px",
          cursor: livingAlone? "unset":'pointer',
          textShadow:livingAloneStyle()
        }}>
          Remove
        </Typography>}
      </PersonHeadingBox>
      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling,color:livingAloneStyleText(), marginBottom: "4px",textShadow:livingAloneStyle() }}>
          {configJSON.name} {importantField()}
        </Typography>
        <InputFields
          id="Name"
          variant="outlined" placeholder="Enter name"
          error={errorsArr[index]?.name_Err}
          helperText={errorsArr[index]?.name_Err && "Please enter a value"}
          onChange={(e: any) => onChange("name", e.target.value)}
          value={entry.name}
          disabled={livingAlone}
          data-test-id='personName'
          style={{color:'rgba(15, 23, 42, 1)'}}
        />
      </PersonBox>

      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px", textShadow:livingAloneStyle(),color:livingAloneStyleText()}}>
          {configJSON.relation} {importantField()}
        </Typography>
        <InputFields
          id="relation"
          variant="outlined" placeholder="Enter relation"
          error={errorsArr[index]?.relationship_Err}
          helperText={errorsArr[index]?.relationship_Err && "Please enter a value"}
          onChange={(e: any) => onChange("relationship", e.target.value)}
          value={entry.relationship}
          disabled={livingAlone}
          data-test-id='personRelation'
          style={{color:'rgba(15, 23, 42, 1)'}}
        />
      </PersonBox>
      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px",color:livingAloneStyleText(),textShadow:livingAloneStyle() }}>
          {configJSON.Age_In_Years} {importantField()}
        </Typography>
        <AgeInputParentBox >
          <ShortInputFields
            id="age"
            variant="outlined" placeholder="Enter age"
            error={errorsArr[index]?.age_Err}
            helperText={errorsArr[index]?.age_Err && "Please enter a value"}
            onChange={(e: any) => {
              onChange("age", e.target.value)}}
            value={entry.age}
            disabled={livingAlone}
            data-test-id='personAge'
            onInput={(e:any) => {
              e.target.value = e.target.value.replace(/[^\d]/g, '');
              
            }}
          />
        </AgeInputParentBox>
      </PersonBox>
    </MainBoxForPersonItem>
  );
};
export const PetEntryComponent = ({ entry, onChange, errorsPetArr, index, importantField, handleRemovePet, state,list,breedList,hanldeDropDowns,handleErrorFields,selectedSterilized,setItem,patheName,isOptional}: any) => {
 
 
  return (
    <MainBoxForPersonItem>
      <PersonHeadingBox >
        <Box >
          <Typography style={{
            color:"#64748B" ,
            fontFamily: "Lato",
            lineHeight: "22px",
            fontSize: "14px",
            fontWeight: 700,
           
          }}>{configJSON.pet}{" "}{index + 1}</Typography>
        </Box>
        {state?.length > 1 && <Typography data-test-id='petRemove' onClick={() => { handleRemovePet(index) }} style={{

          color: "#FF5E5B",
          fontFamily: "Lato",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "22px",
          cursor:'pointer',
      
        }}>
          Remove
        </Typography>}
      </PersonHeadingBox>
      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px" }}>
          {configJSON.Animal_Type} {importantField()}
        </Typography>
         <SelectField  >
        <Select
          fullWidth
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Lato",
            color: entry.category_id==''? "#94A3B8" : "rgba(15, 23, 42, 1)"
          }}
          IconComponent={KeyboardArrowDown}
          variant="outlined"
          error={errorsPetArr[index]?.category_id_Err}
          onChange={(e: any) => onChange("category_id", e.target.value)}
          value={entry.category_id || 'Select'}
          data-test-id="animalType"
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "10px",
                maxHeight: 398,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,

          }}
        >
          <MenuItem
            value="Select"
            style={{
              display: "none",
              fontSize: "16px",
              color: "#94A3B8",
              fontWeight: 400,
              fontFamily: "Lato",
            }}
          >
            {configJSON.selectAnimalType}
          </MenuItem>
          {hanldeDropDowns(list)}
        </Select>
        {handleErrorFields(errorsPetArr[index]?.category_id_Err)}
      </SelectField>
      </PersonBox>

      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px"}}>
          {configJSON.breed} {importantField()}
        </Typography>
<SelectField  >
        <Select
          fullWidth
          style={{
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Lato",
            color:entry.sub_category_id==''? "#94A3B8" : "rgba(15, 23, 42, 1)"
          }}
          IconComponent={KeyboardArrowDown}
          variant="outlined"
          error={errorsPetArr[index]?.sub_category_id_Err}
          onChange={(e: any) => onChange("sub_category_id", e.target.value)}
          value={entry.sub_category_id || 'Select'}
          data-test-id="breed"
          MenuProps={{
            MenuListProps: {
              style: {
                padding: "10px",
                maxHeight: 398,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,

          }}
        >
          <MenuItem
            value="Select"
            style={{
              fontSize: "16px",
              display: "none",
              fontFamily: "Lato",
              color: "#94A3B8",
              fontWeight: 400,
            }}
          >
            {configJSON.breedSelect}
          </MenuItem>
          {hanldeDropDowns(breedList[index])}
        </Select>
        {handleErrorFields(errorsPetArr[index]?.sub_category_id_Err)}
      </SelectField>

      </PersonBox>
      <PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px" }}>
          {configJSON.Age_In_Years} {importantField()}
        </Typography>
        <AgeInputParentBox >
          <ShortInputFields
            id="age"
            variant="outlined" placeholder="Enter age"
            error={errorsPetArr[index]?.age_Err}
            helperText={errorsPetArr[index]?.age_Err && "Please enter a value"}
            onChange={(e: any) => onChange("age", e.target.value)}
            value={entry.age}
            data-test-id='petAge'
            onInput={(e:any) => {
              e.target.value = e.target.value.replace(/[^\d]/g, '');
              
            }}
          />
        </AgeInputParentBox>
      </PersonBox>
     {(patheName=='/adoption-form'||patheName=='/adoption-template') &&<PersonBox>
      
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "16px",display:'flex',alignItems:'center'}}>
          {configJSON.Sterilized} <span style={{marginLeft:'2px'}}>{importantField()}</span>  {
          errorsPetArr[index]?.sterilized_Err && (
            <Typography
              style={{...Allstyle.errorTextStyle,marginLeft:'16px'}}
            >
              Please select an option
            </Typography>)
}
        </Typography>
        <MainBoxForItem2 >
        {
          configJSON.SterilizedArr.map((item:any) => {
            const isChecked =entry.sterilized==item.name
            const imgSrc = isChecked ? fillcheckBoxRadioButton : radiobuttons;

            return <Typography key={item.id}style={Allstyle.optionStyle2} data-test-id='sterilizedRadioOption2' id="optionalSpecific" 
            onClick={(e: any) => onChange("sterilized", item.name)}
                 >
              <img src={imgSrc} style={{ marginRight: "12px", width: "20px" }} />
              {item?.name}
            </Typography>
          })
        }
      </MainBoxForItem2>
        </PersonBox>}
        {(patheName!=='/adoption-form'&&patheName!=='/adoption-template') &&<PersonBox>
        <Typography style={{ ...Allstyle.nameStyling, marginBottom: "4px"}}>
          {configJSON.any_health_condition} {isOptional()}
        </Typography>
        <InputFields
          id="reason"
          variant="outlined" placeholder="Write something"
          onChange={(e: any) => onChange("any_health_condition", e.target.value)}
          value={entry.any_health_condition}
            data-test-id='reasonHealthCondition'
            style={{color:"rgba(15, 23, 42, 1)"}}
        />
      </PersonBox>}
     
    </MainBoxForPersonItem>
  );
};
const ParentContainer = styled(Box)({
  margin: 'auto',
  width: "85%",
  marginBottom: '94px',
  '@media (max-width:350px)': {
    width: "98%",
    paddingLeft: 3,
    paddingRight: 3,
    boxSizing: 'border-box'
  }
})
const MainBoxForItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: '24px',
  '@media (max-width:950px )': {
    minWidth: "unset",
    maxWidth: "unset",
    flexDirection: "column",
    gap: "23px",
  },
});
const MainBoxForPersonItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'flex-end',
  flexDirection: "column",
  gap: '16px',
  '@media (max-width:950px )': {
    alignItems: 'flex-start',
  },
  '@media (max-width:660px )': {
    minWidth: "unset",
    maxWidth: "unset",
    flexDirection: "column",
    gap: "23px",
    flexWrap: "wrap",
    alignItems: 'flex-start',
     width:'100%'
  },
});
const MainBoxForItem2 = styled(Box)({
  maxWidth: "375px",
  minWidth: "474px",
  display: "flex",
  flexDirection: "column",
  '@media (max-width:660px )': {
    minWidth: "unset",
    flexWrap: "wrap",
    maxWidth: "unset",

  },
})
const MainTopArrowHeading = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "52px 100px 48px 0px",
  '@media (max-width:1208px) and (min-width:960px)': {
    padding: "52px 40px 48px 40px;"
    
  },
  '@media (max-width:960px) and (min-width:800px)': {
    padding: "52px 40px 48px 40px"
  },
  '@media (max-width:800px )': {
    padding: "52px 20px 48px 20px"
  },
  '@media (max-width:1011px)': {
    display:'none'
  }
});

const MainTopArrowHeadingForSmallSreen = styled(Box)({
  
  display:'none',
  '@media (max-width:1011px )': {
    display:'block',
    padding: "52px 0px 0px 0px",
   
  },
  '@media (max-width:1012px )': {
    display: 'flex',
      alignItems: "center",
  },
});
const InputFields = styled(TextField)({
  minWidth: "473px",
  color: "rgba(15, 23, 42, 1)",
  "& .MuiInputBase-input": {
    lineHeight: "19.2px",
    color: "rgba(15, 23, 42, 1)"
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "18px"
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },


  "& .MuiInputBase-root": {
    color: "rgba(15, 23, 42, 1)"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderRadius: "8px",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: "1px solid #CBD5E1",
  
  },
  '& .MuiInputBase-input.Mui-disabled':{
    color: "#64748B"
  },
  '@media (max-width:660px )': {
    maxWidth: "unset",
    minWidth: "unset",
    width: '100%'
  },
});
const ShortInputFields = styled(TextField)({
  minWidth: "274px",
  "& .MuiInputBase-input[type='date']": {
    lineHeight: "19.2px",
    color: "rgba(15, 23, 42, 1)",
    textTransform: 'uppercase',
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#F87171"
  },


  "& .MuiInputBase-root": {
    color: "rgba(15, 23, 42, 1)"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#CBD5E1",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderColor: "#CBD5E1",
    borderWidth: "1px",
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#CBD5E1",
      borderWidth: "1px",
    },
  },

  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  },

  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: "1px solid #CBD5E1",
  },
  '& .MuiInputBase-input.Mui-disabled':{
    color: "#64748B"
  },
  '@media (max-width:660px )': {
    maxWidth: "unset",
    minWidth: "274px",

  },
  '@media (max-width:350px )': {
    minWidth: "100%",

  },
});
const PostalCodeInputParentBox = styled(Box)({
  minWidth: "473px",
  '@media (max-width:660px )': {
    maxWidth: "unset",
    minWidth: "274px",

  },
  '@media (max-width:350px )': {
    minWidth: "100%",

  },
})
const BoxForCheck=styled(Box)({
  minWidth: "473px", 
  display: 'flex', 
  alignItems: 'flex-start' ,
  '@media (max-width:660px )': {
    minWidth: "unset",
  
  },
})
const BoxForCheckForCommonRadio=styled(Box)({
  display: 'flex', 
  minWidth: "473px", 
  alignItems: 'flex-start' ,
  flexDirection:'column',
  gap:'16px',
  '@media (max-width:660px )': { minWidth: "unset",}
   
  
  
})
const NameParentBoxForCommonRadio = styled(Box)({
  height: "max-content",
  width: '275px',
  display: "flex",
  flexDirection:'column',
  '@media (max-width:950px)': {
    width:'unset',
    alignSelf: 'unset',
  }
});
const BoxForYesIAgree=styled(Box)({
 display: 'flex',  alignItems: 'flex-start', minWidth: "473px", flexDirection:'column',
 '@media (mwidthax-:660px )': {
  minWidth: "unset",
},
})

export const PhoneNumStyle = styled(PhoneInput)(({ borderColor ,disabled}: any) => ({
 
  minWidth: "473px",
  boxSizing: "border-box",
  border: `1px solid ${borderColor || "#F87171"}`,
  height: 41,
  zIndex: 1,
  borderRadius: 8,
  position: "relative",
  display: 'flex',
  alignItems: 'center',
  color:disabled?"#64748B":"rgba(15, 23, 42, 1)",
  paddingLeft: 10,
  '& input': {
    border: 'none',
    color:disabled?"#64748B":"rgba(15, 23, 42, 1)"
  },
  '& input:focus': {
    border: 'none',
    outline: 'none'
  },
  '& .PhoneInputInput': {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    background: "transparent",
    color:disabled?"#64748B":"rgba(15, 23, 42, 1)",
    lineHeight: "24px"
  },
  '& input:focus-visible': {
    border: 'none',
    outline: 'none'
  },
  '@media (max-width:600px)': {
    minWidth: "unset"
  },

}));
const CalenderImage = styled('img')({
  marginRight: "10px",
  position: 'absolute',
  top: 9,
  left: '242px',
  width: "24px",
  height: "24px",

})
const DateBoxStyle = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "374px",
  minWidth: "473px",
  '@media (max-width:660px )': {
    flexWrap: "wrap",
    minWidth: "unset",
    maxWidth: "unset",
    flexDirection: "column"
  },
});
const DateStyle = styled(Input)(({ disabledStyle }: any) => ({
  minWidth: "175px",
  maxWidth: "274px",
  border: disabledStyle ? "1px solid #F87171" : "1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
  height: "44px",
  borderRadius: "8px",
  padding: "10px 8px",
  color:"rgba(15, 23, 42, 1)",
'& .MuiInputBase-input.Mui-disabled':{
  color:"#64748B",

}
}));
const StyleDateBox = styled(Calendar)({
  "& .rdrDayNumber span": {
    fontWeight: 700,
  },
  "& .rdrMonth": {
    width: "unset"
  }
});
const SelectField = styled(FormControl)({
  minWidth: "473px",
  maxWidth: "473px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": { borderColor: "#CBD5E1" },

    "&.Mui-focused fieldset": {
      borderWidth: "1px", borderColor: "#CBD5E1",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1", borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px", borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": { padding: "12px 8px" },
  "& .MuiInputBase-input": { lineHeight: "19.2px" },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": { borderColor: "#F87171" },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#DC2626",
    fontSize: "12px", fontFamily: "Lato",
    lineHeight: "18px", fontWeight: 400,
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px", marginRight: "0px"
  },
  '@media (max-width:600px)': { minWidth: "unset",width:'100%' }
});
const BottonComponentComPo = styled(Button)({
  "&.MuiButton-contained": {
    boxShadow: "unset"
  }
});

const PersonHeadingBox=styled(Box)({
   marginBottom: "16px", 
   display: "flex", 
   justifyContent: "space-between",
    width:'473px' ,
    '@media (max-width:950px)': { width: "100%" } 
})
const ButtonCom = styled(Button)({
  borderRadius: "8px",
  color: "var(--Primary-color-1, #03B5AA)",
  fontFamily: "Lato",
  fontSize: "16px",
  height: "44px",
  fontWeight: 700,
  lineHeight: "24px",

  textTransform: "inherit",
  '&.MuiButton-outlined': {
    border: "1px solid #03B5AA"
  }
})
const NameParentBox = styled(Box)({
  height: "max-content",
  display: "flex",
  width: '275px',
  alignSelf: 'center',
  '@media (max-width:950px)': {
    alignSelf: 'unset',
    width:'unset'
  }
});
const NameParentBoxRadioAndCheck = styled(Box)({
  height: "max-content",
  display: "flex",
  flexDirection:"column",
  width: '275px',
  '@media (max-width:950px)': {
    width: "100%"
  }
});
const PersonBox = styled(Box)({
  '@media (max-width:950px)': {
    width: "100%"
  },
  '@media (max-width:600px)': {
    width: "100%"
  }
});
const MainBoxForm = styled(Box)({
  margin: 'auto',
  gap: "32px",
  marginInline: "auto",
  display: "flex",
  width:'60%',
  
  flexDirection: "column" as 'column',
  '@media (max-width:600px )': {
    width: 'unset'
  },
 
  '@media (max-width:1390px )': {
    width: '70%'
  },
  '@media (max-width:1190px )': {
    width: '80%'
  },
  '@media (max-width:1090px )': {
    width: '85%'
  },

  '@media (max-width:650px )': {
    width: 'unset'
  },
  '@media (min-width:1680px )': {
    maxWidth:'780px'
  },
});
const AddAnotherBox = styled(Box)({
  minWidth: "473px",
  marginTop: "8px",
  borderBottom: "1px solid #E2E8F0",
  '@media (max-width:600px)': {
    minWidth: '100%',
  }
})
const AgeInputParentBox = styled(Box)({
  minWidth: '473px',
  '@media (max-width:600px)': {
    minWidth: '100%',
  }
})
const ShortInputFieldsPetAloneHoursBox = styled(Box)({
  minWidth: "473px",
  '@media (max-width:600px)': {
    minWidth: '100%',
  }
})
const ButtonsParentBox = styled(Box)({

  marginTop: '40px',
  paddingTop: '40px',
  paddingBottom: '40px',
  display: "flex",
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  borderTop: "1px solid #E2E8F0"
})
const ButtonsChildBox = styled(Box)({
  width: '30%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '16px',
  '@media (max-width:1095px)': {
    width: 'max-content'
  },
  '@media (max-width:292px)': {
    flexDirection: 'column'
  }
})
const Allstyle = {
  
  addAnotherTypo:{
  display: "flex",
  fontSize: "16px",
  fontWeight: 700,
  fontFamily: "Lato" as'Lato',
  color: "rgba(3, 181, 170, 1)",
  alignItems: "center",
  width: "max-content",
  paddingTop: '8px',
  paddingBottom: "32px",
  lineHeight:'24px'
},
  Textstyle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "40px",
    letterSpacing: "-0.15px",
  },
  nameStyling: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  hedingsStyle: {
    color: "#475569",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  errorTextStyle: {
    color: "#DC2626",
    fontSize: "12px",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "18px",
  },
  optionalStyle: {
    color: 'var(--Neutrals-Cool-gray-Active-text, #334155)',
    fontFamily: 'Lato' as 'Lato',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
  },
  optionStyle2: {
    color: "#0A2239",
    display: "flex",
    cursor: "pointer",
    fontFamily: "Lato" as 'Lato',
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "16px"
  },
  checkStyle: {
    color: "#0A2239",
    display: "flex",
    cursor: "pointer",
    fontWeight: 400,
    fontFamily: "Lato",
    fontSize: "16px",
  },
  endAdornmentStyle: {
    color: 'var(--Neutrals-Cool-gray-500, #64748B)',
    textAlign: 'right' as 'right',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    fontWeight: 700,
  },
  buttonStyling: {
    color: "#FFF",
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 700,
    height: "44px",
    lineHeight: "24px",
    borderRadius: "8px",
    background: "#03B5AA",
    padding: "10px 16px",
    textTransform: "inherit" as "inherit",
  },
  divStyle:{ position: "relative" as 'relative' },
   labelStyle:{
    position: 'absolute' as 'absolute', 
    top: 10, left: 100, 
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
     color: '#94A3B8'
  }
}
// Customizable Area End
