import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {  isValidPhoneNumber } from "react-phone-number-input";
import storage from '../../../framework/src/StorageProvider';

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  pets: Array<any>;
  petsError: boolean;
  open: boolean;
  isSnackbarOpen:boolean;
  snackbarMessage:string;
  enableCPasswordField: boolean;
  enableNPasswordField: boolean;
  phoneError: boolean;
  fomatMObileNumber: any;
  emirateError: boolean;
  emirate: string;
  emirateData: any;
  area: string;
  areaError: boolean;
  areaData: any;
  gender: string;
  genderError: boolean;
  nationality: string;
  nationalitiesData: any;
  residence: string;
  residenceError: boolean;
  countriesData: any;
  genderData: any;
  dOB: string;
  userProfileData: any;
  has_rescue_group_error:boolean
  emirateName:string;
  emirateId:any;
  areaName:string;
  isLoading:boolean
  nationalityData:Array<any>;
  nationalityId:number;
  countryData:Array<any>;
  countryId:number;
  oldPassword:string;
  handleVisiblNPassword:boolean
  handleVisiblCPassword:boolean
  openSnackbbar:boolean;
  selectedProfileImg:any;
  newPass:any;
  confirmPass:any;
  confirmPassError:boolean;
  confirmPassErrorMessage:string;
  newPassError:boolean;
  oldPassError:boolean;
  rescueGroupData: Array<any>
  veterinariClinicsData: Array<any>
  areaID:number,
  cityError:boolean,
  cityName:string,
  newPassErrorMsg:string,
  oldPassErrMsg:string,
  profileGetData:any,
  imageError:boolean,
  facebookError:boolean,
  tiktokError:boolean,
  instaError:boolean,
  snapchatError:boolean,
  linkedinError:boolean,
  clinicsErrors:Array<any>,
  removeClinicArr:any,
  veterinariClinicsDataTest:any,
  fileFormateErr:boolean
  tooltipOpenchannelVeterinaryTooltip:boolean
  tooltipOpenchannelCategoriesTooltip:boolean
  tooltipOpenchannelPetsReferenceTooltip:boolean
  isModalOpen:boolean
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  getUserProfileDataApiCallId: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  getUserProfileAdopterDetailsCallId: any;
  getAdopterEmiratesCallId: any;
  getAdopterAreaCallId: any;
  getAdopterNationalityCallId: any;
  getAdopterCountryCallId: any;
  userAttr: any;
  profileGetApiCallID : string = "";
  apiAdpoterProfileUpdateCallId:string = "";
  apiAdpoterProfileImgUpdateCallId:string = "";
  apiAdpoterProfileDeleteCallId:string = "";
  apiAdpoterPasswordUpdateCallId:string="";
  apiRescuerProfileUpdateCallId:string = ""


  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      isModalOpen:false,
      removeClinicArr:[],
      veterinariClinicsDataTest:[],
      fileFormateErr:false,
      firstName: "",
      lastName: "",
      email: "",
      isLoading:false,
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
  has_rescue_group_error:false,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      pets: [],
      petsError: false,
      open: false,
      isSnackbarOpen:false,
      snackbarMessage:"",
      enableCPasswordField: true,
      enableNPasswordField: true,
      phoneError: false,
      fomatMObileNumber: "",
      emirate: "Select",
      emirateError: false,
      emirateData: [],
      area: "Select",
      areaError: false,
      areaData: [],
      gender: "",
      genderData: ["Male", "Female"],
      genderError: false,
      nationality: "Select",
      nationalitiesData: [],
      residence: "Select",
      residenceError: false,
      countriesData: [],
      dOB: "",
      userProfileData: "",
      emirateName:"Select",
      emirateId:"",
      nationalityId:0,
      countryId:0,
      areaName:"",
      nationalityData:[],
      countryData:[],
      oldPassword:"",
      handleVisiblNPassword:false,
      handleVisiblCPassword:false,
      openSnackbbar:false,
      selectedProfileImg:"",
      newPass:"",
      confirmPass:"",
      confirmPassError:false,
      newPassError:false,
      oldPassError:false,
      confirmPassErrorMessage:"",
      rescueGroupData: [],
      veterinariClinicsData: [],
      areaID:0,
      cityError:false,
      cityName:"",
  newPassErrorMsg:"",
  oldPassErrMsg:"",
  profileGetData:{},
  imageError:false,
  facebookError:false,
  tiktokError:false,
  instaError:false,
  snapchatError:false,
  linkedinError:false,
  clinicsErrors:[],
  tooltipOpenchannelVeterinaryTooltip:false,
  tooltipOpenchannelCategoriesTooltip:false,
  tooltipOpenchannelPetsReferenceTooltip:false
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      let selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      this.handleCurrentCountryData(selectedCode)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      this.handleRestApiresponse(responseJson, errorReponse, apiRequestCallId)
    }
    else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let requesterId = message.getData(
        getName(MessageEnum.SessionRequestedBy)
      );
      this.handleUniqueSessiondata(requesterId, message)
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAdopterUserCountry();
    this.getUserProfileAdopterDetails();
    this.getAdopterUserEmirates();
    this.getAdopterUserArea();
    this.getAdopterUserNationality();
    this.checkUserType()
  }

  checkUserType = async()=>{
    const user_type = await storage.get("auth_user_role")
    const endPoint = window.location.pathname;
    this.handlePathValidation(user_type,endPoint)
  
  }
  handlePathValidation = (user_type:string, endPoint:string)=>{
    if(user_type === "rescuer" && endPoint == "/UserProfileBasicAdopter" ){
      this.props.navigation.navigate("UserProfileBasicRescuer")
     }else if(user_type === "adopter" && endPoint == "/UserProfileBasicRescuer"){
      this.props.navigation.navigate("UserProfileBasicAdopter")
     }
  }
  
  getUserProfileAdopterDetails = async (isCancel?:any) => {
    this.setState({
      isLoading:isCancel?false:true,
      areaError:false,
      phoneError:false,
      cityError:false,
      petsError:false,
      clinicsErrors:[],
      facebookError:false,
      tiktokError:false,
      instaError:false,
      linkedinError:false,
      snapchatError:false,
      removeClinicArr:[],
      has_rescue_group_error:false,
    })
    const authId = await storage.get("auth_user_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileAdopterDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.endPointApiGetUserProfileAdopter}${authId}`
    );

    const authToken = await storage.get("auth_token");


    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: authToken,
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfileAdopter
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdopterUserEmirates = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAdopterEmiratesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.endPointApiAdopterUserEmirates
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAdopterUserEmirates
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiAdopterUserEmirates
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdopterUserArea = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAdopterAreaCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.endPointApiAdopterUserAreas
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAdopterUserAreas
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiAdopterUserAreas
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdopterUserNationality = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAdopterNationalityCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.endPointApiAdopterUserNationality
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAdopterUserNationality
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiAdopterUserNationality
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAdopterUserCountry = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAdopterCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.endPointApiAdopterUserCountry
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAdopterUserCountry
    };


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiAdopterUserCountry
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.handleBtnsProps()
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }
  handlePetSelection = (value: string) => {
    this.setState((prevState: any) => {
      const { pets } = prevState;
      const updatedPets = prevState?.pets?.includes(value)
      ? prevState?.pets?.filter((pet: any) => pet !== value)
      : [...pets, value];
      return {
        pets: updatedPets,
        petsError: updatedPets.length === 0,
      };
    });
  };
  handleOpen = () => {
    this.setState({ open: true });

  };

  handleClose = () => {
    this.setState({ open: false,
      oldPassword:"",
    newPass:"",
    confirmPass:"",
    oldPassError:false,
    newPassError:false,
    confirmPassError:false,
    enablePasswordField:true,
    handleVisiblCPassword:false,
    handleVisiblNPassword:false 
  });
  };
  handleVisiblPassword = () => {
    return this.state.enablePasswordField ? "password" : "text"
  };
  handleColorPhone = () => {
    return this.state.phoneError ? "#F87171" : "#CBD5E1"
  };
  handlePhoneNumbberCallBack = (e: any) => {
    this.handlePhoneNumber(e)
  }
  handlePhoneNumber = (e: any) => {
    if (e && isValidPhoneNumber(e.toString())) {
      this.handleMobileNumber(e)
    } else {
      this.setState({ phoneNumber: e, phoneError: true });
      return false;
    }
  };
  handleMobileNumber = (e: any) => {
    this.setState({ phoneNumber: e, phoneError: false });
    return true;
  }
  handleSeprateValidationSelect = (field: any, value: string, errorName: any) => {
    this.setState((prevState: any) => ({
      ...prevState,
      [field]: value,
      [errorName]: value !== "Select" ? false : true,
    }));
  };
  //@ts-ignore
  handleColorStyle = (field: keyof MyComponentState) => {
    //@ts-ignore
    const fieldValue = this.state[field];
    return fieldValue === "Select" ? "#94A3B8" : "#64748B";
  };

  setPasswordField = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField })
  }
  setConfirmPasswordField = () => {
    this.setState({ handleVisiblCPassword: !this.state.handleVisiblCPassword })
  }
  setConfirmNewPasswordField = () => {
    this.setState({ handleVisiblNPassword: !this.state.handleVisiblNPassword })
  }
  handleOldPassword = (e: any) => {
    this.setOldPassword(e.target.value.trim())
    if(e.target.value){
      this.setState({
        oldPassError:false
      })
    }
  }
  setOldPassword = (text: string) => {
    this.setState({
      oldPassword: text,
    });
  };
  handleNationality=(e: any) => {
    let selectedItem = this.state.nationalityData.find((val:any)=> val.name === e.target.value)
    this.setState({ nationality: selectedItem?.name, nationalityId: selectedItem?.id });
  }
  handleSeprate = (e: any) => {
    let obj= this.state.countryData.find((val: any) => e.target.value == val.name)
    this.setState({
      countryId: obj?.id
    })
    this.handleSeprateValidationSelect("area", e?.target?.value, "areaError");
  }
  handleSeperateResidence = (e: any) => {
    let obj = this.state.countryData?.find((val:any)=> e.target?.value == val?.name)
   
    this.setState({
      countryId:obj?.id
    })
    this.handleSeprateValidationSelect("residence", e.target.value, "residenceError");
    if(e.target.value != "United Arab Emirates" || e.target.value != "United Arab Emairates" ){
      this.setState({
        areaName:"",
        emirateName:"Select",
        emirateId:""
      })
    }
  }

  responseSucessCall = async (apiRequestCallId: any, responseJson: any) => {
    this.setState({
      profileGetData:JSON.parse(JSON.stringify(responseJson))
    })
    this.resetChanges(responseJson)
  }
  handleuserAttrFunction = () => {
    let email = this.userAttr.email;
    let firstName = this.userAttr.first_name;
    let lastName = this.userAttr.last_name;
    let currentCountryCode = this.userAttr.country_code;
    let phoneNumber = this.userAttr.phone_number
      ? this.userAttr.phone_number
      : "";

    this.setState({
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber
    });

    //@ts-ignore
    this.txtInputFirstNameProps.value = firstName;

    //@ts-ignore
    this.txtInputLastNameProps.value = lastName;

    //@ts-ignore
    this.txtInputEmailProps.value = email;

    //@ts-ignore
    this.txtInputPhoneNumberProps.value = phoneNumber;

    this.registrationAndLoginType = this.userAttr.type;

    if (this.userAttr.country_code) {
      this.hanndleCountryCodeState(currentCountryCode)
    }

    if (
      configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType
    ) {
      this.handleState()
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      this.handleEditEmail()
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      this.handleChangeDummy()
    }
  }
  hanndleCountryCodeState = (currentCountryCode: any) => {
    this.setState({ currentCountryCode: currentCountryCode });
  }
  handleState = () => {
    this.setState({
      edtEmailEnabled: false,
      llChangePwdDummyShowContainerVisible: true
    });
    this.txtInputEmailProps.editable = false;
  }
  handleEditEmail = () => {
    this.setState({
      edtEmailEnabled: false,
      llChangePwdDummyShowContainerVisible: false,
      llDoChangePwdContainerVisible: false
    });
    this.txtInputEmailProps.editable = false;
  }
  handleChangeDummy = () => {
    this.setState({
      llChangePwdDummyShowContainerVisible: true,
      edtMobileNoEnabled: false,
      countryCodeEnabled: false
    });
    this.txtInputPhoneNumberProps.editable = false;
  }
  handleSuccessfulUserProfileResponse(userAttr: any) {
    const {
      email,
      first_name: firstName,
      last_name: lastName,
      country_code: currentCountryCode,
      phone_number: phoneNumber = "",
      type: registrationAndLoginType,
    } = userAttr;
    this.setState({
      email,
      firstName,
      lastName,
      phoneNumber,
    });

    //@ts-ignore
    this.txtInputFirstNameProps.value = firstName;
    //@ts-ignore
    this.txtInputLastNameProps.value = lastName;
    //@ts-ignore
    this.txtInputEmailProps.value = email;
    //@ts-ignore
    this.txtInputPhoneNumberProps.value = phoneNumber;

    if (currentCountryCode) {
      this.handleCurrentCountryCodeState(currentCountryCode)
    } else if (configJSON.ACCOUNT_TYPE_EMAIL === registrationAndLoginType) {
      this.showContainerFunction()
    } else if (configJSON.ACCOUNT_TYPE_SOCIAL === registrationAndLoginType) {
      this.handleShowcontainerFunction()
    } else if (configJSON.ACCOUNT_TYPE_PHONE === registrationAndLoginType) {
      this.showFunctionContainer()
    }
  }
  handleCurrentCountryCodeState = (currentCountryCode: any) => {
    this.setState({ currentCountryCode });
  }
  showFunctionContainer = () => {
    this.setState({
      llChangePwdDummyShowContainerVisible: true,
      countryCodeEnabled: false,
      edtMobileNoEnabled: false,
    });
    this.txtInputPhoneNumberProps.editable = false;
  }
  handleShowcontainerFunction = () => {
    this.setState({
      edtEmailEnabled: false,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
    });
    this.txtInputEmailProps.editable = false;
  }
  showContainerFunction = () => {
    this.setState({
      edtEmailEnabled: false,
      llChangePwdDummyShowContainerVisible: true,
    });
    this.txtInputEmailProps.editable = false;
  }
  handleUserProfileError(responseJson: any) {
    if (responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token) {
      this.handleShowAlert()
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleShowAlert = () => {
    this.showAlert("Session Expired", "Please Log in again.");
  }
  userProfileGetApiCallIdFunction = (responseJson: any, errorReponse: any) => {
    if (
      responseJson &&
      responseJson.errors &&
      responseJson.data &&
      responseJson.data.attributes
    ) {
      this.handleSuccessfulUserProfileResponse(responseJson.data.attributes);
    } else {
      this.handleUserProfileError(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  };

  handleRestApiresponse = (responseJson: any, errorReponse: any, apiRequestCallId: string) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId && responseJson != null && responseJson.errors === undefined) {
        this.arrayholder = responseJson.data;
        this.handleArrayHolder()
      } else if (apiRequestCallId === this.userProfileGetApiCallId) {
        this.handlereceivedata(responseJson, errorReponse)
      } else if (apiRequestCallId === this.profileGetApiCallID) {
        this.userProfileGetApiCallIdFunction(responseJson, errorReponse)
      }
      else if (apiRequestCallId === this.apiChangePhoneValidation) {
        this.handleValidateFunction(responseJson, errorReponse)
      } else if (
        apiRequestCallId === this.apiCallMessageUpdateProfileRequestId
      ) {
        this.handleShowAlertFunction(responseJson)
      }
      else if (
        apiRequestCallId === this.getUserProfileAdopterDetailsCallId
      ) {
        this.handleResponseSucesscall(responseJson, apiRequestCallId)
      } else if (
        apiRequestCallId === this.getAdopterEmiratesCallId
      ) {
        this.handleSucessEmarite(responseJson)
      }
      else if (
        apiRequestCallId === this.getAdopterAreaCallId
      ) {
        this.handleDataSucess(responseJson)
      } else if (
        apiRequestCallId === this.getAdopterNationalityCallId
      ) {
        this.handleSucessCallResponse(responseJson)
      } else if (
        apiRequestCallId === this.getAdopterCountryCallId
      ){
       this.handleSucessCountryData(responseJson)
      } else if(apiRequestCallId === this.apiAdpoterProfileUpdateCallId){
        this.handleSucessProfileUpdateData(responseJson)
      } else if(apiRequestCallId === this.apiAdpoterProfileImgUpdateCallId) {
 
        this.setState({isLoading:false})
        this.handleSuccessProfileImgUpdated(responseJson)
      } else if(apiRequestCallId === this.apiAdpoterProfileDeleteCallId){
        this.handleSuccessProfileImgDelete(responseJson)
      } else if(apiRequestCallId === this.apiAdpoterPasswordUpdateCallId){
        this.handleSuccessPassUpdateData(responseJson)
      }  else if(apiRequestCallId === this.apiRescuerProfileUpdateCallId){
        this.handleSucessProfileUpdateData(responseJson)
        
      } 
    }
  }
  handleSucessEmarite = (responseJson: any) => {
    if (responseJson !== null && responseJson !== undefined) {
      this.handleEmariteData(responseJson)
    }
  }
  handleDataSucess = (responseJson: any) => {
    if (responseJson !== null && responseJson !== undefined) {
      this.handleAreaData(responseJson)
}
  }
handleSucessCountryData=(responseJson:any)=>{
  if(responseJson !== null && responseJson !== undefined)
        {
         this.handleCountryData(responseJson)
        }
}
handleResponseSucesscall=(responseJson:any,apiRequestCallId:any)=>{
  this.setState({
    isLoading:false
  })
  if(responseJson !== null && responseJson !== undefined)
        {
          this.responseSucessCall(apiRequestCallId, responseJson)                    
        }
}
handleSucessProfileUpdateData = (responseJson:any)=>{
  if(responseJson != null && responseJson.errors == undefined){
    this.setState({
      isSnackbarOpen:true,
      snackbarMessage:"PROFILE UPDATED! It just got a fresh new look! Your profile is now as awesome as you are.",
    })
    this.resetChanges(responseJson)
  }else{
   

    let isPhoneError = responseJson.errors.some((obj:any)=> "full_phone_number" in obj)
    let clinicPhoneError = responseJson.errors.some((obj:any)=> "veterinary_clinics.contact_number" in obj)
   
    this.hanldePhoneErrRes(clinicPhoneError,responseJson)
    if(isPhoneError){
      this.setState({
        phoneError:true
      })
    }
  //  this.parseApiErrorResponse(responseJson)
    
  }
}
hanldePhoneErrRes=(clinicPhoneError:any,responseJson:any)=>{
  if(clinicPhoneError){
    let clinicsData = [...this.state.veterinariClinicsData]
    let clinicErrors = [...this.state.clinicsErrors]
 
   
    for(let i=0;i<responseJson?.errors?.length;i++){
      for(let j=0;j<this.state.veterinariClinicsData.length;j++){
      let isMatchErr= responseJson?.errors[i][`veterinary_clinics.invalid_number`]==this.state.veterinariClinicsData[j]?.attributes?.contact_number
      if(isMatchErr)
      {
        clinicErrors[j].phoneError = true 
      }

      }
    }
    
      this.setState({
      veterinariClinicsData:clinicsData,
      clinicsErrors:clinicErrors
    })
    }
}
handleSuccessProfileImgUpdated = (responseJson:any)=>{
  if(responseJson != null && responseJson.errors == undefined){
    this.setState({
      isSnackbarOpen:true,
      snackbarMessage:"PICTURE PERFECT! Your profile never looked better!"
    })
    setTimeout(() => {
      
      window.location.reload();
    }, 5000);
  }else{
    this.parseApiErrorResponse(responseJson)
  }
}

handleSuccessProfileImgDelete = (responseJson:any)=>{
  if(responseJson != null && responseJson.errors == undefined){
    let userData = {...this.state.userProfileData};
    delete userData.profile_image;
    this.setState({
      isSnackbarOpen:true,
      snackbarMessage:"PICTURE PERFECT! Your profile never looked better!",
      selectedProfileImg:"",
      userProfileData : userData
    })
    setTimeout(() => {
      
      window.location.reload();
    }, 5000);
  }else{
    this.parseApiErrorResponse(responseJson)
  }
}

handleSuccessPassUpdateData = (responseJson:any)=>{
  if(responseJson != null && responseJson.errors == undefined){
    this.setState({
      open:false,
      isModalOpen:true,
      snackbarMessage:"",
      oldPassword:"",
      newPass:"",
      confirmPass:"",
      oldPassError:false,
      newPassError:false,
      confirmPassError:false,
      enablePasswordField: true,
      handleVisiblCPassword: false,
      handleVisiblNPassword: false,
    })
  }else{
    this.setState({
      oldPassError:true,
      oldPassErrMsg:"Password is incorrect"
    })
  }
}

handleArrayHolder=()=>{
  if (this.arrayholder && this.arrayholder.length !== 0) {
    let regexData = this.arrayholder[0];
    if (regexData) {
      this.handlePasswordSucessRegex(regexData)
    }
  }
}
  handleSucessCallResponse = (responseJson: any) => {
    if (responseJson !== null && responseJson !== undefined) {
      this.nationalDataHandle(responseJson)
    }
  }
   
  handlePasswordSucessRegex = (regexData: any) => {
    if (regexData.password_validation_regexp) {
      this.handlerexegPassword(regexData)
    }
    if (regexData.email_validation_regexp) {
      this.handleEmailregex(regexData)
    }
    if (regexData.email_validation_regexp) {
      this.handlePasswordHelpertext(regexData)
    }
  }
  handleEmailregex = (regexData: any) => {
    this.emailReg = new RegExp(regexData.email_validation_regexp);
  }
  handlerexegPassword = (regexData: any) => {
    this.passwordReg = new RegExp(
      regexData.password_validation_regexp
    );
  }
  handleShowAlertFunction = (responseJson: any,) => {
    if (responseJson != null && responseJson.errors === undefined) {
      this.showAlert("Success", "Profile updated successfully.");
      this.enableDisableEditPassword(false);
      this.getUserProfile();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  handleValidateFunction = (responseJson: any, errorReponse: any) => {
    if (responseJson != null && responseJson.errors === undefined) {
      this.validateAndUpdateProfile();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  handlereceivedata = (responseJson: any, errorReponse: any) => {
    if (
      responseJson != null && responseJson.errors === undefined &&
      !responseJson.errors &&
      responseJson.data &&
      responseJson.data.attributes
    ) {
      //On User Profile Success
      this.userAttr = responseJson.data.attributes;
      if (this.userAttr !== null && this.userAttr !== undefined) {
        this.handleuserAttrFunction()
      }
    }
    else {
      if (
        responseJson.errors && responseJson.errors.length > 0 && responseJson.errors[0].token
      ) {
        this.handleAlertShow()
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }
  handleAlertShow = () => {
    this.showAlert("Session Expired", "Please Log in again.");
  }
  handlePasswordHelpertext = (regexData: any) => {
    this.setState({
      passwordHelperText: regexData.password_validation_rules
    });
  }
  handleEmariteData = (responseJson: any) => {
    this.setState({
      emirateData: responseJson.data,
    })
  }
  handleAreaData = (responseJson: any) => {
    this.setState({
      areaData: responseJson.data,
    })
    if(this.state.userProfileData.area && responseJson.data.length){
      let {id = 0} = responseJson.data.find((val:any)=> val.name == responseJson.data.attributes.area )
      this.setState({
        areaID: id
      })
    }
  }
  handleBtnsProps = () => {
    this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
    this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
    this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
  }
  handleCountryData = (responseJson: any) => {
    this.setState({
      countryData: responseJson.data,
    })
    
  }
  nationalDataHandle = (responseJson: any) => {
    this.setState({
      nationalityData: responseJson.data,
    })
  }
  handleCurrentCountryData = (selectedCode: any) => {
    if (selectedCode !== undefined) {
      this.setState({
        currentCountryCode:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }
  handleUniqueSessiondata = (requesterId: any, message: any) => {
    if (requesterId === this.uniqueSessionRequesterId) {
      const sessionToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      this.authToken = sessionToken;
      this.getUserProfile();
    }
  }

  handleAddClinic = () => {
    let clinicData = [...this.state.veterinariClinicsData]
    let obj = {
      attributes: {
        'clinic_name': '',
        'name': '',
        'phone_number': '',
        'isPhoneValid': false,
        'formatedPhoneNumber': '',
        'contact_number':''
      }
    }
    clinicData.push(obj)
    let clinicErrors = [...this.state.clinicsErrors]
    clinicErrors.push({nameError:false,clinicNameError:false,phoneError:false})
    this.setState({
      veterinariClinicsData: clinicData,
      clinicsErrors:clinicErrors
    })
  }

  
  
  handleRescueGrpNameChange = ( e: any) => {
    const maxLength = 100;
    let data = [...this.state.rescueGroupData]
    data[0].attributes.name =e.target.value.substring(0, maxLength)
    
    this.setState({ rescueGroupData:  data ,
      has_rescue_group_error:e.target.value.trim()==0
      })
  }
  


  handleVeterinaryClinicNameChange = (i: number, name: string, e: any,errName:string,limit:number) => {
    let data = [...this.state.veterinariClinicsData]
    let errors = [...this.state.clinicsErrors]
    errors[i][errName] = e.target?.value?.trim().length ? false : true
    data[i].attributes[name] = e.target.value.substring(0,limit);
    this.setState({
      veterinariClinicsData: data,
      clinicsErrors: errors
    })
  }

  handleRescuerClinicsPhoneChange = (i: number, e: any) => {
   
    let data = [...this.state.veterinariClinicsData]
    let errorArray = [...this.state.clinicsErrors]
    data[i].attributes.contact_number = e
    if (e && isValidPhoneNumber(e.toString())) {
      data[i].attributes.formatedPhoneNumber = e;
      data[i].attributes.isPhoneValid = true;
      data[i].attributes.contact_number = e
      errorArray[i].phoneError = false;
      this.setState({ veterinariClinicsData: data, clinicsErrors:errorArray });
      return true
    } else {
      data[i].attributes.isPhoneValid = false;
      errorArray[i].phoneError= true
      this.setState({ veterinariClinicsData: data, clinicsErrors:errorArray });
      return false
    }
  };
  handlePostsImagePreference = () => {
    let data = { ...this.state.userProfileData }
    data.publish_image_in_post = !data.publish_image_in_post
    this.setState({
      userProfileData: data
    })
  }
  handlePostsNamePreference = () => {
    let data = { ...this.state.userProfileData }
    data.publish_name_in_post = !data.publish_name_in_post
    this.setState({
      userProfileData: data
    })
  }
  handleClinicErrFun=(clinicData:any)=>{
    let clinicErrArr=[...this.state.clinicsErrors]
    for ( let i=0;i<clinicData.length;i++){
      if (!clinicErrArr[i]) {
        clinicErrArr[i] = {};
      }
      if(clinicData[i]?.attributes?.name==''||clinicData[i]?.attributes?.name==null){
        clinicErrArr[i].nameError=true
      }
      if(clinicData[i]?.attributes?.clinic_name==''||clinicData[i]?.attributes?.clinic_name==null){
        clinicErrArr[i].clinicNameError=true
      }
      if(clinicData[i]?.attributes?.contact_number==''||clinicData[i]?.attributes?.contact_number==null){
        clinicErrArr[i].phoneError=true
      }
    }
    this.setState({clinicsErrors:clinicErrArr},)
  }
  validateRescuerProfileUpdate = () => {
    let isValid = true;
    let formValid = this.handleValidateProfileData()
    if(!formValid){
      isValid = false;
    }
    let clinicData = [...JSON.parse(JSON.stringify(this.state.veterinariClinicsData))].map((data:any)=>{
      delete data.attributes.phone_number;
      return data
    })
   this.handleClinicErrFun(clinicData)
    let hasFalsyValue = clinicData.some((val:any)=>{
      return Object.values(val.attributes).some(value=>{
        if(typeof value == "string"){
          return !value.trim()
        }else{
          return !value
        }
      } )
    })
     if(this.state.pets.length<=0){
      return false
     }
    if(hasFalsyValue){
      isValid = false
    }
    if(this.state.userProfileData?.has_rescue_group){
      let hasFalsyValueInRescueGrp = !this.state.rescueGroupData[0].attributes.name.trim().length;
      
      if(hasFalsyValueInRescueGrp){
        this.setState({has_rescue_group_error:true})
        isValid = false
      }
    }
    if(this.state.facebookError || this.state.instaError || this.state.tiktokError || this.state.snapchatError || this.state.linkedinError){
      isValid = false
    }
    return isValid;

  }

  handleSubmitRescuerProfileUpdate = async () => {
     if(!this.validateRescuerProfileUpdate()) return
    let clinicData = [...JSON.parse(JSON.stringify(this.state.veterinariClinicsData))]
    clinicData = clinicData.map((obj:any)=>{
      let clinicData = {
        name:obj.attributes.name,
        clinic_name:obj.attributes.clinic_name,
        contact_number:obj.attributes.contact_number
      }
      if(obj.attributes.formatedPhoneNumber){
        clinicData.contact_number = obj.attributes.formatedPhoneNumber
        obj.attributes.contact_number = obj.attributes.formatedPhoneNumber
        delete obj.attributes.formatedPhoneNumber
      }
      let clinicPayload = JSON.parse(JSON.stringify(clinicData))
      if(obj.id != undefined){
        clinicPayload.id = parseInt(obj.id)
      }
      return clinicPayload
    })
  
    if(this.state.removeClinicArr.length>0){
      for(let i=0;i<=this.state.removeClinicArr.length-1;i++){
        let isId=this.state.veterinariClinicsDataTest.some((item:any) => item.id == this.state.removeClinicArr[i])
   isId&&clinicData.push({"id":this.state.removeClinicArr[i],"_destroy":true})
      }
      
    }
    const reqPayload = {
      data: {
        attributes:{
          full_phone_number:this.state.phoneNumber,
          "emirate_id":this.state.emirateId,
            "area":this.state.areaName,
            "nationality_id":this.state.nationalityId,
            "pets_references":this.state.pets,
            "publish_name_in_post":this.state.userProfileData.publish_name_in_post,
            "publish_image_in_post":this.state.userProfileData.publish_image_in_post,
            "facebook_url": this.state.userProfileData.facebook_url,
            "tiktok_url": this.state.userProfileData.tiktok_url,
            "linkedin_url": this.state.userProfileData.linkedin_url,
            "snapchat_url": this.state.userProfileData.snapchat_url,
            "instagram_url": this.state.userProfileData.instagram_url,
            "has_rescue_group":this.state.userProfileData.has_rescue_group,
            "veterinary_clinics_attributes":clinicData
        }
      }
    }
    let payLoad = {...JSON.parse(JSON.stringify(reqPayload))}
    if(this.state.userProfileData.has_rescue_group){
    let rescueData = [...JSON.parse(JSON.stringify(this.state.rescueGroupData))]
    rescueData = rescueData.map((obj:any)=>{
      let rescueData = {
        name:obj.attributes.name
      }
      let rescueGrpPayload = JSON.parse(JSON.stringify(rescueData))
      if(obj.id != undefined){
        rescueGrpPayload.id = parseInt(obj.id)
      }
      return rescueGrpPayload;
    })
    payLoad.data.attributes['rescue_groups_attributes'] = rescueData
    }
    const authToken = await storage.get("auth_token");
    const header = {
      "Content-Type": configJSON.contentTypeApiAdopterUserProfile,
      token : authToken
    };

    const body = payLoad

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiRescuerProfileUpdateCallId = requestMessage.messageId
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiAdopterUserProfileUpdate
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiAdopterUserProfile
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCheckRescueGrp = ()=>{
    let data = {...this.state.userProfileData}
    data.has_rescue_group = !data.has_rescue_group
    this.setState({
      userProfileData:data,
  has_rescue_group_error:false
    })
    let dataGroup = [...this.state.rescueGroupData]
    dataGroup[0].attributes.name ="";

      this.setState({ rescueGroupData:  dataGroup })
  }
  socialMediaChange =(name:string,value:string,errorName:string)=>{
    const urlPattern = /\bhttps?:\/\/\S+\b/;
    this.setState((prevState) => ({
      ...prevState,
      [errorName]:value === "" ? false : !urlPattern.test(value),
      userProfileData:{
        ...prevState.userProfileData,
        [name]: value,
      }

    }));
  }
  





handleAdopterEmirate = (e:any)=>{
  let selectedItem = this.state.emirateData?.find((val:any)=> val.name === e.target.value)
  this.setState({
    emirateName:selectedItem?.name,
    emirateId:selectedItem?.id,
    emirateError:false
  })
}
hanldeDropDownAreaSelect= (e:any)=>{
  const inputValue = e.target.value?.trim();
  const maxLength = 50;
  if(inputValue.length==0){
    this.setState({ areaError: true,areaName: e.target.value })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({ areaName:  e.target.value.substring(0, maxLength), areaError: false })
}

}
areaFeildErrorMessage = ()=>{
  if(!this.state?.areaName?.trim()){
    return "Please enter a value"
  }
  return ""
}

cityFeildErrorMessage = ()=>{
  if(!this.state.cityName?.trim()){
    return "Please enter a value"
  }
  return ""
}

handleCityChange= (e:any)=>{
  const inputValue = e.target.value?.trim();
  const maxLength = 50;
  if(inputValue.length==0){
    this.setState({ cityError: true,cityName: e.target.value })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({ cityName:  e.target.value.substring(0, maxLength), cityError: false })
}

}

handleNewPasswordChange = (e:any)=>{
  const special = configJSON.special
  let val = e.target.value
  this.setState({
    newPass:val
  })
  if(special.test(val)){
    this.setState({
      newPassError:false,
    })
  }else{
    this.setState({
      newPassError:true,
      newPassErrorMsg:"Please enter a valid password with minimum 5 characters"
    })
  }
}

handleConfirmPasswordChange = (e:any)=>{
  let val = e.target.value
  this.setState({
    confirmPass:val
  })

  if(val === this.state.newPass){
    this.setState({
      confirmPassError:false
    })
  }
}

validateChangePass = ()=>{
  let isValid = true
  if(!this.state.oldPassword.length){
    this.setState({
      oldPassError:true,
      oldPassErrMsg:"Password is incorrect"
    })
    isValid = false;
  }
  const special = configJSON.special

  if(this.state.newPass.length < 5 || !special.test(this.state.newPass) ){
    this.setState({
      newPassError:true,
      newPassErrorMsg:"Please enter a valid password with minimum 5 characters"
    })
    isValid = false;
  }
  if(!this.state.confirmPass.length){
    this.setState({
      confirmPassError:true,
      confirmPassErrorMessage:"Passwords do not match"
    })
    isValid = false;
  }
  if(this.state.confirmPass !== this.state.newPass){
    this.setState({
      confirmPassError:true,
      confirmPassErrorMessage:"Passwords do not match"
    })
    isValid = false;
  }
return isValid
  

}

handleAdopterProfilePasswordUpdate = async ()=>{
  if(!this.validateChangePass()) return;
  const authToken = await storage.get("auth_token");
  const header = {
    "Content-Type": configJSON.contentTypeApiAdopterUserPasswordUpdate,
    token : authToken
  };
  const body = {
    "data":{
      "current_password":this.state.oldPassword,
        "new_password":this.state.newPass,
        "confirm_password":this.state.confirmPass
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAdpoterPasswordUpdateCallId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiAdopterUserPasswordUpdate
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiAdopterUserPasswordUpdate
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleAdopterImageUpload = (event: any) => {
const targetData= event.target as HTMLInputElement;
const selectedImage: File = (targetData.files as FileList)[0];
const maxFileSize = 5 * 1024 * 1024; 
const file = event?.target?.files[0];
const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
if (file && allowedFormats.includes(file.type)) {
if(selectedImage.size <= maxFileSize){
  this.setState({
    imageError:false,
    fileFormateErr:false
  })
  const reader = new FileReader();
    reader.onload = (e:any) => {
      this.setState({ selectedProfileImg: e.target.result },() => {
        event.target.value = "";
    });
    };
    reader.readAsDataURL(selectedImage);

  const formData = new FormData();
  formData.append("image",selectedImage)
  this.handleAdopterImgUpdate(formData)
}else{
  this.setState({
    imageError:true,
  })
}
}else{
  this.setState({fileFormateErr:true})
}
 };

 handleValidateProfileData = ()=>{
  let isValid = true;
    if(this.state.residence === "United Arab Emirates" || this.state.residence === "United Arab Emairates"){
    if(this.state.emirateName == "Select" || this.state.emirateName == undefined ){
      this.setState({
        emirateError : true
      })
      isValid = false;
    }
    if(!this.state?.areaName?.trim()){
      this.setState({
        areaError : true
      })
      isValid = false;
    }
  }else{
    if(!this.state?.cityName?.trim()){
      this.setState({
        cityError: true
      })
      isValid = false
    }
  }
  
  
  if(this.state.phoneError){
    isValid = false;
  }
  if(this.state.petsError){
    isValid = false
  }
  return isValid;
 }
handleValidationForAdopter=()=>{
  let isValidFields = true;
    if(this.state.residence === "United Arab Emirates" || 
    this.state.residence === "United Arab Emairates"){
    if(this.state.emirateName == undefined|| this.state.emirateName == "Select" ){ 
      isValidFields = false;
      this.setState({emirateError : true} )
    }
    if(!this.state?.areaName?.trim()){
      this.setState({
        areaError : true
      })
      isValidFields = false;
    }
  }else{
    if(!this.state?.cityName?.trim()){
      isValidFields = false
      this.setState({
        cityError: true
      })
    }
  }
  if(this.state.petsError){ isValidFields = false }
  if(this.state.phoneError){isValidFields = false}
  if(isValidFields){
   this.handleAdopterProfileSubmit()
  }
}
handleAdopterProfileSubmit = async ()=>{
  const authToken = await storage.get("auth_token");
  const header = {
    "Content-Type": configJSON.contentTypeApiAdopterUserProfile,
    token : authToken
  };
  
      
  let data = JSON.parse(JSON.stringify({
    "email":this.state.email,
    "full_phone_number":this.state.phoneNumber,
    
    "pets_references": this.state.pets,
    "nationality_id":this.state.nationalityId,
    "country_id":this.state.countryId,
    "emirate_id":this.state.emirateId,
    'gender':this.state.gender,
    "date_of_birth":this.state.dOB,
    ...(this.state.cityName?.length>0&&{"area":null,"city":this.state.cityName}),
    ...(this.state.areaName?.length>0&&{"city":null,"area":this.state.areaName})
    

}))

  const body = {
    "data":{
      "attributes": data
    }
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAdpoterProfileUpdateCallId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiAdopterUserProfileUpdate
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiAdopterUserProfile
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleAdopterImgUpdate =async (formData:FormData) => {
  this.setState({isLoading:true})


  const authToken = await storage.get("auth_token");
  const header = {
    token : authToken
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAdpoterProfileImgUpdateCallId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiAdopterUserProfileImgUpdate
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiAdopterUserProfileImg
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleAdopterProfileDelete = async ()=>{
  const authToken = await storage.get("auth_token");
  const header = {
    token : authToken
  };
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiAdpoterProfileDeleteCallId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.endPointApiAdopterUserProfileImgDelete
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodTypeApiAdopterUserProfileImgDelete
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
removeClinic = (i:number,id:any)=>{
let newArr=[...this.state.removeClinicArr,id]
  let clinicData = [...this.state.veterinariClinicsData];
  let clinicErrors = [...this.state.clinicsErrors]
  clinicErrors.splice(i,1)
  clinicData.splice(i,1)
  this.setState({
    veterinariClinicsData:clinicData,
    clinicsErrors : clinicErrors,
    removeClinicArr:newArr
  })
}

resetChanges = (responseJson:any)=>{
  const [year, month, day] = responseJson?.data?.attributes?.date_of_birth.split("-");
const formattedDate = `${day}/${month}/${year}`;
  this.setState({
    userProfileData: responseJson?.data?.attributes,
    email: responseJson?.data?.attributes?.email,
    firstName: responseJson?.data?.attributes?.first_name,
    lastName: responseJson?.data?.attributes?.last_name,
    phoneNumber: "+" + responseJson?.data?.attributes?.full_phone_number,
    emirateName: responseJson?.data?.attributes?.emirate?.name || "Select",
    areaName: responseJson?.data?.attributes?.area?.trim(),
    gender: responseJson?.data?.attributes?.gender,
    dOB: formattedDate,
    nationality: responseJson?.data?.attributes?.nationality?.name || "Select",
    pets: responseJson?.data?.attributes?.pets_references||[],
    countryId: responseJson?.data?.attributes?.country_id,
    residence:responseJson?.data?.attributes?.country,
    emirateId:responseJson?.data?.attributes?.emirate?.id,
    nationalityId: responseJson?.data?.attributes?.nationality?.id,
    rescueGroupData: responseJson?.data?.attributes?.rescue_groups?.data,
    cityName:responseJson?.data?.attributes?.city,
    fomatMObileNumber:responseJson?.data?.attributes?.full_phone_number,
    veterinariClinicsDataTest:responseJson?.data?.attributes?.veterinary_clinics?.data,

  
  })

  if (responseJson?.data?.attributes?.veterinary_clinics?.data?.length) {
    let clinicData = responseJson.data.attributes.veterinary_clinics.data.map((data: any) => {
      data.attributes['isPhoneValid'] = data.attributes.phone_number ? true : false;
      data.attributes['formatedPhoneNumber'] = data.attributes.contact_number
      return data;
    })
    let clinicEntries = [...this.state.clinicsErrors]
    responseJson.data.attributes.veterinary_clinics.data.forEach((entry:any)=>{
      clinicEntries.push({nameError:false,clinicNameError:false,phoneError:false})
    })
    this.setState({
      veterinariClinicsData: clinicData,
      clinicsErrors:clinicEntries
    })
  } else {
    let clinicData = {
      attributes: {
        'clinic_name': '',
        'name': '',
        'phone_number': '',
        'isPhoneValid': false,
        'formatedPhoneNumber': ''
      }
    }
    this.setState({
      veterinariClinicsData: [clinicData],
      clinicsErrors:[{nameError:false,clinicNameError:false,phoneError:false}]
    })
  }
  if (!responseJson?.data?.attributes?.rescue_groups.data.length) {
    let rescueData = {
      attributes: {
        'name': "",
      }
    }
    this.setState({
      rescueGroupData: [rescueData],
      
    })
  }

}

//tooltip changes
handleVeterinaryMouseHover=()=>{
  this.setState({tooltipOpenchannelVeterinaryTooltip:true});
};
 handleVeterinaryMouseOver=()=>{
  this.setState({tooltipOpenchannelVeterinaryTooltip:false});
}
 handleVeterinary=()=>{
    this.setState({tooltipOpenchannelVeterinaryTooltip:!this.state.tooltipOpenchannelVeterinaryTooltip});
}

handleTooltipCloseChanelsData = () => {
  this.setState({ tooltipOpenchannelVeterinaryTooltip: false });
};


handleCategoriesMouseHover=()=>{
  this.setState({tooltipOpenchannelCategoriesTooltip:true});
};
 handleCategoriesMouseOver=()=>{
  this.setState({tooltipOpenchannelCategoriesTooltip:false});
}
 handleCategories=()=>{
    this.setState({tooltipOpenchannelCategoriesTooltip:!this.state.tooltipOpenchannelCategoriesTooltip});
}

handleTooltipCloseChanelsDataCategories = () => {
  this.setState({ tooltipOpenchannelCategoriesTooltip: false });
};


handlePetsReferenceMouseHover=()=>{
  this.setState({tooltipOpenchannelPetsReferenceTooltip:true});
};
 handlePetsReferenceMouseOver=()=>{
  this.setState({tooltipOpenchannelPetsReferenceTooltip:false});
}
 handlePetsReference=()=>{
    this.setState({tooltipOpenchannelPetsReferenceTooltip:!this.state.tooltipOpenchannelPetsReferenceTooltip});
}

handleTooltipCloseChanelsDataPetsReference = () => {
  this.setState({ tooltipOpenchannelPetsReferenceTooltip: false });
};
handleCloseSnackBar=()=>{
  this.setState({isModalOpen:false})
}
  // Customizable Area End

}
