import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';
import { setStorageData , getStorageData} from "../../../framework/src/Utilities";
import {imgPasswordInVisible, imgPasswordVisible, LittleCritters, Reptiles, birds, cat, dog, rabbit, LittleCrittersFilled, ReptilesFilled, birdsFilled, catFilled, dogFilled, rabbitFilled } from "./assets";



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  isSnackbarOpen: boolean
  firstNameError: boolean,
  firstNameErrorMessage: string,
  lastNameErrorMessage: string,
  lastNameError: boolean,
  emailerror: boolean,
  messageSnackbar: string
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  petAdopter: boolean,
  pertRescuer: boolean;
  lastName: string;
  userRoleImage:string
  emailErrorMessage: string
  checked: boolean;
  checkederror: boolean
  termsAndConditonPopUp:boolean
  privacyPolicyPopUp:boolean
  isSnackbarOpenForGenFosRequest:boolean
  isSnackbarOpenForGenFosRequestMessage:any
  terms_accept:boolean|null
  terms_acceptCheck:boolean
  termsAndConditon:boolean;
  privacyAndPolicy:boolean;
  allAnimalData:Array<any>;
  setHovered:any;
  dogsData:Array<any>;
  catsData:Array<any>;
  catsCurrentPage:number;
  dogsCurrentPage:number;
  selectedDog:any;
  selectedCat:any;
  impactData:Array<any>;
  sponserData:Array<any>;
  user_id:number|null
  contactSnackBar:boolean
  contactSnackBarmsg:string;
  bannerAds:any,
  adsList:Array<any>;
  blogsList:Array<any>;
  bannerCurrentPage:number;
  pathName:any;
  briefText:any;
  is_visible_sponsor_section:any;
  genericPosterDescription : string;
  currentIndex:number;
  isMouseHover: boolean;
  isImageLoading:boolean;
  genericFosterImage : string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  signUpApiId: string = ""
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  getDogsApiCallId:string="";
  getCatsApiCallId:string="";
  getOurImpactCallId:string="";
  getSponsersCallId:string="";
  favoriteApiId:string="";
  getBannerImageApiId:string="";
  getAdsApicallId:string ="";
  getBlogsApicalId:string = "";
  getGenericPosterApiId:string ="";
  intervalId: NodeJS.Timeout | null  =null;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  getAnimalTypeApi:string='';
  getBriefAnimalTypeApi:string='';
  getShow_hide_sponsor_sectionApi:string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      contactSnackBar:false,
      contactSnackBarmsg:"",
      firstName: "",
      email: "",
      termsAndConditonPopUp:false,
      privacyPolicyPopUp:false,
      password: "",
      petAdopter: true,
      pertRescuer: false,
      reTypePassword: "",
      otpAuthToken: "",
      isSnackbarOpen: false,
      data: [],
      messageSnackbar: "",
      lastName: "",
      emailErrorMessage: "",
      checkederror: false,
      checked: false,
      userRoleImage:"",
      passwordHelperText: "",
      termsAndConditon:false,
      genericPosterDescription: "",
      privacyAndPolicy:false,
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      firstNameErrorMessage: "",
      lastNameErrorMessage: "",
      firstNameError: false,
      lastNameError: false,
      emailerror: false,
      isSnackbarOpenForGenFosRequest:false,
      isSnackbarOpenForGenFosRequestMessage:'',
      terms_accept:null,
      terms_acceptCheck:false,
      allAnimalData:[],
      setHovered:"",
      dogsData:[],
      catsData:[],
      catsCurrentPage:0,
      dogsCurrentPage:0,
      selectedDog:"",
      selectedCat:"",
      impactData:[],
      sponserData:[],
      user_id:null,
      bannerAds:null,
      adsList:[],
      blogsList:[],
      bannerCurrentPage:0,
      pathName:'',
      briefText:'',
      is_visible_sponsor_section:false,
      currentIndex:0,
      isMouseHover:false,
      isImageLoading:true,
      genericFosterImage: ''
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start   
   
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson ) {
     
      if (apiRequestCallId === this.signUpApiId) {
if (responseJson?.message === "Email address already exists") {
          this.setState({emailErrorMessage:"Email already exists",emailerror:true})
        }
        else {
          setStorageData('user_Id',responseJson?.data?.id)
          storage.set("otp_token", responseJson?.meta?.otp_token)
          storage.set("token", responseJson?.meta?.token)
          storage.set("email", this.state.email)
          storage.set("firstName", this.state.firstName)
          this.props.navigation.navigate("EmailAccountOtpVerify")
        }
        
      }
      if(apiRequestCallId === this.getDogsApiCallId){
        this.handleGetDogsRes(responseJson)
      }else if(apiRequestCallId === this.getCatsApiCallId){
        this.handleGetCatsRes(responseJson)
      }else if(apiRequestCallId === this.getOurImpactCallId){
         this.handleGetImpactRes(responseJson)
      }else if(apiRequestCallId === this.getSponsersCallId){
         this.handleGetSponsersRes(responseJson)
      }
      this.extraApiCall(apiRequestCallId,responseJson)
      this.getGenericData(apiRequestCallId,responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  extraApiCall=(apiRequestCallId:any,responseJson:any)=>{
     if(apiRequestCallId === this.getBannerImageApiId){
      this.handleGetBannerImageRes(responseJson)
    }else if(apiRequestCallId === this.getAdsApicallId){
      this.handleGetAdsRes(responseJson)
    }else if(apiRequestCallId === this.favoriteApiId){
    }else if(apiRequestCallId === this.getBlogsApicalId){
      this.handleGetBlogsRes(responseJson)
    }
    else if(apiRequestCallId===this.getAnimalTypeApi){
        this.handleSelAnimalType(responseJson)
    }
    else if(apiRequestCallId===this.getBriefAnimalTypeApi){
       this.setBriefAnimalTypeText(responseJson)
    }
    else if(apiRequestCallId==this.getShow_hide_sponsor_sectionApi){
      this.set_Show_hide_sponsor_section(responseJson)
    }
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
closePrivacyPolicy=()=>{
  this.setState({privacyPolicyPopUp:false})
}
closeTermsCondition=()=>{
  this.setState({termsAndConditonPopUp:false})
}
  hanldeCheckBox = () => {
    if (!this.state.checked === false) {
      this.setState({ checkederror: true })
      this.setState({ checked: !this.state.checked })

    }
    else {
      this.setState({ checkederror: false })

      this.setState({ checked: !this.state.checked })
    }
  }
  handleTermsAndPolicy = () => {
    if (!this.state.terms_acceptCheck === false) {
      this.setState({ checkederror: true })
      this.setState({ terms_acceptCheck: !this.state.terms_acceptCheck })

    }
    else {
      this.setState({ checkederror: false })

      this.setState({ terms_acceptCheck: !this.state.terms_acceptCheck })
    }
  }
  closeSnackbar = () => {
    this.setState({ isSnackbarOpen: false })
  }
  handleNavigateLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  getSignUpFunction = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      "data": {
        "attributes": {
          "user_role": await storage.get("user_type"),
          "email": this.state.email,
          "first_name": this.state.firstName,
          "last_name": this.state.lastName,
          "terms_accepted": this.state.checked
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpApiId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.signUpApiEndpoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
 

  handleEmail = (value: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if(value===""){
      this.setState({
        emailerror: true,
        email: value,
        emailErrorMessage:"Please enter a value"
      });
      return false
    }
    else if (!value.match(emailPattern)) {
      this.setState({
        emailerror: true,
        email: value,
        emailErrorMessage:"Please enter a valid email address"
      });
      return false
    } else {
      this.setState({
        emailerror: false,
        email: value,
        emailErrorMessage:""
      });
      return true
    }
  }
  handleNavigateEmailForm = () => {
    this.state.petAdopter ? storage.set("user_type", "adopter") : storage.set("user_type", "rescuer")
    this.props.navigation.navigate("EmailAccountVerification")
  }
  handleValidationFirstLast = (fieldName: string, value: string, maxLength: number) => {
    const inputValue =value?.trim();
  let   errorMessage = "Please enter a value";
    
    if(inputValue.length==0){
      this.setState((prevState) => ({
        ...prevState,
        [`${fieldName}Error`]:true,
        
        [`${fieldName}ErrorMessage`]: errorMessage,
        [fieldName]:  value.slice(0,maxLength),
      }));
return false
    }
  else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState((prevState) => ({
      ...prevState,
      [`${fieldName}Error`]:false,
      [`${fieldName}ErrorMessage`]: errorMessage,
      [fieldName]: value.slice(0,maxLength),
    }));
return true

  }

   
  }


  handleValidation = () => {

    const emailCheck=this.handleEmail(this.state.email)
    const error = {
      checkederror: !this.state.checked
    };
    const firstName = this.handleValidationFirstLast("firstName", this.state.firstName, 20)
    const lastName = this.handleValidationFirstLast("lastName", this.state.lastName, 20)

    this.setState(error);


    if (emailCheck && firstName && lastName && this.state.email !== "" && this.state.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) && this.state.checked) {
      this.getSignUpFunction()
    }
  };


  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputlastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputlastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  async componentDidMount(){
    this.setState({pathName:window.location.pathname})
    this.handleGetAnimalType()
    this.getBriefAnimalType()
    this.show_hide_sponsor_section()
    this.handleAutoFill()
    const image=await storage.get("signup_screen_image");
    const genericFosterImage=await storage.get("generic_foster_image")
    if(image){

      this.setState({userRoleImage:image})
    }
    if(genericFosterImage){
      this.setState({genericFosterImage:genericFosterImage})
    }
    const isState=this.props?.navigation?.history?.location?.state
    if(isState?.isSnackBarOpen){
    
      this.setState({isSnackbarOpenForGenFosRequest:isState.isSnackBarOpen,isSnackbarOpenForGenFosRequestMessage:isState.msg})
    this.props?.navigation?.history?.replace()
    }
    this.intervalId = setInterval(() => {
      if(!this.state.isMouseHover){
        this.setState((prevState) => ({
          currentIndex: (prevState.currentIndex + 1) % prevState.adsList.length,
        }));
      }
    }, 4000);
    
  }

  async componentWillUnmount(): Promise<void> {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  handleMouseEnter = (index:number) => {
    this.setState((prevState:any) => ({
      setHovered: {
        ...prevState.setHovered,
        [index]: true,
      },
    }));
  };

  handleMouseLeave = (index:number) => {
    this.setState((prevState:any) => ({
      setHovered: {
        ...prevState.setHovered,
        [index]: false,
      },
    }));
  };
  handleGetAllDogsData = async ()=>{
    let authorization_token= await getStorageData('auth_token')

    let header ;
    if(authorization_token){
      header= {
        "Content-Type": "application/json",
        token : authorization_token
      }
    }else{
      header = {
        "Content-Type": "application/json",
      };
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getDogsApiCallId = requestMessage.messageId
    let endPoint = "/bx_block_advanced_search/search/lending_page_posts?animal_type=Dogs"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetDogsRes =(responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined){
      this.setState({
        dogsData:responsJson.data
      })
    }
  }
  handleGetAllCatsData = async ()=>{
    let authorization_token= await getStorageData('auth_token')

    let header ;
    if(authorization_token){
      header= {
        "Content-Type": "application/json",
        token : authorization_token
      }
    }else{
      header = {
        "Content-Type": "application/json",
      };
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getCatsApiCallId = requestMessage.messageId
    let endPoint = "/bx_block_advanced_search/search/lending_page_posts?animal_type=Cats"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetOurImpactData = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getOurImpactCallId = requestMessage.messageId
    let endPoint = "/bx_block_landingpage/landing_page"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetAllSponsersData = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getSponsersCallId = requestMessage.messageId
    let endPoint = "/bx_block_content_management/sponsor_sections"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetBannerImageData = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBannerImageApiId = requestMessage.messageId
    let endPoint = "/bx_block_custom_ads/advertisements/1"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetAdsData = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getAdsApicallId = requestMessage.messageId
    let endPoint = "/bx_block_custom_ads/advertisements"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handlegetGenericPaster();
  }

  getGenericData = (callerId:string, responseJson : { data : {
    description : string
  }}) => {
      if(callerId === this.getGenericPosterApiId){
        this.setState({genericPosterDescription : responseJson.data.description});
      }
  }
  handlegetGenericPaster = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGenericPosterApiId = requestMessage.messageId
    let endPoint = "/bx_block_landingpage/landing_page/generic_foster"
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGetBlogsApi = ()=>{
    const header = {
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBlogsApicalId = requestMessage.messageId
    let endPoint = "/bx_block_blogpostsmanagement2/blog_posts/sections"
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
    );
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
  
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleGetCatsRes =(responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined ){
      this.setState({
        catsData:responsJson.data
      })
    }
  }
  handleGetImpactRes =(responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined){
      let bgArray = [{background:"#E8D658"},{background:"#FF5E5B"},{background:"#03B5AA"}]
      let mergedArray = responsJson.data.map((data:any,index:number)=> ({
        ...data , ...bgArray[index]
        }))
      this.setState({
        impactData: mergedArray
      })
    }
  }
  handleGetSponsersRes =(responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined &&  responsJson.message == undefined){
      this.setState({
        sponserData:responsJson.data
      })
    }
  }
  handleGetBannerImageRes = (responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined &&  responsJson.message == undefined){
      this.setState({
        bannerAds:responsJson.data
      })
    }
  }
  handleGetAdsRes = (responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined &&  responsJson.message == undefined){
      this.setState({
        adsList:responsJson.data
      })
    }
  }
  handleGetBlogsRes = (responsJson:any)=>{
    if(responsJson && responsJson.errors == undefined &&  responsJson.message == undefined){
      let bgArray = ["#E8D658","#FF5E5B","#03B5AA"]
      let blogsData = responsJson.data.filter((data:any)=> data.attributes.name !== "Other").map((item:any,i:number)=> {
        item['bg']= bgArray[i]
        return item
      });
      this.setState({
        blogsList:blogsData
      })
    }
  }
 
  catPrevious = ()=>{
    this.setState(prev=>({
      catsCurrentPage : prev.catsCurrentPage - 1
    }))
  }
  catNext = ()=>{
    this.setState(prev=>({
      catsCurrentPage : prev.catsCurrentPage + 1
    }))
  }
  dogPrevious = ()=>{
    this.setState(prev=>({
      dogsCurrentPage : prev.dogsCurrentPage - 1
    }))
  }
  dogNext = ()=>{
    this.setState(prev=>({
      dogsCurrentPage : prev.dogsCurrentPage + 1
    }))
  }

  handleBannerNext = ()=>{
    this.setState(prev=>({
    bannerCurrentPage: prev.bannerCurrentPage + 1
    }))
  }
  handleBannerPrev = ()=>{
    this.setState(prev=>({
    bannerCurrentPage: prev.bannerCurrentPage - 1
    }))
  }

  handleSelectedDog = (index:number) => {
    this.setState((prevState:any) => ({
      selectedDog: {
        ...prevState.selectedDog,
         [index]: prevState.selectedDog && prevState.selectedDog[index] ? !prevState.selectedDog[index] :true,
      },
    }));
  };
  handleSelectedCat = (index:number) => {
    this.setState((prevState:any) => ({
      selectedCat: {
        ...prevState.selectedCat,
        [index]: prevState.selectedCat && prevState.selectedCat[index] ? !prevState.selectedCat[index] :true,
      },
    }));
  };
  handlePetNavigation = async (data:any)=>{
 
    if(data?.attributes?.account_id == this.state.user_id){
      this.props.navigation.navigate("ProductDescription",{id:data.attributes.id,postDetails:false})
    }else{
    this.props.navigation.navigate("ProductDescriptionPublic",{id:data.attributes.id,postDetails:false})
    }
  }
  navigateToRequestManagment = ()=>{
    this.props.navigation.navigate("RequestManagementGenericFoster")
  }

  handleFavorite=async(fav_id:any,isFav:any, type:string,index:number)=>{
    let authorization_token= await getStorageData('auth_token')
    if(!authorization_token){
    this.props.navigation.navigate("EmailAccountLoginBlock")
    return
    }
    if(type === 'dog'){
      let dogData = [...this.state.dogsData];
      dogData[index].attributes.favourites = !isFav
      this.setState({
        dogsData:dogData
      })
    }else{
      let catData = [...this.state.catsData];
      catData[index].attributes.favourites = !isFav
      this.setState({
        catsData:catData
      })
    }
    let method=isFav?'DELETE':'POST'
    const headers = {
    "Content-Type": "application/json",
    'token':authorization_token
    };
    const body={
    'favouriteable_id':fav_id
    }
    const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.favoriteApiId = requestMessage.messageId
    
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    
    `bx_block_favourites/favourites`
    
    );
    
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
    );
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
    );
    
    requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
    }
    handleGetAnimalType= async () => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAnimalTypeApi = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.animal_GetTypeEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  handleSelAnimalType = (res: any) => {
   
    let newData = res?.data||[]
    for (let i = 0; i <= newData?.length - 1; i++) {
      if (newData[i].name == 'Dogs') {
        newData[i].fill = true
        newData[i].imageSrc = dog
        newData[i].imageSrcFilled = dogFilled
      }

      if (newData[i].name == 'Cats') {
        newData[i].fill = false
        newData[i].imageSrc = cat
        newData[i].imageSrcFilled = catFilled
      }
      if (newData[i].name == 'Rabbits') {
        newData[i].fill = false
        newData[i].imageSrc = rabbit
        newData[i].imageSrcFilled = rabbitFilled
      }

      if (newData[i].name == 'Birds') {
        newData[i].fill = false
        newData[i].imageSrc = birds
        newData[i].imageSrcFilled = birdsFilled
      }
      if (newData[i].name == 'Reptiles') {
        newData[i].fill = false
        newData[i].imageSrc = Reptiles
        newData[i].imageSrcFilled = ReptilesFilled
      }
      if (newData[i].name == 'Little Critters') {
        newData[i].fill = false
        newData[i].imageSrc = LittleCritters
        newData[i].imageSrcFilled = LittleCrittersFilled
      }

    }
   this.setState({allAnimalData:newData})
  }
 
  getBriefAnimalType = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBriefAnimalTypeApi = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBriefAnimalType_EndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }  
  setBriefAnimalTypeText=(resp:any)=>{
     this.setState({briefText:resp?.data[0]?.attributes?.brief})
  }
  show_hide_sponsor_section=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShow_hide_sponsor_sectionApi = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.show_hide_sponsor_section_EndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  set_Show_hide_sponsor_section=(resp:any)=>{
  this.setState({is_visible_sponsor_section:resp?.data?.attributes?.is_visible})
  }

  handleBannerHoverIn = () => {
    this.setState({ isMouseHover: true });
  };

  handleBannerHoverOut = () => {
    this.setState({ isMouseHover: false });
  };

  handleImageLoad = () => {
    this.setState({ isImageLoading: false });
  };

  moveLeft = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex >  0 ? prevState.currentIndex -1 : prevState.adsList.length -1) 
    }));  
  };

  moveRight = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex <  prevState.adsList.length - 1 ? prevState.currentIndex  + 1 : 0) 
    }));  
    
  };
  handleAutoFill=()=>{
    let urlParams = new URLSearchParams(window?.location?.search); 
    let pathName=window?.location?.pathname?.includes('signup-email')
    if(pathName){
    let email =urlParams.get('email')||'' ;
    let first_name =urlParams.get('first_name')||'' ;
    let last_name = urlParams.get('last_name')||'';
   
    if(this.handleCheckExist(email)&&this.handleCheckExist(first_name)&&this.handleCheckExist(last_name) ){
      this.setState({firstName:first_name,lastName:last_name,email:email})
      setStorageData('user_type','rescuer')
    }

  }
}
  handleCheckExist=(value:string)=>{
   if(value?.trim()!==''&&value!==null&&value!==undefined) {
    return true
   }
   else{
    return false
   }
  }
  handleClosediologComponent2=()=>{
    this.setState({contactSnackBar:false})
    this.props?.navigation?.history?.replace({
      ...this?.props?.navigation?.history.location,
      state: {
          ...this.props?.navigation?.history?.location?.state,
          msg: ''
      }
  });
  }
  // Customizable Area End
}
