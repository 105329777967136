import React from "react";
// Customizable Area Start
import { lazy } from "react";
import { styled, withStyles } from "@material-ui/core/styles";
const baseUrl = require("../../../framework/src/config.js")
import { Box, Grid,Input,Typography, MenuItem, Select, TextField, Button, FormControl, IconButton, Tooltip, TextareaAutosize, Dialog } from "@material-ui/core";
import { fillStarIcon, camera, video, RightIcon, iconInfo, VideoPlayIcon, crossIcon, document2, crossIconPlan, documentIcon, starIcon, calender } from "../../productdescription/src/assets";
import { KeyboardArrowDown } from "@material-ui/icons";
import { enUS } from "date-fns/locale";
import CreateRescuerPostPageController, { configJSON, Props,HandleNewSelectConfig,HandleMultiSelectConfig,Item,TexteriaFieldParams} from "./CreateRescuerPostPageController";
import { checkBoxFalse, checkBoxTrue } from "../../email-account-registration/src/assets";
const Calendar = lazy(() => import('react-date-range').then(mod => ({ default: mod.Calendar })));
import Loader from "../../../components/src/Loader.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'
import { closeIconSvg } from "./assets";
import moment from "moment";
// Customizable Area End
// Customizable Area Start

const LightTooltip = withStyles({
    tooltip: {
        backgroundColor: "#FFF",
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        padding: "8px 16px",
        lineHeight: "22px",
        borderRadius: "8px",
        border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
        boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
    },
})(Tooltip);
const LightTooltip2 = withStyles({
    tooltip: {
        width: "219px",
        backgroundColor: "#FFF",
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        padding: "8px 16px",
        lineHeight: "22px",
        borderRadius: "8px",
        border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
        boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
    },
})(Tooltip);

const LightTooltip3 = withStyles({
    tooltip: {
        width: "175px",
        backgroundColor: "#FFF",
        color: 'rgba(0, 0, 0, 0.87)',
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        padding: "8px 16px",
        lineHeight: "22px",
        borderRadius: "8px",
        border: "0.5px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
        boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)"
    },
})(Tooltip);
// Customizable Area End
export default class CreateRescuerPostPage extends CreateRescuerPostPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleError = (error: boolean) => {
        return error && (
            <Typography
                style={Allstyle.errorTextStyle}
            >
                Please select an option
            </Typography>)
    }
   
   

    handleImage = () => {
        return <MainBoxItem >
            <SecondBoxItem2>

                <Typography style={{ ...Allstyle.nameSTyle, }}>
                    Images  {this.importantField()}
                    <br />
                    <span style={{ ...Allstyle.maxStyle, }}>
                        (You can add max 6 images,recommended image size is 548X460)
                    </span>
                </Typography>
              { this.state.lengthOfImage&& <Typography style={{...Allstyle.errorTextStyle,width:"175px"}}>
              You cannot upload more than 6 images
                </Typography>}
                {(this.state.imgError) && <Typography style={Allstyle.errorTextStyle}>
                    {this.state.imgErrorValue}
                </Typography>}
                    <Typography style={Allstyle.errorTextStyle}>
                    {this.state.imageMessage}
                </Typography>
            </SecondBoxItem2>
            <Box>

            <ImageGrid container >
                {
                   this.state.pathname!=="editpost"? this.state.selectedImageDataBase.map((item: any, index: number) => {
                        return <Box style={{ position: "relative" }} key={`${item.name}${index}${item.size}`}>
                            <Box style={{ ...Allstyle.imgStyle, background: "unset" }}>
                                <img src={URL.createObjectURL(item)} style={{
                                    height: "104px", borderRadius: "8px", objectFit: "cover",
                                    width: "108px"
                                }} />
                            </Box>
                            <img src={crossIcon} id="imagremove" onClick={() => {
                                this.removeImage(index)
                            }} style={{ position: "absolute", top: "-8px", right: "-10px", cursor: "pointer" }} />
                              <img 
                              src={this.state.primaryImage==index?fillStarIcon:starIcon}
                              id="staricon"
                              style={{ position: "absolute", top: "3px", left: "3px", cursor: "pointer" }} 

                              onClick={()=>{
                                this.setState({primaryImage:index})
                              }}
                              />
                        </Box>
                    })
                    :
                   [... this.state.newImagesData].map((item: any, index: number) => {
                    const imageUrl = this.handleUrl(item)

                      return <Box style={{ position: "relative" }} key={`${item.name}${index}`}>
                            <Box style={{ ...Allstyle.imgStyle, background: "unset" }}>
                                <img 
                                          src={imageUrl}
                                style={{
                                    width: "108px"
                                    , height: "104px", borderRadius: "8px", objectFit: "cover"
                                }} />
                            </Box>
                            <img src={crossIcon} id="imagremove2" onClick={() => {
                                this.removeEditImage(index)
                            }} style={{ position: "absolute", top: "-8px", right: "-10px", cursor: "pointer" }} />
                              <img 
                              id="staricon2"
                              src={this.handleStarIcon(item,index)}
                              onClick={()=>{
                                this.setPrimaryImage(item,index)
                              }}
                                style={{ position: "absolute", top: "3px", left: "3px", cursor: "pointer" }} />
                        </Box>
                    })
                }
                {this.handleConditionEditImage()&& <label htmlFor="imageUpload" style={{ ...Allstyle.imgStyle, cursor: "pointer" }}>
                    <img src={camera} style={{ width: "24px", marginBottom: "12px", cursor: "pointer" }} />
                    <Typography style={Allstyle.imgFieldSyle}>
                        Add images
                    </Typography>
                    <input
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        data-test-id="imageUpload"
                        type="file"

                        multiple
                        onChange={this.handleImageUpload}
                        style={{ display: "none" }}
                    />
                </label>}
            
            </ImageGrid>
            <Typography style={{minWidth:"324px",maxWidth:"324px",fontSize:"12px",
        fontWeight: 400,
        lineHeight: "18px",
        fontFamily: "Lato",
        color:"#64748B",
        marginTop:"16px",
        alignItems:"baseline",
        display:"flex",
        }}>
          <span style={{marginRight:"4px"}}>
          Note:
          </span>
          <span style={{marginRight:"4px"}}>
          Image having
          </span>
          <img src={fillStarIcon} style={{width:"12px",height:"12px"}}/>
          <span style={{marginLeft:"4px"}}>
          symbol will be used as primary image.
          </span>
            </Typography>
            </Box>
        </MainBoxItem>
    }
    handleStarIcon=(item:any,index:number)=>{
        return this.state.primaryImage === (index)|| this.state.primaryImage === (item?.id) ? fillStarIcon : starIcon
    }
    setPrimaryImage=(item:any,index:number)=>{
      
this.setState({ primaryImage: index});
    }
    handleUrl=(item:any)=>{
       return  item?.url ? `${baseUrl.baseURL}${item.url}` : URL.createObjectURL(item)
    }
    handleVideo = () => {
        return <MainBoxItem >
            <SecondBoxItem2>
                <Typography style={{ ...Allstyle.nameSTyle, }}>
                    Video
                    <span style={Allstyle.optionalSTyle}>

                        {" "}  (Optional)
                    </span>
                    <br />
                    <span style={{ ...Allstyle.maxStyle, }}>
                        (You can add max 2 videos)
                    </span>
                </Typography>
                { this.state.lengthOfVideo&& <Typography style={{...Allstyle.errorTextStyle,width:"175px"}}>
                You cannot upload more than 2 videos
                </Typography>}
                {this.state.videoError && <Typography style={Allstyle.errorTextStyle}>
                    {this.state.videoErrorvalue}
                </Typography>}
                <Typography style={Allstyle.errorTextStyle}>
                    {this.state.videoMessage}
                </Typography>
            </SecondBoxItem2>
            <ImageGrid container>
                {this.state.pathname=="editpost"?
                    this.state.VideoNewEditData.map((videoData:any, index:number) => {
                        const url=this.handleUrl(videoData)
                     return   <Box key={`${videoData.name}${index}`}style={{ position: "relative" }}>
                            <video style={{ width: "108px", height: "104px", borderRadius: "8px", objectFit: "cover" }}>
                            <source src={url}  />
                            </video>
                            <img
                                id="removevideo2"
                                src={crossIcon}
                                alt={`Remove Video ${index}`}
                                style={{ position: "absolute", top: "-8px", right: "-10px", cursor: "pointer" }}
                                onClick={() => this.removeEditVideo(index)}
                            />
                             <img
                   id="removevideo"
                   src={VideoPlayIcon}
                   alt={`Remove Video ${index}`}
                   style={{ position: "absolute",width:"32px",  top: "50%",transform: "translate(-50%, -50%)", left: "50%", cursor: "pointer" }}
                   onClick={() => this.openVideo(index)}
               />
                        </Box>
    })
    :
    this.state.selectVideoDataBase.map((videoData, index) => {
        return   <Box key={`${videoData.name}${index}${videoData.size}`} style={{ position: "relative" }}>
               <video style={{ width: "108px", height: "104px", borderRadius: "8px", objectFit: "cover" }}>
               <source src={URL.createObjectURL(videoData)} type={`video/${videoData.name.split('.').pop()}`} />
               </video>
               <img
                   id="removevideo"
                   src={crossIcon}
                   alt={`Remove Video ${index}`}
                   style={{ position: "absolute", top: "-8px", right: "-10px", cursor: "pointer" }}
                   onClick={() => this.removeVideo(index)}
               />
                 <img
                   id="removevideo"
                   src={VideoPlayIcon}
                   alt={`Remove Video ${index}`}
                   style={{ position: "absolute",width:"32px", top: "50%",transform: "translate(-50%, -50%)", left: "50%", cursor: "pointer" }}
                   onClick={() => this.openVideo(index)}
               />
           </Box>
})
                }

                {this.handleConditionEditVideo() && <label htmlFor="videoUpload" style={{ ...Allstyle.imgStyle, cursor: "pointer" }}>
                    <img src={video} style={{ width: "24px", marginBottom: "12px", cursor: "pointer" }} />
                    <Typography style={Allstyle.imgFieldSyle}>
                        Add Video
                    </Typography>
                    <input
                        id="videoUpload"
                        type="file"
                        accept="video/mp4, video/webm"
                        multiple
                        onChange={this.handleVideoUpload}
                        style={{ display: "none" }}
                    />
                </label>}

            

            </ImageGrid>
        </MainBoxItem>
    }
check=()=>{return  this.state.pdfData.length >= 1||this.state.pdfDataNew.length>=1}
alignItemsStyle=()=>{
    return this.check()?"":"center";
}
checkSize = (size: number): React.ReactNode => {
    const kilobytes = size / 1024;
    const megabytes = kilobytes / 1024;
  
    if (megabytes >= 1) {
      return <>{megabytes.toFixed(2)} MB</>;
    } else {
      return <>{kilobytes.toFixed(2)} KB</>;
    }
  };
  hanleNameCertificate=()=>{
    if(this.state.typeOfAnimalName=="Birds"||this.state.typeOfAnimalName=="Reptiles"){
        return 'Ownership Certificate'
    }
    if(this.state.typeOfAnimalName=="Little Critters"){
        return "Vaccination Book/Ownership Certificate"
    }
    else{
        return 'Vaccination Book'
    }
  }
    handlePdf = () => {
        return <>
            <MainGrd2
               
            >
                <Box style={{width:"175px"}} >

                <Typography style={{ ...Allstyle.nameSTyle, marginRight: "34px",paddingTop:0 }}>
                    {this.hanleNameCertificate()}   <span style={Allstyle.optionalSTyle}>

                        (Optional)
                    </span>

                </Typography>
                { this.state.lengthOfPdf&& <Typography style={{...Allstyle.errorTextStyle,width:"175px"}}>
                You cannot upload more than 3 files
                </Typography>}
                 </Box> 
                <MainBoxItem2ForPdf >
<Box>

                    {this.handlePdfErrorUPLOAD() && <label style={{}}>
                        <ButtonComponent variant="outlined" id="pdfUpload2" onClick={() => { (document.getElementById('pdfUpload')! as HTMLInputElement).click() }}>
                            Browse files
                        </ButtonComponent>
                        <input
                            id="pdfUpload"
                            accept=".pdf, .png, .jpg, .jpeg"
                            type="file"

                            onChange={(e) => this.handlePdfUpload(e)}
                            multiple
                            style={{ display: "none" }}
                        />

                    </label>

                    }
                  {  this.handlePdfError()&& <LightTooltip3 
                  id="tooltip3"
                  open={this.state.toolTip3}
                  onClose={this.handleTooltipCloseChanelsData3}
                  title="Accepted formats: PDF, PNG, JPG and JPEG with maximum file size of 3 MB" placement="right" >
                        <IconButtonStyle
                         id="tooltip33"
                        onMouseLeave={()=>this.handleHeadingMouseOver3()}
                        onMouseEnter={()=>this.handleHeadingMouseHover3()}
                         onClick={() => this.handleHeading3()} 
                         style={{ marginLeft: '12px' }}>
                            <img src={iconInfo}  title="Accepted formats: PDF, PNG, JPG, and JPEG with a maximum file size of 3 MB"/>
                        </IconButtonStyle>
                    </LightTooltip3>}
</Box>
                    {this.state.pdferror && <PStyle style={{ ...Allstyle.errorTextStyle, maxWidth: "374px" }}>
                        Invalid file format, Accepted formats: PDF, PNG, JPG and JPEG with maximum file size of 3 MB
                    </PStyle>}
                    {this.state.pathname=="editpost"?
                     this.state.pdfDataNew.map((item: any, index: number) => {
                        return (
                            <ImageStyleBox key={`${item?.name}${index}`}>
                                <Box style={{
                                     marginRight: "16px", alignItems: "center",
                                     borderRadius: "8px", width: "56px",
                                     background: "#CDF0EE80",
                                     display: "flex",
                                    height: "56px", justifyContent: "center",
                                   
                                }}>
                                    <img src={document2} style={{ width: "24px", height: "24px" }} />
                                </Box>
                                <Box style={{ width: "242px" }}>

                                    <Typography
                                        style={{
                                            fontWeight: 400,
                                            fontFamily: "lato",
                                            fontSize: "16px",
                                            color: "#0F172A",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}

                                    >
                                       {item?.name}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#64748B",
                                            fontFamily: "lato",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                        }}>
                                    {  this.checkSize(item?.size)}
                                    </Typography>
                                </Box>

                                <img src={crossIconPlan}
                                    onClick={() => this.handleRemovePdfUpload(index)}
                                    style={{ cursor: "pointer", width: "24px", height: "24px" }}
                                    id="removepdfPdf"
                                />

                            </ImageStyleBox>
                        );
                    })
                    :
                        this.state.pdfData.map((item: any, index: number) => {
                     

                            return (
                                <ImageStyleBox key={`${item.name}${index}${item.size}`}
                                >
                                    <Box style={{
                                        background: "#CDF0EE80",
                                        height: "56px", justifyContent: "center",
                                        borderRadius: "8px", width: "56px",
                                        marginRight: "16px", alignItems: "center",
                                        display: "flex"
                                    }}>
                                        <img src={document2} style={{ width: "24px", height: "24px" }} />
                                    </Box>
                                    <Box style={{ width: "242px" }}>

                                        <Typography
                                            style={{
                                                fontSize: "16px",
                                                fontWeight: 400,
                                                fontFamily: "lato",
                                                color: "#0F172A",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}

                                        >
                                            {item.name}
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontSize: "16px",
                                                fontFamily: "lato",
                                                color: "#64748B",
                                                fontWeight: 400,
                                            }}>
                                                                {this.checkSize(item?.size)}

                                        </Typography>
                                    </Box>

                                    <img src={crossIconPlan}
                                        id="removepdf"
                                        style={{ cursor: "pointer", width: "24px", height: "24px" }}
                                        onClick={() => this.handleRemovePdf(index)}
                                    />

                                </ImageStyleBox>
                            );
                        })
                    }
            {this.state.pdferror && <PStyle2 style={{ ...Allstyle.errorTextStyle, maxWidth: "374px" }}>
                Invalid file format, Accepted formats: PDF, PNG, JPG and JPEG with maximum file size of 3 MB
            </PStyle2>}
                </MainBoxItem2ForPdf>
            </MainGrd2>
            {this.handlePaginationPdf() && <PdfDataComponent style={{
                paddingBottom: "40px",
            }}>
                {this.state.pathname=="editpost"?
                    this.state.pdfDataNew.map((item: any, index: number) => {
                        return (
                            <ImageStyleBox2 key={`${item.name}${index}`}>
                                <Box style={{ background: "#CDF0EE80", marginRight: "16px", alignItems: "center", borderRadius: "8px", width: "56px", height: "56px", justifyContent: "center", display: "flex" }}>
                                    <img src={document2} style={{ width: "24px", height: "24px" }} />
                                </Box>
                                <Box style={{ width: "242px" }}>

                                    <Typography
                                        style={{
                                            fontFamily: "lato",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            color: "#0F172A",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}

                                    >
                                        {item.name}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontFamily: "lato",
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            color: "#64748B"
                                        }}>
                                        {this.checkSize(item?.size)}
                                    </Typography>
                                </Box>

                                <img src={crossIconPlan}
                                    id="pdfUploadRemove"
                                    data-test-id='pdfUploadRemove'
                                    onClick={() => this.handleRemovePdfUpload(index)}
                                    style={{ cursor: "pointer", width: "24px", height: "24px" }} />

                            </ImageStyleBox2>
                        );
                    }):
                    this.state.pdfData.map((item: any, index: number) => {
     return (
                            <ImageStyleBox2 key={`${item.name}${index}${item.size}`}
                            >
                                <Box style={{ background: "#CDF0EE80",
                                  borderRadius: "8px", width: "56px", height: "56px", 
                                 marginRight: "16px", alignItems: "center",
                                  justifyContent: "center", display: "flex" }}>
                                    <img 
                                    style={{ width: "24px", height: "24px" }} 
                                    src={document2} 
                                    />
                                </Box>
                                <Box style={{ width: "242px" }}>

                                    <Typography
                                        style={{
                                            fontSize: "16px",
                                            color: "#0F172A",
                                            fontWeight: 400,
                                            fontFamily: "lato",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        }}

                                    >
                                        {item.name}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: "#64748B",
                                            fontWeight: 400,
                                            fontFamily: "lato",
                                            fontSize: "16px",
                                        }}>
                                        {this.checkSize(item?.size)}
                                    </Typography>
                                </Box>

                                <img src={crossIconPlan}
                                    style={{ cursor: "pointer", width: "24px", height: "24px" }} 
                                    id="pdfUploadRemove"
                                    onClick={() => this.handleRemovePdf(index)}/>

                            </ImageStyleBox2>
                        );
                    })
                }
            </PdfDataComponent>}</>
    }
    importantField = () => {
        return <span style={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "22px",
            color: "#FF5E5B"
        }}>
            {" "}    *
        </span>
    }
    handleAgeError=()=>{
        return  this.state.ageError && (
            <Typography
                style={Allstyle.errorTextStyle}
            >
                Please select a date
            </Typography>)
    }
    hanldeSvg=()=>{
        return  <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.5001 5.59246C7.1751 5.91746 7.1751 6.44246 7.5001 6.76746L10.7334 10.0008L7.5001 13.2341C7.1751 13.5591 7.1751 14.0841 7.5001 14.4091C7.8251 14.7341 8.3501 14.7341 8.6751 14.4091L12.5001 10.5841C12.8251 10.2591 12.8251 9.73413 12.5001 9.40913L8.6751 5.58412C8.35843 5.26746 7.8251 5.26746 7.5001 5.59246Z"
          fill="#64748B"
        />
      </svg>

    }
    handleHeading=()=>{
        return this.state.pathname=="editpost"?
        <>
        <>
        <Typography
        onClick={()=>{
          this.props.navigation.navigate("ProductDescription",{id:this.state.postID,postDetails:this.state.postDetails})
        }}
        style={{
            ...Allstyle.hedingStyle,
            paddingInline: "6px"
            , cursor: "pointer"

        }}
    >
       Post Details
    </Typography>
    {this.hanldeSvg()}
        </>

<Typography
        style={{
            ...Allstyle.hedingStyle,
            color: "#0F172A",
            paddingInline: "6px",
        }}
    >
       Edit Post
    </Typography>
        </>
        :     <Typography
                  style={{
                      ...Allstyle.hedingStyle,
                      color: "#0F172A",
                      paddingInline: "6px",
                  }}
              >
                  Create New Post
              </Typography>
    }
    handleNewSelect = (config: HandleNewSelectConfig) => {
        const {
            data,
            heading,
            isImp,
            errState,
            errName,
            stateValue,
            state,
            showInfo,
            toolTip
        } = config;
    
              const headingIs=heading=='Type of Animal'
              const isSkinDiseases = heading == "Skin Diseases";
              const isYestSelected= this.state.skin_Diseases=="Yes"
              const isMicroChipped=heading=="Microchipped"
              const Spayed_Neutered=heading=="Spayed/Neutered"

         return  <MainBoxItem2 >
             <MainBoxItemForSelect >
                 <SecondBoxItem3>
                     <HeadingTypography style={{ ...Allstyle.nameSTyle }}>
                         {heading}
                         {
                             isImp ?
                                 this.importantField()
                                 : <span style={Allstyle.optionalSTyle}>

                                     {`  (Optional)`}
                                 </span>
                         }
                     </HeadingTypography>
                     {showInfo && toolTip && <LightTooltip
                         data-test-id="toolTip"
                         open={toolTip?.state}
                         onClose={() => this.handleCloseToolTip(toolTip.isOpenStateName)}
                         title={toolTip?.text || ''} placement="right" >
                         <IconButtonStyleShow
                             data-test-id="tooltip3"

                             onMouseLeave={() => this.hanldeToolTipOnMouseLeave(toolTip?.isOpenStateName)}
                             onMouseEnter={() => this.handleOnMouseEnterToolTip(toolTip?.isOpenStateName)}
                             onClick={() => this.handleClickToolTip(toolTip?.isOpenStateName, toolTip?.state)}
                             style={{ marginLeft: '8px', padding: "2px" }}>
                             <img src={iconInfo} />
                         </IconButtonStyleShow>
                     </LightTooltip>}

                 </SecondBoxItem3>
                 <SelectField style={{ position: "relative" }}>
                     <Select
                         disabled={this.state.pathname == "editpost" && headingIs}
                         data-test-id={state}
                         error={errState}
                         variant="outlined"
                         value={stateValue}
                         id="select"
                         onChange={(event: React.ChangeEvent<{ name?: string; value: unknown; }>) =>
                             this.handleChangeFunc(event, state, errName, heading)
                         }
                         MenuProps={{
                             getContentAnchorEl: null,
                             MenuListProps: {
                                 style: {
                                     maxHeight: 398,
                                     padding: "10px",
                                 },
                             },
                             anchorOrigin: {
                                 vertical: "bottom",
                                 horizontal: "left",
                             },
                         }}
                         style={{
                             fontSize: "16px",
                             fontWeight: 400,
                             fontFamily: "Lato",
                             color:
                                 this.handleStyle(stateValue, state)
                         }}
                         fullWidth
                         IconComponent={KeyboardArrowDown}
                     >
                         <MenuItem
                             value="Select"
                             style={{
                                 display: "none",
                                 fontSize: "16px",
                                 fontWeight: 400,
                                 fontFamily: "Lato",
                                 color: "#94A3B8",
                             }}

                         >
                             Select
                         </MenuItem>
                         {data.length > 0 && data.map((item: Item) => {
                             return <MenuItem
                                 value={headingIs ? item.id : item.name}
                                 id={item.name}
                                 style={{
                                     fontSize: "16px",
                                     fontWeight: 400,
                                     fontFamily: "Lato",

                                 }}
                             >
                                 {item.name}
                             </MenuItem>
                         })}
                     </Select>
                     {this.handleError(errState)}
                     {showInfo && <LightTooltip

                         title={toolTip?.text || ''} placement="right" >
                         <IconButtonStyleHook

                             style={{ position: "absolute", right: '-44px' }}>
                             <img src={iconInfo} />
                         </IconButtonStyleHook>
                     </LightTooltip>}
                 </SelectField>
             </MainBoxItemForSelect>
             <ExtraFieldBox>
                 {isMicroChipped && this.state.Microchiped == "Yes" && <InfoTypography >
                    {configJSON.infoLine}
                    </InfoTypography>}
          
                 {isSkinDiseases && isYestSelected && (
                     <Box style={{ marginTop: '16px' }}>
                         <Typography style={{ ...Allstyle.nameSTyle, marginBottom: "4px", }}>
                             {configJSON.pleaseExplain} {this.importantField()}
                         </Typography>
                         <InputField
                             fullWidth
                             error={this.state.skin_Diseases_Value_Err}
                             helperText={this.handleErroMessage(this.state.skin_Diseases_Value_Err)}
                             placeholder="Please explain"
                             variant="outlined"
                             value={this.state.skin_Diseases_Value}
                             id="optionalGood"
                             data-test-id='pleaseExplainField1'
                             onChange={(event) => {
                                 this.handleSkinDiseases(event)
                             }}
                         /></Box>
                 )}
                 {Spayed_Neutered && <InfoTypography style={{
                     }}>{configJSON.infoLine2}</InfoTypography>}
                 </ExtraFieldBox>

            
                 {isMicroChipped && this.state.Microchiped == "Yes" && <ExtraFieldBox >
                     <Box style={{ marginTop: '16px' }}>
                         <Typography style={{ ...Allstyle.nameSTyle, marginBottom: "4px", }}>
                             Microchip number {this.importantField()}
                         </Typography>
                         <InputField
                             fullWidth
                             error={this.state.microchipped_number_Err}
                             helperText={this.handleMicroChippedMessErr('Please enter a number', this.state.microchipped_number_Err)}
                             placeholder="Enter microchip number"
                             variant="outlined"
                             value={this.state.microchipped_number}
                             id="optionalGood"
                             data-test-id='microchipNumber'
                             onChange={(event) => {
                                 this.handleMicrochipNumber(event)
                             }}
                         />
                         <Typography style={{ ...Allstyle.nameSTyle, marginBottom: "4px", marginTop: '16px' }}>
                             Confirm number {this.importantField()}
                         </Typography>
                         <InputField
                             fullWidth
                             error={this.state.microchipped_Confirm_number_Err}
                             helperText={this.handleMicroChippedMessErr('Numbers do not match', this.state.microchipped_Confirm_number_Err)}
                             placeholder="Enter confirm number"
                             variant="outlined"
                             value={this.state.microchipped_Confirm_number}
                             id="optionalGood"
                             data-test-id='microchipConfirmNumber'
                             onChange={(event) => {
                                 this.handleMicrochipNumberConfirm(event)
                             }}
                         />
                     </Box>
                </ExtraFieldBox> }

         </MainBoxItem2 >
                   
    }
    returnTexteriaField=({
        heading,
        isImp,
        error,
        errName,
        isOpenToolTip,
        isOpenToolTipStateName,
        toolTipValueText,
        inputValue,
        stateName,
        placeHolderText
    }: TexteriaFieldParams)=>{
     return  <MainBoxItem >
        <SecondBoxItem21 style={{...Allstyle.nameSTyle,}}>
        <HeadingTypography style={{ ...Allstyle.nameSTyle}}>
                    {heading}
                    {
                        isImp ?
                            this.importantField()
                            : <span style={Allstyle.optionalSTyle}>

                                {`  (Optional)`}
                            </span>
                    }
                </HeadingTypography>

            <LightTooltip 
          data-test-id="tooltip4"
            open={isOpenToolTip}
            onClose={()=>this.handleCloseToolTip(isOpenToolTipStateName)}
            title={toolTipValueText} placement="right" >
                <IconButtonStyleShow 
             data-test-id="tooltip3"

                 onMouseLeave={()=>this.hanldeToolTipOnMouseLeave(isOpenToolTipStateName)}
                 onMouseEnter={()=>this.handleOnMouseEnterToolTip(isOpenToolTipStateName)}
                  onClick={()=>this.handleClickToolTip(isOpenToolTipStateName,isOpenToolTip)}
                style={{ marginLeft: '8px', padding: "2px" }}>
                    <img src={iconInfo} />
                </IconButtonStyleShow>
            </LightTooltip>
        </SecondBoxItem21>
        <PetStoryBox >
            <Box style={{position:'relative'}}>
            <InputField2
                minRows={8}
                style={{
                    overflow:"auto",
                    border: this.handleBorder(error),
                }}
                id="conversation"
                data-test-id={stateName}
                value={inputValue}
                placeholder={placeHolderText}
                onChange={(event) => {
                   this.handlePetStory(event,errName,stateName,isImp)
                }
                }
            />
               <LightTooltip 
            
            title={toolTipValueText} placement="right" >
                <IconButtonStyleHook 
               
                style={{ marginRight: '8px', position: "absolute" }}>
                    <img src={iconInfo} />
                </IconButtonStyleHook>
            </LightTooltip>
            </Box>
            {error && <Typography style={{
                color: "#DC2626",
                fontSize: "12px",
                fontFamily: "Lato",
                fontWeight: 400,
                lineHeight: "18px"
            }}>
                Please enter a value
            </Typography>}
        </PetStoryBox>

    </MainBoxItem>
    }
    handleReturnMultiSelect = (config:HandleMultiSelectConfig) => {
          const   { 
                state,
                testId,
                data,
                isImp,
                heading,
                changeFun,
                stateLength,
                errState}=config
        const isGoodInHouseWith = heading === "Good in a house with";
        const isOtherSelected = this.state.Good_in_house?.some((item: Item) => item.name === 'Other');
        return <MainBoxItem2 >
           
            <MainBoxItemForSelect > <SecondBoxItem3>
                <HeadingTypography style={{ ...Allstyle.nameSTyle }}>
                    {heading}
                    {
                        isImp ?
                            this.importantField()
                            : <span style={Allstyle.optionalSTyle}>

                                {`  (Optional)`}
                            </span>
                    }
                </HeadingTypography>
            </SecondBoxItem3>
            <SelectField>
            <CustomAutocomplete
                multiple
                limitTags={2}
                id="multiple-limit-tags"
                options={data}
                value={state?.length>0?state:[]}
                getOptionLabel={(option: unknown) => (option as Item).name}
                onChange={changeFun}
                data-test-id={testId}
                 popupIcon={<KeyboardArrowDown />}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={errState}
                        variant="outlined"
                        placeholder={stateLength==0?"Select":''}
               

                    />
                )}
            />
            {this.handleError(errState)}
            </SelectField>
            </MainBoxItemForSelect>
                    <ExtraFieldBox>
                         {isGoodInHouseWith && isOtherSelected && (
                <Box style={{marginTop:'16px'}}>
                     <Typography style={{ ...Allstyle.nameSTyle, marginBottom: "4px", }}>
                {configJSON.pleaseExplain} {this.importantField()}
              </Typography>
                        <InputField
                        fullWidth
                            error={this.state.othererror}
                        helperText={this.handleErroMessage(this.state.othererror)}
                            placeholder="Please explain"
                            data-test-id='pleaseExplainField3'
                            variant="outlined"
                            value={this.state.GoodinHousewithValue}
                            id="optionalGood"
                            onChange={(event) => {
                               this.handleGoodInHouseWithValue(event)
                            }}
                        /></Box>
                    )}
                    </ExtraFieldBox>
        </MainBoxItem2 >
    }
    returnMarketingStrategy=()=>{
  return this.state.isShowMarketingStrategy&&this.handleNewSelect({data: configJSON.marketing_Strategy,
    heading: 'Marketing Strategy',
    isImp: false,
    errState: this.state.marketing_strategy_Err,
    errName: 'marketing_strategy_Err',
    stateValue: this.state.marketing_strategy,
    state: 'marketing_strategy',
    showInfo: true,
    toolTip: {
        text: configJSON.toolTipText_marketing_Strategy,
        state: this.state.toolTipState,
        isOpenStateName: 'toolTipState'
    }})
    }
    returnGender = () => {
        return this.handleNewSelect({
            data: configJSON.gender2,
            heading: 'Gender',
            isImp: true,
            errState: this.state.gendererror,
            errName: 'gendererror',
            stateValue: this.state.gender,
            state: 'gender',
            showInfo: false,
            toolTip: {
                text: '',
                state: false,
                isOpenStateName: ''
            }
        })
    }
    returnSizesFields = () => {
        return <> {this.hanldeShowField() && this.handleNewSelect({
            data: configJSON.size,
            heading: 'Size',
            isImp: false,
            errState: this.state.size_Err,
            errName: 'size_Err',
            stateValue: this.state.size,
            state: 'size',
            showInfo: false,
            toolTip: {
                text: '',
                state: false,
                isOpenStateName: ''
            }
        })}
             {this.hanldeShowFieldComm("Birds") && this.handleNewSelect({
    data: configJSON.cage_Size_Required,
    heading: 'Cage Size Required',
    isImp: true,
    errState: this.state.cage_Size_Required_Err,
    errName: 'cage_Size_Required_Err',
    stateValue: this.state.cage_Size_Required,
    state: 'cage_Size_Required',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
            {this.hanldeShowComm1() && this.handleNewSelect({
    data: configJSON.habitat_Size_Required,
    heading: 'Habitat Size Required',
    isImp: true,
    errState: this.state.habitat_Size_Required_Err,
    errName: 'habitat_Size_Required_Err',
    stateValue: this.state.habitat_Size_Required,
    state: 'habitat_Size_Required',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
        </>
    }
    returnActivityLevel_NoiseLevel = () => {
        return <>
             {this.hanldeShowFieldComm("Birds") && this.handleNewSelect({
    data: configJSON.noise_Level,
    heading: 'Noise Level',
    isImp: true,
    errState: this.state.noise_Level_Err,
    errName: 'noise_Level_Err',
    stateValue: this.state.noise_Level,
    state: 'noise_Level',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
             {this.hanldeShowField() && this.handleNewSelect({
    data: configJSON.level,
    heading: 'Activity Level',
    isImp: false,
    errState: this.state.activitiylevel_Err,
    errName: 'activitiylevel_Err',
    stateValue: this.state.activitiylevel,
    state: 'activitiylevel',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
        </>
    }
    returnFields_For_HealthStatus = () => {
        return <>
            {this.hanldeShowField3() && this.handleNewSelect({
    data: configJSON.VaccineStatus,
    heading: 'Vaccine Status',
    isImp: true,
    errState: this.state.VaccineStatuserror,
    errName: 'VaccineStatuserror',
    stateValue: this.state.vaccineStatus,
    state: 'vaccineStatus',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}

             {this.hanldeShowFieldComm("Little Critters") && this.handleNewSelect({
    data: configJSON.little_Critter_VaccineStatus,
    heading: 'Vaccine Status',
    isImp: false,
    errState: this.state.little_Critter_VaccineStatus_Err,
    errName: 'little_Critter_VaccineStatus_Err',
    stateValue: this.state.little_Critter_VaccineStatus,
    state: 'little_Critter_VaccineStatus',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
             {this.hanldeShowFieldComm("Rabbits") && this.handleNewSelect({
    data: configJSON.little_Critter_VaccineStatus,
    heading: 'Vaccine status',
    isImp: true,
    errState: this.state.rabbit_VaccineStatus_Err,
    errName: 'rabbit_VaccineStatus_Err',
    stateValue: this.state.rabbit_VaccineStatus,
    state: 'rabbit_VaccineStatus',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
           {this.hanldeShowFieldComm("Cats") && this.handleNewSelect({
    data: configJSON.FIVStatus,
    heading: 'FIV/FELV status',
    isImp: true,
    errState: this.state.FELVerror,
    errName: 'FELVerror',
    stateValue: this.state.FELVStatus,
    state: 'FELVStatus',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
             {this.hanldeShowFieldComm("Dogs") && this.handleNewSelect({
    data: configJSON.ehrlichia,
    heading: 'Ehrlichia',
    isImp: true,
    errState: this.state.ehrlichia_Err,
    errName: 'ehrlichia_Err',
    stateValue: this.state.ehrlichia,
    state: 'ehrlichia',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
            {this.handleNewSelect({
    data: configJSON.Microchiped,
    heading: 'Microchipped',
    isImp: true,
    errState: this.state.Microchipederror,
    errName: 'Microchipederror',
    stateValue: this.state.Microchiped,
    state: 'Microchiped',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
            {this.hanldeShowFieldComm("Cats") && this.handleNewSelect({
    data: configJSON.dewormed,
    heading: 'Dewormed',
    isImp: false,
    errState: this.state.dewormed_Err,
    errName: 'dewormed_Err',
    stateValue: this.state.dewormed,
    state: 'dewormed',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
               {this.hanldeShowFieldComm("Birds") && this.handleNewSelect({
    data: configJSON.Microchiped,
    heading: 'Wings Clipped',
    isImp: true,
    errState: this.state.wings_Clipped_Err,
    errName: 'wings_Clipped_Err',
    stateValue: this.state.wings_Clipped,
    state: 'wings_Clipped',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
              {this.hanldeShowFieldComm("Birds") && this.handleNewSelect({
    data: configJSON.pbfd_test,
    heading: 'PBFD test',
    isImp: false,
    errState: this.state.pbfd_test_Err,
    errName: 'pbfd_test_Err',
    stateValue: this.state.pbfd_test,
    state: 'pbfd_test',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
             {this.hanldeShowField() && this.handleNewSelect({
    data: configJSON.Spayed_Neutered,
    heading: 'Spayed/Neutered',
    isImp: true,
    errState: this.state.Spayed_Neutered_Err,
    errName: 'Spayed_Neutered_Err',
    stateValue: this.state.Spayed_Neutered,
    state: 'Spayed_Neutered',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
               {this.hanldeShowFieldComm("Dogs") && this.handleNewSelect({
    data: configJSON.parasite_Medication,
    heading: 'Parasite Medication',
    isImp: true,
    errState: this.state.parasite_Medication_Err,
    errName: 'parasite_Medication_Err',
    stateValue: this.state.parasite_Medication,
    state: 'parasite_Medication',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
           {this.hanldeShowFieldComm("Rabbits") && this.handleNewSelect({
        data: configJSON.skin_Diseases,
        heading: 'Skin Diseases',
        isImp: true,
        errState: this.state.skin_Diseases_Err,
        errName: 'skin_Diseases_Err',
        stateValue: this.state.skin_Diseases,
        state: 'skin_Diseases',
        showInfo: false,
        toolTip: {
            text: '',
            state: false,
            isOpenStateName: ''
        }
    })}

        </>
    }
    returnLifeStyle_and_training = () => {
        return <>
              {this.hanldeShowFieldComm("Cats") && this.handleNewSelect({
    data: configJSON.lifeStyle,
    heading: 'Lifestyle',
    isImp: true,
    errState: this.state.Lifestyle_Err,
    errName: 'Lifestyle_Err',
    stateValue: this.state.Lifestyle,
    state: 'Lifestyle',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
             {this.hanldeShowFieldComm("Rabbits") && this.handleNewSelect({
    data: configJSON.lifeStyle_Rabbit,
    heading: 'Lifestyle',
    isImp: true,
    errState: this.state.Lifestyle_Rabbit_Err,
    errName: 'Lifestyle_Rabbit_Err',
    stateValue: this.state.Lifestyle_Rabbit,
    state: 'Lifestyle_Rabbit',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
              {this.hanldeShowFieldComm("Birds") && this.handleNewSelect({
    data: configJSON.lifeStyle_bird,
    heading: 'Lifestyle',
    isImp: true,
    errState: this.state.lifeStyle_bird_Err,
    errName: 'lifeStyle_bird_Err',
    stateValue: this.state.lifeStyle_bird,
    state: 'lifeStyle_bird',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
            {this.state.typeOfAnimalName=='Little Critters' && this.handleNewSelect({
    data: configJSON.lifeStyle_reptile_and_LittleCritter,
    heading: 'Lifestyle',
    isImp: true,
    errState: this.state.lifeStyle_LittleCritter_Err,
    errName: 'lifeStyle_LittleCritter_Err',
    stateValue: this.state.lifeStyle_LittleCritter,
    state: 'lifeStyle_LittleCritter',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
    {this.state.typeOfAnimalName=='Reptiles' && this.handleNewSelect({
    data: configJSON.lifeStyle_reptile_and_LittleCritter,
    heading: 'Lifestyle',
    isImp: false,
    errState: this.state.lifeStyle_reptile_Err,
    errName: 'lifeStyle_reptile_Err',
    stateValue: this.state.lifeStyle_reptile,
    state: 'lifeStyle_reptile',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
              {this.hanldeShowFieldComm("Dogs") && this.handleNewSelect({
    data: configJSON.training,
    heading: 'Training',
    isImp: true,
    errState: this.state.training_Err,
    errName: 'training_Err',
    stateValue: this.state.training,
    state: 'training',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}
        </>
    }
    // Customizable Area End

    render() {
        const dateParts = this.state.age !==""?this.state.age.split('/'): moment.parseZone(new Date()).format('MM/DD/YYYY').split('/') ;
        const year = parseInt(dateParts[2]);
        const month = parseInt(dateParts[0]) - 1;
        const day = parseInt(dateParts[1]);
        const date = new Date(year, month, day);
        return (
            //Merge Engine DefaultContainer
            // Customizable Area Start
            <>
            
            <Loader loading={this.state.loader } data-test-id='loader'/>

          { <>
            <MainGrid style={{ display: "flex",alignItems:"center",flexWrap:"wrap" }}>
                <Typography
                data-test-id="home"
                 style={{ ...Allstyle.hedingStyle, paddingInline: "6px", cursor: "pointer" }} onClick={() => {
                    this.props.navigation.navigate("LandingPageWeb")
                }}>
                    Home
                </Typography>
               {this.hanldeSvg()}
{this.state.postDetails &&<>
                <Typography
                data-test-id="page"

                    onClick={() => {
                        this.props.navigation.navigate("CataloguePage")
                    }}
                    style={{
                        ...Allstyle.hedingStyle,
                        paddingInline: "6px"
                        , cursor: "pointer"
                    }}
                >
                    My Posts
                </Typography>
                {this.hanldeSvg()}
</>}
          { this.handleHeading()}
            </MainGrid>
            <HomeGrid >
                <Typography style={Allstyle.styleText}>
                 {this.handleHeding()}
                </Typography>
                <MainBoxItem >
                    <SecondBoxItem >
                        <Typography style={Allstyle.nameSTyle}>
                            Pet name{this.importantField()}

                        </Typography>
                    </SecondBoxItem>
                    <InputField variant="outlined" placeholder="Enter pet name"
                    data-test-id='petName'
                        error={this.state.petNameError}
                        helperText={this.handleErroMessage(this.state.petNameError)}
                        value={this.state.petName}
                        id="petname"
                        onChange={(e: any) => {
                           this.handlePetName(e)
                        }} />
                </MainBoxItem>
                <MainBoxItem >
                    <SecondBoxItem >

                        <Typography style={Allstyle.nameSTyle}>
                            Hook line  {this.importantField()}

                        </Typography>
                        <LightTooltip 
                      data-test-id="tooltip1"
                        open={this.state.toolTip1}
                        onClose={()=>this.handleTooltipCloseChanelsData()}
                        title="A brief tagline - max 5 words" placement="right" >
                            <IconButtonStyleShow 
                         data-test-id="tooltip11"

                             onMouseLeave={()=>this.handleHeadingMouseOver()}
                             onMouseEnter={()=>this.handleHeadingMouseHover()}
                              onClick={() => this.handleHeading4()} 
                            style={{ marginLeft: '8px', padding: "2px" }}>
                                <img src={iconInfo} />
                            </IconButtonStyleShow>
                        </LightTooltip>
                    </SecondBoxItem>
                    <Box style={{ position: "relative" }}>

                        <InputField variant="outlined" placeholder="Enter hook line"
                            style={{ width: "100%" }}
                            id="hook"
                            error={this.state.hookLineError}
                        helperText={this.handleErroMessage(this.state.hookLineError)}
                             data-test-id='hookline'
                            value={this.state.hookLine}
                            onChange={(e: any) => {
                                this.handleHookLine(e)
                            }
                            }
                        />

                        <LightTooltip 
                        
                        title="A brief tagline - max 5 words" placement="right" >
                            <IconButtonStyleHook 
                           
                            style={{ marginRight: '8px', position: "absolute" }}>
                                <img src={iconInfo} />
                            </IconButtonStyleHook>
                        </LightTooltip>
                    </Box>
                </MainBoxItem>
                {this.handleNewSelect({
    data: this.state.animalData,
    heading: 'Type of Animal',
    isImp: true,
    errState: this.state.typeOfAnimalerror,
    errName: 'typeOfAnimalerror',
    stateValue: this.state.typeOfAnimal,
    state: 'typeOfAnimal',
    showInfo: false,
    toolTip: {
        text: '',
        state: false,
        isOpenStateName: ''
    }
})}

                <MainBoxItem >
                    <SecondBoxItem >
                        <Typography style={Allstyle.nameSTyle}>
                            Breed {this.importantField()}

                        </Typography>
                    </SecondBoxItem>
                    <SelectField  >
                        <Select
                            error={this.state.breederror}
                            variant="outlined"
                            value={this.state.breed}
                            id="breed"
                            data-test-id='breed'
                            onChange={(e: any) => {
                                this.setState({ breed: e.target.value, breederror: false })
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                MenuListProps: {
                                    style: {
                                        maxHeight: 398,
                                        padding: "10px",
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Lato",
                                color: 
                                this.handleStyle(this.state.breed,'breed')
                            }}
                            fullWidth
                            IconComponent={KeyboardArrowDown}
                        >
                            <MenuItem
                                value="Select"
                                style={{
                                    display: "none",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                    color: "#94A3B8",
                                }}

                            >
                                Select
                            </MenuItem>
                            {this.state.breedData.map((item) => {
                                return <MenuItem
                                    value={item.id}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        fontFamily: "Lato",

                                    }}
                                >
                                    {item.name}
                                </MenuItem>
                            })}
                        </Select>
                        {this.handleError(this.state.breederror)}
                    </SelectField>
                </MainBoxItem>
               {this.returnMarketingStrategy()} 

                <MainBoxItem >
                    <SecondBoxItem >
                        <Typography style={Allstyle.nameSTyle}>
                            Current Location of Animal  {this.importantField()}

                        </Typography>
                    </SecondBoxItem>
                    <SelectField  >
                        <Select
                            error={this.state.locationerror}
                            variant="outlined"
                            value={this.state.location}
                            id="location"
                            data-test-id='location'
                            onChange={(e: any) => {
                                this.setState({ location: e.target.value, locationerror: false })
                            }}
                            MenuProps={{
                                getContentAnchorEl: null,
                                MenuListProps: {
                                    style: {
                                        maxHeight: 398,
                                        padding: "10px",
                                    },
                                },
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                fontFamily: "Lato",
                                color: this.handleStyle(this.state.location,'location')
                            }}
                            fullWidth
                            IconComponent={KeyboardArrowDown}
                        >
                            <MenuItem
                                value="Select"
                                style={{
                                    display: "none",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    fontFamily: "Lato",
                                    color: "#94A3B8",
                                }}

                            >
                                Select
                            </MenuItem>
                            {this.state.animalLocationData.map((item: any) => {
                                return <MenuItem
                                    value={item.name}
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                        fontFamily: "Lato",

                                    }}

                                >
                                    {item.name}
                                </MenuItem>
                            })}
                        </Select>
                        {this.handleError(this.state.locationerror)}
                    </SelectField>
                </MainBoxItem>
                {this.returnGender()} 
                <MainBoxItem >
                    <SecondBoxItem style={{ width:"175px" }}>
                        <Typography style={Allstyle.nameSTyle}>
                            Age  {this.importantField()}

                        </Typography>
                    </SecondBoxItem>
                    <DateBox style={{position:"relative"}}>
                    <Box style={{position:"relative"}}>

<DateStyle
placeholder="DD/MM/YYYY"
disabledStyle={this.state.ageError}
value={this.convertFormat()}
fullWidth
disableUnderline
name="companyName" 
onClick={()=>this.openDatePicker()}
data-test-id="user_date_textfield"
/>

<img
  src={calender} 
id="user-date"
data-test-id='datePicker'
  style={{marginRight:"10px",position:'absolute',top:9,left:132, width: "24px", height: "24px",cursor:'pointer' }}
  onClick={()=>this.openDatePicker()}
/>
  {this.state.datePickerIsOpen && (
 <div style={{ position: 'absolute', top: 60,zIndex: 1000 }}>
<StyleDateBox
data-test-id='changeDate'
onChange={ this.handleChanges}
maxDate={new Date()} 
locale={enUS}
 date={ date  } />
 </div>
 )}
    
 </Box>
 {this.handleAgeError()}
                    </DateBox>
                </MainBoxItem>
                      {this.returnSizesFields()}
                {this.handleImage()}
                {this.handleVideo()}
                {this.returnActivityLevel_NoiseLevel()}
                        <Typography style={Allstyle.healthStyle}>
                            Health Status
                        </Typography>
                        {this.returnFields_For_HealthStatus()}
                      
                         {this.returnTexteriaField({
                            heading: 'Animal of determination',
                            isImp: false,
                            error: this.state.Animal_determination_Err,
                            errName: 'Animal_determination_Err',
                            isOpenToolTip: this.state.is_Open_Animal_determination,
                            isOpenToolTipStateName: 'is_Open_Animal_determination',
                            toolTipValueText: configJSON.toolTipText_Animal_determination,
                            inputValue: this.state.Animal_determination,
                            stateName: 'Animal_determination',
                            placeHolderText: "Write animal of determination..."
                        })}
                        {this.returnTexteriaField({
                            heading: 'Medical Conditions',
                            isImp: false,
                            error: this.state.medical_Conditions_Err,
                            errName: 'medical_Conditions_Err',
                            isOpenToolTip: this.state.is_Open_medical_Conditions,
                            isOpenToolTipStateName: 'is_Open_medical_Conditions',
                            toolTipValueText: configJSON.toolTipText_medical_Conditions,
                            inputValue: this.state.medical_Conditions,
                            stateName: 'medical_Conditions',
                            placeHolderText: "Write medical conditions..."
                        })}
                        <Typography style={Allstyle.healthStyle}>
                        Pet Behaviour
                        </Typography>
                            {this.returnLifeStyle_and_training()}
                         {this.handleShowField2() && this.handleReturnMultiSelect(

                            {
                                state: this.state.Good_in_house,
                                testId: 'goodsInHouse',
                                data: configJSON.goodHome,
                                isImp: false,
                                heading: 'Good in a house with',
                                changeFun: this.handleMultSelectChangeGoodHouse,
                                stateLength: this.state.Good_in_house?.length,
                                errState: false
                            }
                        )}
              
                        {this.handleReturnMultiSelect(

                            {
                                state: this.state.Temperament,
                                testId: 'temprament',
                                data: configJSON.temperament,
                                isImp: this.state.typeOfAnimalName!=='Little Critters' && this.state.typeOfAnimalName!=='Reptiles'  ,
                                heading: 'Temperament',
                                changeFun: this.handleMultSelectChangeTemperament,
                                stateLength: this.state.Temperament?.length,
                                errState: this.state.TemperamentError
                            }
                        )} 

                         {this.hanldeShowField() && this.handleNewSelect({
                            data: configJSON.coatLength,
                            heading: 'Coat Length',
                            isImp: false,
                            errState: this.state.coatLength_Err,
                            errName: 'coatLength_Err',
                            stateValue: this.state.coatLength,
                            state: 'coatLength',
                            showInfo: false,
                            toolTip: {
                                text: '',
                                state: false,
                                isOpenStateName: ''
                            }
                        })}
               
                          {this.returnTexteriaField({
                            heading: 'Rescue story and Personality of Animal',
                            isImp: true,
                            error: this.state.PetStoryerror,
                            errName: 'PetStoryerror',
                            isOpenToolTip: this.state.isOpenRescueStory,
                            isOpenToolTipStateName: 'isOpenRescueStory',
                            toolTipValueText: configJSON.toolTipText_rescue_story,
                            inputValue: this.state.petStory,
                            stateName: 'petStory',
                            placeHolderText: "Write rescue story..."
                        })}
                
          {  this.state.pathname!=="editpost"&&    <MainBoxItem style={{marginTop:'8px',marginBottom:'8px'}}>
                    <SecondBoxItem21 style={Allstyle.nameSTyle}>
                        Pet is already fostered?

                    </SecondBoxItem21>
                    <DateBox style={{ flexDirection: "row", display:"flex",alignItems:"center"}}>
                        <Typography style={{...Allstyle.optionStyle,marginBottom:"0px"}} id="fostered" onClick={this.handlefostered}>
                            <img src={this.state.fostered ? checkBoxTrue : checkBoxFalse} style={{ marginRight: "12px" }} />
                            Yes
                        </Typography>
                        <LightTooltip2
                        data-test-id="tooltip2"
                        open={this.state.toolTip2}
                        onClose={()=>this.handleTooltipCloseChanelsData2()}
                         title='Selecting this option will mark the post as "Fostered" to others' placement="right" >
                            <IconButtonStyle 
                        data-test-id="tooltip22"

                            onMouseLeave={()=>this.handleHeadingMouseOver2()}
                            onMouseEnter={()=>this.handleHeadingMouseHover2()}
                             onClick={() => this.handleHeading2()} 
                            style={{ marginLeft: "12px", padding: "0px 12px" }}>
                                <img src={iconInfo} />
                            </IconButtonStyle>
                        </LightTooltip2>
                    </DateBox>
                </MainBoxItem>}


               {this.handlePdf()}
       

                
            </HomeGrid>
            <Border  />
         
            <ButtonComponentMain  >
            <TypoComponen2 style={{ ...Allstyle.nameSTyle, }} edit ={this.state.pathname=="editpost"}>
                    </TypoComponen2>
                    <ButtomCompo style={{ flexDirection: "row",gap:"16px" }}>
                    <BottonComponentCom variant="contained"
                    data-test-id="previewTestId2"
                   onClick={() => {
                    this.handlePreviewNavigate()
                }}
                    style={{ ...Allstyle.btnStyle ,background:"#FF5E5B"}}
                >
                    <img src={documentIcon} style={{ marginRight: "8px" }} />
                    Preview Post
                </BottonComponentCom>
                <BottonComponentCom variant="contained"
                   data-test-id="submit"
                    style={{ ...Allstyle.btnStyle }}
                    onClick={() => {
                        this.handleValidtion()
                    }}
                >
                    <img src={RightIcon} style={{ marginRight: "8px" }} />
                   {this.handleButtonName()}
                </BottonComponentCom>
                <ButtonComponent variant="outlined"
                    data-test-id="cancelid"
                    style={{ width: "80px" }} onClick={this.cancelButton}>
                    Cancel
                </ButtonComponent>
                </ButtomCompo>
                </ButtonComponentMain>
                <DiologCompo onClose={this.closeVideo} style={{overflowY:"auto"}}aria-labelledby="customized-dialog-title" open={this.state.openVideo}>
    {this.state.urlVideo && (
<Box style={{width:"100%",height:"100%",position:"relative"}}>
<Typography style={{...Allstyle.closeIconStyle,position:"absolute",top:24,zIndex:111}} onClick={this.closeVideo}>
    <img src={closeIconSvg}/>
    Close
</Typography>
      <video
      controls 
      controlsList="nodownload"

        style={{ width: "100%", height: "100%", objectFit: "contain",background:"#494545" }}
      >
        <source
        src={this.convertUrl()
        }
        />
      </video>
</Box>
    )}
</DiologCompo>
            </>}
            
        </>
            // Customizable Area End
            //Merge Engine End DefaultContainer
        );
    }
}

// Customizable Area Start
export const DiologCompo=styled(Dialog)({
    "& .MuiDialog-paperWidthSm":{
maxWidth:"547px",
minWidth:"547px",
minHeight:"697px",
height:"697px",
borderRadius: "8px",
    },
    '@media (max-width:660px )': {
        "& .MuiDialog-paperWidthSm":{
            maxWidth:"300px",
minHeight:"400px",
borderRadius: "8px",
         height:"400px",
            minWidth:"300px",
                },
    },
})
const Border=styled("hr")({
    width: "100%", marginBlock: 0, maxWidth: "1170px", backgroundColor: "rgba(226, 232, 240)" , border: 'none',
    height:"1px",
    '@media (max-width:660px )': {
       marginBlock:"20px"
    },
})
const TypoComponen2=styled(Typography)(({ edit }: any) => ({
    width:edit ?"299px": "175px",
    '@media (max-width:1000px )': {
        width:"0px",
        minWidth: "unset",
        maxWidth: "unset",
      display:"none"
    },
}))
const TypoComponen=styled(Typography)({
    width: "175px",
    '@media (max-width:1000px )': {
        width:"0px",
        minWidth: "unset",
        maxWidth: "unset",
      display:"none"
    },
})
const ButtonComponentMain = styled(Box)({
    marginTop: "40px",
    position:"relative",
   
    marginInline:"auto",
    maxWidth:"unset",
    marginBottom: "134px"
    ,gap:"77px",
    minWidth: "573px",
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width:1000px )': {
        minWidth: "unset",
        position:"unset",
        maxWidth: "unset",
        gap: "0px",
        flexWrap: "wrap",
        flexDirection: "column"
    },
    '@media (max-width:600px )': {
        marginInline:"20px",
        flexWrap: "wrap",
        maxWidth: "unset",
        gap: "0px",
        flexDirection: "column",
        minWidth: "unset",
        position:"unset",
            }
})
const MainBoxItem = styled(Box)({
    minWidth: "573px",
    maxWidth: "573px",
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",
        flexDirection: "column"
    },
})
const MainBoxItemForSelect = styled(Box)({
    minWidth: "573px",
    maxWidth: "573px",
    display: "flex",
    justifyContent: "space-between",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems:'unset'
    },
})
const ExtraFieldBox=styled(Box)({
    minWidth: "573px",
    maxWidth: "573px",
    display: "flex",
    justifyContent:'flex-end',
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",
        flexDirection: "column"
    },
})
const SecondBoxItem = styled(Box)({
    display: "flex", alignItems: "center",height:"43px",
    '@media (max-width:660px )': {
       height:"unset",
       marginBottom:"4px"
    },
})
const SecondBoxItem21= styled(Typography)({
    '@media (max-width:660px )': {
       marginBottom:"4px"
    },
    '@media (max-width:600px )': {
        display: "flex", alignItems: "center",
     },
})
const SecondBoxItem2= styled(Box)({
  
    '@media (max-width:660px )': {
       marginBottom:"4px"
    },
    '@media (max-width:600px )': {
        display:'flex',
        alignItems:'center'
     },
})
const SecondBoxItem3= styled(Box)({
  
    '@media (max-width:660px )': {marginBottom:"4px"},
    '@media (max-width:600px )': {
        display:'flex',
        alignItems:'center'
     },
})
const DateBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "374px",
    minWidth: "374px",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",
        flexDirection: "column"
    },
})
const ButtomCompo = styled(Box)({
    display: "flex",
    flexDirection: "column",
   
    '@media (max-width:660px )': {
       
        flexWrap: "wrap",
        flexDirection: "column"
    },
})
const PdfDataComponent = styled(Box)({
    display: "none",
    '@media (max-width:660px )': {
        display: "block",
        paddingBottom: "40px",
    },
})
const PetStoryBox = styled(Box)({
    maxWidth: "375px",
    minWidth: "375px",
    maxHeight: "170px",
    height: "140px",
    minHeight: "140px",
    borderRadius: "8px",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
    },
})
const IconButtonStyle = styled(IconButton)({
    "&.MuiIconButton-root:hover": {
        background: "unset"
    },

})
const IconButtonStyleHook = styled(IconButton)({
    "&.MuiIconButton-root:hover": {
        background: "unset"
    },
    '@media (max-width:1000px )': {
        display: "none"
    },
})

const IconButtonStyleShow = styled(IconButton)({

    "&.MuiIconButton-root:hover": {
        background: "unset"
    },
    display: "none",
    '@media (max-width:1000px )': {
        display: "block"
    },
})
const MainGrd2 = styled(Box)({
    minWidth: "573px",

	
    display: "flex",

	
    justifyContent:"space-between",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        borderBottom: "unset",
        gap: "23px",
        flexWrap: "wrap",
        paddingBottom: "unset"
    },
})
const ImageStyleBox = styled(Box)({
    marginTop: "16px",
    alignItems:"center",
    marginBottom: "8px", justifyContent: "space-between", padding: "8px 4px 8px 8px",
    borderRadius: "8px", display: "flex",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    '@media (max-width:660px )': {
        display: "none"
    },
})
const StyleDateBox=styled(Calendar)({
   "& .rdrDayNumber span":{
    fontWeight:700
   },
   "& .rdrMonth":{
    width:"unset"
   }
})
const ImageStyleBox2 = styled(Box)({
    marginTop: "16px",
    alignItems:"center",
    display: "none",
    marginBottom: "8px", justifyContent: "space-between", padding: "8px 4px 8px 8px",
    width: "374px", borderRadius: "8px",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    '@media (max-width:660px )': {
        display: "flex",
        marginInline: "auto",
        width: "374px",
        alignItems: "center",
        flexDirection: "row"
    },
    '@media (max-width: 500px )': {
        display: "flex",
        marginInline: "auto",
        width: "300px",
        flexWrap:"wrap",
        alignItems: "center",
        flexDirection: "row"
    }
})
const ImageGrid = styled(Grid)({
    width: "372px",
    gap: "24px",
    '@media (max-width:660px )': {
        width: "300px"
    },
})
const MainBoxItem2 = styled(Box)({
  
    display: "flex",
    flexDirection: "column",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",

    },
})
const MainBoxItem2ForPdf = styled(Box)({
  
    display: "flex",
    flexDirection: "column",
    minWidth: "374px",
    maxWidth: "374px",
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",
        flexWrap: "wrap",

    },
})
const SelectField = styled(FormControl)({
    maxWidth: "374px",
    minWidth: "374px",
    "& .MuiSelect-icon.Mui-disabled":{
        color:'#64748B',
    
    },
    
    "& .MuiSelect-icon":{
        color:'#0F172A'
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-disabled fieldset": {
            borderColor: "#CBD5E1",
          },
    },
    "& .MuiOutlinedInput-input": {
        padding: "12px 8px",
    },
    "& .MuiInputBase-input": {
        lineHeight: "19.2px",

    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#CBD5E1",
        borderRadius: "8px"
    }

    ,
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: "18px",
        fontSize: "12px",
    },
    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px"
    },
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",

    },
});
const CustomAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] .MuiAutocomplete-input:first-child": {
        paddingLeft: 'unset',
        padding: 'unset',
        cursor: 'pointer',
    },
   "& .MuiSvgIcon-root":{color:'#0F172A'},
    "& .MuiAutocomplete-inputRoot": {
        paddingRight: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        color: 'rgb(148, 163, 184)',
    },
    "& .MuiOutlinedInput-input": {
        padding: '0px',
        color: 'rgb(148, 163, 184)',
    },
    '& input::placeholder': {
        color: '#94A3B8',
        opacity: 1
    },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: 'uppercase',
        color: "#334155"
    },

})
const DateStyle = styled(Input)(({disabledStyle}:any)=>({
    minWidth: "175px",
    maxWidth: "175px",
    border:disabledStyle ?"1px solid #F87171":"1px solid var(--Neutrals-Cool-gray-300, #CBD5E1)",
    borderRadius:"8px",
    height:"44px",
    '& input::placeholder': {
        color: '#94A3B8',
        opacity:1
      },
    padding:"10px 8px",
   
}));
 export const InputField = styled(TextField)({
    minWidth: "374px",
    '& input::placeholder': {
        color: '#94A3B8',
        opacity:1
      },
    "& .MuiInputBase-input[type='date']": {
        lineHeight: "19.2px",
        textTransform: 'uppercase',
        color: "#334155"
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: "18px"
    }
    , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F87171"
    },


    "& .MuiInputBase-root": {
        color: "#334155"
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#CBD5E1",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#CBD5E1",
        },
    },

    "& .MuiOutlinedInput-input": {
        padding: "12px 8px",
    },

    "& .MuiFormHelperText-contained": {
        marginLeft: "0px",
        marginRight: "0px"
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        border: "1px solid #CBD5E1",
    },
    '@media (max-width:660px )': {
        minWidth: "unset",
        maxWidth: "unset",

    },
});
 const InputField2 = styled(TextareaAutosize)({
    minWidth: "374px",
    minHeigh: "110px",
    maxWidth:"374px",

    "&::placeholder": {
        color: '#94A3B8',
       
        opacity:1
    },
    padding: "8px",
    maxHeight: "140px",
    color: "var(--Neutrals-Cool-gray-900, #0F172A)",
    fontFamily: "Lato",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "150%",
    "&:focus-visible": {
       outline:"unset",
    },
    '@media (max-width:500px )': {
        minWidth: "300px",

    },
    '@media (max-width:360px )': {
        minWidth: "100%",

    },
});


export const BottonComponentCom = styled(Button)({
    "&.MuiButton-contained": {
        boxShadow: "unset"
    }
})
// Customizable Area End

// Customizable Area Start
const MainGrid = styled(Box)({
    padding: "52px 100px 48px 100px",
    '@media (max-width:1208px) and (min-width:960px)': {
        padding: "52px 40px 48px 40px;"
    },
    '@media (max-width:960px) and (min-width:800px)': {
        padding: "52px 40px 48px 40px"
    },
    '@media (max-width:800px )': {
        padding: "52px 20px 48px 20px"
    },

})
const ButtonComponent = styled(Button)({
    borderRadius: "8px",
    color: "var(--Primary-color-1, #03B5AA)",
    fontFamily: "Lato",
    fontSize: "16px",
    height: "44px",
    fontWeight: 700,
    lineHeight: "24px",

    textTransform: "inherit",
    '&.MuiButton-outlined': {
        border: "1px solid #03B5AA"
    }
})

const PStyle = styled(Typography)({

    '@media (max-width:600px )': {
        display: "none"
    },

})
const PStyle2 = styled(Typography)({
    display: "none",
    '@media (max-width:600px )': {
        display: "block"
    },

})
const HomeGrid = styled(Grid)({
    gap: "32px",
    marginInline: "auto",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "column",
    '@media (max-width:600px )': {
    gap: "12px",
paddingBottom:"unset",
        marginInline: "30px",
    },
})
const HeadingTypography=styled(Typography)({
    width: "177px" ,
    paddingTop:'10px',
    paddingBottom:"10px",
    "@media (max-width:660px)":{
        width:'unset',
        paddingTop:'unset',
        paddingBottom:"unset",
    }
})
const InfoTypography=styled(Typography)({
    minWidth: "375px",
    maxWidth: "375px",
     fontSize: "12px",            
     lineHeight: "18px",           
     fontWeight: 400,           
     color: "#64748B",        
     fontFamily: "Lato",       
     marginTop: "16px",
    "@media (max-width:660px)":{
        minWidth: "unset",
    }
})
export const Allstyle = {
    maxStyle: {
        color: "#64748B",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
    },
    errorTextStyle: {
        color: "#DC2626",
        fontSize: "12px",
        fontFamily: "Lato",
        fontWeight: 400,
        lineHeight: "18px",
    },
    closeIconStyle:{
padding:"10px 16px",
background:"#000",
display:"flex",
justifyContent:"center",
gap:"8px",
color:"#FFF",
right:24,
cursor:"pointer",
borderRadius: "8px",
opacity: "0.75",
width:"76px"

    },
    styleText: {
        color: "#0F172A",
        fontFamily: "Inter",
        fontSize: "30px",
        fontWeight: 700,
        lineHeight: "40px",
        letterSpacing: "-0.15px",
    },

    optionStyle: {
        color: "#0A2239",
        display: "flex",
        cursor: "pointer",
        fontFamily: "Lato",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "16px"
    },
    healthStyle: {
        color: "#475569",
        fontFamily: "Lato",
        paddingTop: "40px",
        borderTop: "1px solid #E2E8F0",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
    },
    nameSTyle: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
    },
    optionalSTyle: {
        color: "#334155",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
    },
    imgStyle: {
        width: "108px"
        , height: "104px",
        display: "flex",
        borderRadius: "8px",
        alignItems: "center" as "center",
        justifyContent: "center" as "center",
        flexDirection: "column" as "column",
        background: "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), var(--0, #FFF)"
    },

    hedingStyle: {
        color: "#475569",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    },

    btnStyle: {
        color: "#FFF",
        fontFamily: "Lato" as "Lato",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        height: "44px",
        borderRadius: "8px",
        padding: "10px 16px",
        background: "#03B5AA",
        textTransform: "inherit" as "inherit",
    },
    imgFieldSyle: {
        color: "#03B5AA",
        fontFamily: "Lato" as "Lato",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
    },
};
// Customizable Area End
