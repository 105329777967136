import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider";

// Customizable Area Start
import moment from "moment";
const baseUrl = require("../../../framework/src/config.js")
import { getStorageData, setStorageData} from "../../../framework/src/Utilities";

 export interface ToolTipConfig {
  text?: string;
  state?: boolean;
  isOpenStateName: string;
}
 export interface HandleNewSelectConfig {
  data: Item[];
  heading: string;
  isImp: boolean;
  errState: boolean;
  errName: string;
  stateValue: string | number | boolean;
  state: string;
  showInfo: boolean;
  toolTip?: ToolTipConfig;
}
export interface HandleMultiSelectConfig{
  state: Item[];
  testId: string;
  data: Item[];
  isImp: boolean;
  heading: string;
  changeFun: ChangeFunction;
  stateLength: number;
  errState: boolean
}
export interface Item{
  id:number;
  name:string;
}
export interface TexteriaFieldParams {
  heading: string;
  isImp: boolean;
  error: boolean;
  errName: string;
  isOpenToolTip: boolean;
  isOpenToolTipStateName: string;
  toolTipValueText: string;
  inputValue: string;
  stateName: string;
  placeHolderText: string;
}
export interface ChangeFunction {
  (event: React.ChangeEvent<{}>, value: any): void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 // Customizable Area Start
 marketing_strategy:string
 statusToast:string;

 marketing_strategy_Err:boolean;
 toolTipState:boolean;
 size_Err:boolean;
 activitiylevel_Err:boolean;
 Lifestyle_Err:boolean;
 coatLength_Err:boolean;
 isOpenRescueStory:boolean;
 Spayed_Neutered:string;
 Spayed_Neutered_toolTip_state:boolean;
 Spayed_Neutered_Err:boolean;
 Animal_determination:string;
 Animal_determination_Err:boolean;
 is_Open_Animal_determination:boolean;
 medical_Conditions:string;
 medical_Conditions_Err:boolean;
 is_Open_medical_Conditions:boolean;
 Good_in_house:Item[];
 ehrlichia_Err:boolean;
 ehrlichia:string;
 parasite_Medication_Err:boolean;
 parasite_Medication:string;
 training_Err:boolean;
 training:string;
 skin_Diseases_Err:boolean;
 skin_Diseases:string;
 skin_Diseases_Value:string;
 skin_Diseases_Value_Err:boolean;
 Lifestyle_Rabbit_Err:boolean;
 Lifestyle_Rabbit:string;
 lifeStyle_bird_Err:boolean;
 lifeStyle_bird:string;
 cage_Size_Required_Err:boolean;
 cage_Size_Required:string;
 habitat_Size_Required_Err:boolean;
 habitat_Size_Required:string;
 noise_Level_Err:boolean;
 noise_Level:string;
 microchipped_or_Ring_Err:boolean;
 microchipped_or_Ring:string;
 wings_Clipped_Err:boolean;
 wings_Clipped:string;
 pbfd_test_Err:boolean;
 pbfd_test:string;
 lifeStyle_LittleCritter_Err:boolean;
 lifeStyle_LittleCritter:string;
 lifeStyle_reptile_Err:boolean;
 lifeStyle_reptile:string;
 little_Critter_VaccineStatus_Err:boolean;
 little_Critter_VaccineStatus:string;
 dewormed_Err:boolean;
 dewormed:string;
 microchipped_number:string;
 microchipped_number_Err:boolean;
 microchipped_Confirm_number:string;
 microchipped_Confirm_number_Err:boolean;
 isShowMarketingStrategy:boolean;
 rabbit_VaccineStatus:string
 rabbit_VaccineStatus_Err:boolean;

 petName:string;
  hookLine:string;
  typeOfAnimal:any
  typeOfAnimalName:string
  breed:any;
  imageStore:boolean;
  location:string;
  RehomingStrategy :string;
  gender:string;
  age:any
  pdferror:boolean
  othererror:boolean
  Reason_for_Editing:string;
  Reason_for_Editing_Error:boolean
  activitiylevel:string;
  Lifestyle:string;
  GoodinHousewith:any[];
  GoodinHousewithValue:string;
  Temperament:any[];
  coatLength:string ;
  selectedImageDataBase:any[];
  selectVideoDataBase:any[];
  speacialneed:string;
  petStory:string;
  fostered:boolean;
  size:any;
  vaccineStatus:string;
  FELVStatus :string;
  Microchiped:string
  videoError:boolean;
animalData:any[];
animalLocationData:any[];
breedData:any[];
imagesData:any[];
pdfData:any[]
videoStore:boolean;
petNameError:boolean;
hookLineError:boolean;
imgErrorValue:string;
typeOfAnimalerror:boolean;
breederror:boolean;
locationerror:boolean;
videoErrorvalue:string
RehomingStrategyerror:boolean;
gendererror:boolean;
ageError:boolean;
imgError:boolean;
TemperamentError:boolean;
PetStoryerror:boolean;
VaccineStatuserror:boolean;
speacialneedBool:any
FELVerror:boolean;
postID:any
Microchipederror:boolean;
 primaryImage:number
  datePickerIsOpen:boolean
  pathname:string
  videoMessage:string
imageMessage:string
newImagesData:any
deletefiles:any[]
newImageBaseUpload:any
VideoNewEditData:any
videoNewUpload:any
pdfDataNew:any[]
  openVideo:boolean;
  urlVideo:any;
  lengthOfVideo:boolean
  locationPathname:string
  lengthOfPdf:boolean
  lengthOfImage:boolean
  openWindow:any
  loader:boolean
  postDetails:boolean
  toolTip1:boolean;
  toolTip2:boolean;
  toolTip3:boolean;
 // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateRescuerPostPageController extends BlockComponent<Props, S, SS> {
  getAnimalApiId: string="";
  getAnimalLocationApiId: string="";
  getBreedApiId: string="";
  getCreatePostId:string=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
    // Customizable Area Start
    marketing_strategy:"Select",
    statusToast:'',
    typeOfAnimalName:"",
    marketing_strategy_Err:false,
    toolTipState:false,
    size_Err:false,
    activitiylevel_Err:false,
    Lifestyle_Err:false,
    coatLength_Err:false,
    isOpenRescueStory:false,
    Spayed_Neutered:'Select',
 Spayed_Neutered_toolTip_state:false,
 Spayed_Neutered_Err:false,
 Animal_determination:'',
 Animal_determination_Err:false,
 is_Open_Animal_determination:false,
 medical_Conditions:'',
 medical_Conditions_Err:false,
 is_Open_medical_Conditions:false,
 Good_in_house:[],
 ehrlichia_Err:false,
 ehrlichia:'Select',
 parasite_Medication_Err:false,
 parasite_Medication:'Select',
 training_Err:false,
 training:'Select',
 skin_Diseases_Err:false,
 skin_Diseases:'Select',
 skin_Diseases_Value:'',
 skin_Diseases_Value_Err:false,
 Lifestyle_Rabbit_Err:false,
 Lifestyle_Rabbit:'Select',
 lifeStyle_bird_Err:false,
 lifeStyle_bird:'Select',
 lifeStyle_LittleCritter_Err:false,
 lifeStyle_LittleCritter:'Select',
 lifeStyle_reptile_Err:false,
 lifeStyle_reptile:"Select",
 cage_Size_Required_Err:false,
 cage_Size_Required:"Select",
 habitat_Size_Required_Err:false,
 habitat_Size_Required:"Select",
 noise_Level_Err:false,
 noise_Level:"Select",
 microchipped_or_Ring_Err:false,
 microchipped_or_Ring:"Select",
 wings_Clipped_Err:false,
 wings_Clipped:"Select",
 pbfd_test_Err:false,
 pbfd_test:"Select",
 little_Critter_VaccineStatus_Err:false,
 little_Critter_VaccineStatus:"Select",
 dewormed_Err:false,
 dewormed:"Select",
 microchipped_number:'',
 microchipped_number_Err:false,
 microchipped_Confirm_number:'',
 microchipped_Confirm_number_Err:false,
 isShowMarketingStrategy:false,
 rabbit_VaccineStatus:'Select',
 rabbit_VaccineStatus_Err:false,
    toolTip1:false,
  toolTip2:false,
  toolTip3:false,
    loader:false,
    locationPathname:window.location.pathname,
    imageStore:false,
    Reason_for_Editing:"",
  Reason_for_Editing_Error:false,
    deletefiles:[],
    openWindow:null,
    othererror:false,
    postDetails:true,
    lengthOfPdf:false,
    lengthOfVideo:false,
    imgErrorValue:"Please add an image",
    videoStore:false,
    animalData:[],
    breedData:[],
    imagesData:[],
    postID:null,
    datePickerIsOpen:false,
    primaryImage:0,
    lengthOfImage:false,
    newImagesData:[],
    pathname:"",
    animalLocationData:[],
    petNameError:false,
hookLineError:false,
pdfDataNew:[],
pdferror:false,
typeOfAnimalerror:false,
videoErrorvalue:"",
breederror:false,
videoError:false,
locationerror:false,
RehomingStrategyerror:false,
speacialneedBool:null,
gendererror:false,
ageError:false,
imgError:false,
newImageBaseUpload:[],
TemperamentError:false,
PetStoryerror:false,
VaccineStatuserror:false,
FELVerror:false,
Microchipederror:false,
videoMessage:"",
imageMessage:"",

VideoNewEditData:[],
videoNewUpload:[],
pdfData:[],
selectedImageDataBase:[],
selectVideoDataBase:[],
    GoodinHousewithValue:"",
      petName:"",
      hookLine:"",
      size:"Select",
      typeOfAnimal:"Select",
      breed:"Select",
      location:"Select",
      RehomingStrategy :"",
      gender:"Select",
      age:"",
      activitiylevel:"Select",
      Lifestyle:"Select",
      GoodinHousewith:[],
      Temperament:[],
      coatLength:"Select" ,
      speacialneed:"",
      petStory:"",
      fostered:false,
      vaccineStatus:"Select",
      FELVStatus :"Select",
      Microchiped:"Select",
      openVideo:false,
  urlVideo:null,
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
 // Customizable Area Start
 editPostId:string=""
 previewId:string=""
 previewDeleteId:string=""
 editPUTid:string=""
 getMarketingStrategyApi:string=""
 
handleHeadingMouseHover=()=>{
    this.setState({toolTip1:true});
  };
   handleHeadingMouseOver=()=>{
    this.setState({toolTip1:false});
  }
   handleHeading4=()=>{
      this.setState({toolTip1:!this.state.toolTip1});
  }
  handleTooltipCloseChanelsData = () => {
    this.setState({ toolTip1: false });
  };


  handleHeadingMouseHover2=()=>{
    this.setState({toolTip2:true});
  };
   handleHeadingMouseOver2=()=>{
    this.setState({toolTip2:false});
  }
   handleHeading2=()=>{
      this.setState({toolTip2:!this.state.toolTip2});
  }
  handleTooltipCloseChanelsData2 = () => {
    this.setState({ toolTip2: false });
  };


  handleHeadingMouseHover3=()=>{
    this.setState({toolTip3:true});
  };
   handleHeadingMouseOver3=()=>{
    this.setState({toolTip3:false});
  }
   handleHeading3=()=>{
      this.setState({toolTip3:!this.state.toolTip3});
  }
  handleTooltipCloseChanelsData3 = () => {
    this.setState({ toolTip3: false });
  };
  async componentDidMount() {
    this.getAnimalData()
    this.getAnimalLocationData()
    this.getBreedData()  
    this.getMarketingStrategy()
    const postDetails:any=this.props.navigation.history.location?.state?.postDetails;
    this.setState({postDetails:postDetails})
    const [ , segment] = this.state.locationPathname.split('/');
    if(segment==="editpost"){
      const id:any=window.location.pathname.split('/').pop();
      this.setState({pathname:"editpost",postID:id})
      this.getPostData(id)
        }
  }

 handleStyle=(state:string|number|boolean,stateName:string)=>{
  if(this.state.pathname=='editpost'&&stateName=='typeOfAnimal')return "#64748B"
  return state === "Select" ? "#94A3B8" : "#334155"
 }
 handleValidationSpace = (maxLength: number, value: any, name: string) => {
  const inputValue = (typeof value === 'string' || value instanceof String) ? value.trim() : '';
  if(name === 'petStory' && inputValue.length > 0) {
    if(inputValue.split(/\s+/).length > 250) {
      return true
    } else {
      return false
    }
  } else {
    if (inputValue.length <= maxLength && inputValue.length > 0) {
      return false;
    } else {
      return true;
    }
  }
}

  handlePreviewNavigate=async()=>{
    this.handleGoodInHouseWithOther()

            const errors = {
              imgError:this.state.pathname=="editpost"?this.state.newImagesData.length==0: this.state.selectedImageDataBase.length==0,
              TemperamentError: this.handleTempramentErrFun(),
              FELVerror: this.handleErrFunc(this.state.FELVStatus,"Cats"),
              petNameError  : this.handleValidationSpace(50,this.state.petName, 'petname'),
              ageError : this.state.age ==="",
              hookLineError: this.handleValidationSpace(50,this.state.hookLine, 'hookline'),
              typeOfAnimalerror: this.state.typeOfAnimal === "Select",
              locationerror  :this.state.location=="Select",
              gendererror  :this.state.gender=="Select",
              VaccineStatuserror: this.handleErrrFunc2(this.state.vaccineStatus,this.hanldeShowField3),
              breederror:this.state.breed=="Select",
              Microchipederror:this.state.Microchiped=="Select" ,
              PetStoryerror: this.handleValidationSpace(1600,this.state.petStory, 'petStory'),
              Lifestyle_Rabbit_Err: this.handleErrFunc(this.state.Lifestyle_Rabbit,"Rabbits"),
              cage_Size_Required_Err:this.handleErrFunc(this.state.cage_Size_Required,"Birds"),
              noise_Level_Err:this.handleErrFunc(this.state.noise_Level,"Birds"),
              wings_Clipped_Err:this.handleErrFunc(this.state.wings_Clipped,"Birds"),
              lifeStyle_bird_Err:this.handleErrFunc(this.state.lifeStyle_bird,"Birds"),
              habitat_Size_Required_Err:this.handleErrrFunc2(this.state.habitat_Size_Required,this.hanldeShowComm1),
              Lifestyle_Err:this.handleErrFunc(this.state.Lifestyle,"Cats"),
              Spayed_Neutered_Err:this.handleErrrFunc2(this.state.Spayed_Neutered,this.hanldeShowField),
              ehrlichia_Err:this.handleErrFunc(this.state.ehrlichia,"Dogs"),
              skin_Diseases_Err:this.handleErrFunc(this.state.skin_Diseases,"Rabbits"),
              parasite_Medication_Err:this.handleErrFunc(this.state.parasite_Medication,"Dogs"),
              training_Err:this.handleErrFunc(this.state.training,"Dogs"),
              lifeStyle_LittleCritter_Err:this.handleErrrFunc2(this.state.lifeStyle_LittleCritter,this.hanldeShowComm_Little_critters),
              lifeStyle_reptile_Err:false,
            microchipped_Confirm_number_Err:this.handleMicroChippedConfirmNumberErr(),
            rabbit_VaccineStatus_Err:this.handleRabbitVaccineStatus(),
            othererror:this.handleGoodInHouseOtherErr(),
            skin_Diseases_Value_Err:this.handleSkinDiseasErrFunc(),
            dewormed_Err:false,
            microchipped_number_Err:this.handleMicroChippedNumberErr(),
      
          };

          const video=this.state.pathname=="editpost"?
          this.state.VideoNewEditData
         : this.state.selectVideoDataBase
    const pdf=this.state.pathname=="editpost"?
    this.state.pdfDataNew
    :this.state.pdfData
    const image=this.state.pathname=="editpost"?
    this.state.newImagesData
    :this.state.selectedImageDataBase
this.setState(errors);
          const hasErrors = Object.values(errors).some(error => error);
        storage.set("preview_button",true)
      if (  !hasErrors ) {
        this.setState({loader:true})
        this.getPreviewData(video,image,pdf)
      }
  }


  handleConditionEditImage=()=>{
    return this.state.pathname =="editpost" ?  this.state.newImagesData.length < 6:this.state.selectedImageDataBase.length < 6
  }

handleConditionEditVideo=()=>{
  return this.state.pathname =="editpost" ?  this.state.VideoNewEditData.length < 2:this.state.selectVideoDataBase.length < 2
}

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.typeOfAnimal !== this.state.typeOfAnimal) {
     this.getBreedData()
    }
  }
  cancelButton=()=>{
    if(this.state.pathname=="editpost"){
      return this.props.navigation.navigate("ProductDescription",{id:this.state.postID,postDetails:this.state.postDetails})
    }
    return this.props.navigation.navigate("CataloguePage")
  }
  handleGoodInHouseWithOther=()=>{
    const check = this.state.GoodinHousewith?.some((item: any) => item === 'Other');
    if(check){
      if(this.state.pathname=="editpost"){
        if(this.state.GoodinHousewithValue!==""){
          const filteredValues =  this.state.GoodinHousewith.filter((item:any) => {
            return configJSON.goodHome.some((goodItem:any) => goodItem.name === item);
          });
          
          this.setState({
            GoodinHousewith:[...filteredValues,this.state.GoodinHousewithValue]
          })
        }
      }
      else{
        if(this.state.GoodinHousewithValue!==""){
        this.setState({
          GoodinHousewith:[...this.state.GoodinHousewith,this.state.GoodinHousewithValue]
        })
      }
   
      }
    }
    else{
      const filteredValues =  this.state.GoodinHousewith.filter((item:any) => {
        return configJSON.goodHome.some((goodItem:any) => goodItem.name === item);
      });
      this.setState({GoodinHousewith:filteredValues})
    }

  }
  
  checkError=()=>{
    this.handleGoodInHouseWithOther()
    
    const errors = {
      petNameError  : this.handleValidationSpace(50,this.state.petName, 'petname'),
    
      hookLineError: this.handleValidationSpace(50,this.state.hookLine, 'hookline'),
      typeOfAnimalerror: this.state.typeOfAnimal === "Select",
      ageError : this.state.age ==="",
      imgError:this.state.pathname=="editpost"?this.state.newImagesData.length==0: this.state.selectedImageDataBase.length==0,
      TemperamentError: this.handleTempramentErrFun(),
      FELVerror: this.handleErrFunc(this.state.FELVStatus,"Cats"),
      VaccineStatuserror: this.handleErrrFunc2(this.state.vaccineStatus,this.hanldeShowField3),
      Microchipederror:this.state.Microchiped=="Select" ,
      PetStoryerror: this.handleValidationSpace(1600,this.state.petStory, 'petStory'),
      breederror:this.state.breed=="Select",
      locationerror  :this.state.location=="Select",
      gendererror  :this.state.gender=="Select",
      Lifestyle_Err:this.handleErrFunc(this.state.Lifestyle,"Cats"),
      Spayed_Neutered_Err:this.handleErrrFunc2(this.state.Spayed_Neutered,this.hanldeShowField),
      ehrlichia_Err:this.handleErrFunc(this.state.ehrlichia,"Dogs"),
      parasite_Medication_Err:this.handleErrFunc(this.state.parasite_Medication,"Dogs"),
      training_Err:this.handleErrFunc(this.state.training,"Dogs"),
      skin_Diseases_Err:this.handleErrFunc(this.state.skin_Diseases,"Rabbits"),
      Lifestyle_Rabbit_Err: this.handleErrFunc(this.state.Lifestyle_Rabbit,"Rabbits"),
      cage_Size_Required_Err:this.handleErrFunc(this.state.cage_Size_Required,"Birds"),
      noise_Level_Err:this.handleErrFunc(this.state.noise_Level,"Birds"),
      wings_Clipped_Err:this.handleErrFunc(this.state.wings_Clipped,"Birds"),
      lifeStyle_bird_Err:this.handleErrFunc(this.state.lifeStyle_bird,"Birds"),
      habitat_Size_Required_Err:this.handleErrrFunc2(this.state.habitat_Size_Required,this.hanldeShowComm1),
      lifeStyle_LittleCritter_Err:this.handleErrrFunc2(this.state.lifeStyle_LittleCritter,this.hanldeShowComm_Little_critters),
      lifeStyle_reptile_Err:false,
      dewormed_Err:false,
      microchipped_number_Err:this.handleMicroChippedNumberErr(),
      microchipped_Confirm_number_Err:this.handleMicroChippedConfirmNumberErr(),
      rabbit_VaccineStatus_Err:this.handleRabbitVaccineStatus(),
      othererror:this.handleGoodInHouseOtherErr(),
      skin_Diseases_Value_Err:this.handleSkinDiseasErrFunc()

    };
    return errors;
  }
    handleValidtion = async() => {
     this.handleGoodInHouseWithOther()
      const errors = this.checkError()
      this.setState(errors);
      const hasErrors = Object.values(errors).some(error => error);
      if (  !hasErrors ) {
  
  this.setState({Reason_for_Editing_Error:false})
  this.setState({loader:true})
  const previewButton = await storage.get("preview_button");
  const data = JSON.parse(await storage.get("preview_data"));
this.handlePostEditCondition(previewButton,data)

       

       
    }
    }

    handlePostEditCondition = async (previewButton: string, data: any ) => {
      const isEditNewPost = this.state.pathname === "editpost";
      const deletFiles: any[] = [];
    
      const { images, videos, vaccination_books: pdf } = data?.data?.attributes || {};
      const processItem = (item: any, elementArray: any, fileType: string) => {
        if (item?.id && !elementArray?.some((element: any) => element?.id === item?.id)) {
          deletFiles.push(item?.id);
        }
      };
    
      const processFileArray = (fileArray: any[], fileType: string) => {
        fileArray?.forEach((item: any) => item?.id && deletFiles.push(item?.id));
      };

    
      if (isEditNewPost) {
        this.state.newImagesData.forEach((item: any) => processItem(item, images, 'images'));
        this.state.VideoNewEditData.forEach((item: any) => processItem(item, videos, 'videos'));
        this.state.pdfDataNew.forEach((item: any) => processItem(item, pdf, 'pdf'));

      return this.DeletePreviewAPI(deletFiles);
      } else {
        processFileArray(images, 'images');
        processFileArray(videos, 'videos');
        processFileArray(pdf, 'pdf');
        return previewButton ? this.DeletePreviewAPI(deletFiles) : this.postCreateData();
      }
    };
    
    getPreviewData=async(video:any,image:any,pdf:any)=>{
      const header = {
        "token":  await storage.get("auth_token") 
      };
      const previewData = this.returnFormDataFunc()||new FormData();
   previewData.delete('images[]')
previewData.delete('videos[]')
previewData.delete('vaccination_books[]')
    image.forEach((item: any) => {
      if(item?.url){
        return previewData.append("images[]", JSON.stringify(item));

      }
     return previewData.append("images[]", item);
  });

  video.forEach((item: any) => {
    if(item?.url){
      return previewData.append("videos[]", JSON.stringify(item));
    }
     return previewData.append("videos[]", item);
  });
  pdf.forEach((item: any) => {
    if(item?.url){
      return previewData.append("vaccination_books[]", JSON.stringify(item));
    }
      previewData.append("vaccination_books[]", item);
  });
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.previewId = requestMessage.messageId
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_posts/posts/preview_post`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        previewData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
     }

   getPostData=async(id:number)=>{
    const header = {
      "content-Type": "application/json",
      "token":  await storage.get("auth_token") 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPostId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

   
   }

      putPostData=async()=>{
    const token = await storage.get("auth_token") as string;
    const header = {
      token: token
    };

    const formData =this.returnFormDataFunc()||new FormData();

formData.append("delete_files", JSON.stringify(this.state.deletefiles));
formData.delete('images[]')
formData.delete('videos[]')
formData.delete('vaccination_books[]')
formData.append("action_by_admin", String(true));{
this.state.newImagesData.forEach((item: any) => {
  if (!item.url) {
    formData.append("images[]", item);
  }
});

this.state.VideoNewEditData.forEach((item: any) => {
  if (!item.url) {
    formData.append("videos[]", item);
  }
});
this.state.pdfDataNew.forEach((item: any) => {
  if (!item.url) {
    formData.append("vaccination_books[]", item);
  }
});
}

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPUTid = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/posts/${this.state.postID}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

   
   }
handlevaccineStatus=(item: any) => {
  this.setState({ vaccineStatus: item.name, VaccineStatuserror: false })
}
handleFELVStatus=(item: any) => {
  this.setState({ FELVStatus: item.name, FELVerror: false })
}

handleMicrochiped=(item: any) => {
  this.setState({ Microchiped: item.name, Microchipederror: false })
}


handleRehomingStrategy=(item: any) => {
  this.setState({ RehomingStrategy: item.name, RehomingStrategyerror: false })
}
handleLifestyle=(item: any) => {
  this.setState({ Lifestyle: item.name })
}
handlecoatLength=(item: any) => {
  this.setState({ coatLength: item.name })
}

handlespeacialneed=(item: any) => {
  this.setState({ speacialneed: item.name, speacialneedBool: item.value })
}
handleTemperament=(itemName: any) => {
  this.setState((prevState: any) => {
      const updatedTemperament = prevState.Temperament.includes(itemName.name)
          ? prevState.Temperament.filter((selected: any) => selected !== itemName.name)
          : [...prevState.Temperament, itemName.name];

      return {
          Temperament: updatedTemperament,
          TemperamentError: updatedTemperament.length === 0,
      };
  });


}
handleGoodinHousewith=(itemName: any) => {
   const updatedGoodinHousewith = this.state.GoodinHousewith.includes(itemName.name)
      ? this.state.GoodinHousewith.filter((selected: any) => selected !== itemName.name)
      : [...this.state.GoodinHousewith, itemName.name];
const otherError = !updatedGoodinHousewith.includes("Other"); 
  this.setState((prevState: any) => ({
      GoodinHousewith: prevState.GoodinHousewith.includes(itemName.name)
          ? prevState.GoodinHousewith.filter((selected: any) => selected !== itemName.name)
          : [...prevState.GoodinHousewith, itemName.name],
othererror:otherError
  }));
}

handleactivitiylevel= (item: any) => {
  this.setState({ activitiylevel: item.name })
}
handlesize= (item: any) => {
  this.setState({ size: item.id })
}
handlegender=(item: any) => {
  this.setState({ gender: item.name, gendererror: false })
}

handleImageUpload = (event: any) => {
  const selectedPdfs = event.target.files;
  const maxFileSize = 5 * 1024 * 1024;
  const allowedExtensions = ["png", "jpg", "jpeg"];
  const imageFilesArray = Array.from(selectedPdfs);

  const invalidFilesExetnsion = imageFilesArray.filter((imageFile: any) => {
    const extension = imageFile.name.split(".").pop()?.toLowerCase();
    return (
        !allowedExtensions.includes(extension)
    );
});
if(invalidFilesExetnsion.length>0){
  this.setState({ imageMessage: "Invalid file format. Accepted formats: PNG, JPG and JPEG", imgError:false,lengthOfImage:false}, () => {
    event.target.value = "";
});
  return;
}


  const invalidFiles = imageFilesArray.filter(
      (imageFile:any) => imageFile.size > maxFileSize
  );

  if (invalidFiles.length > 0) {
      this.setState({ imageMessage: "Maximum file size is 5 MB" ,lengthOfImage:false,imgError:false}, () => {
        event.target.value = "";
    });
      return;
  }
  const selectedImages = Array.from(event.target.files);
  const imageDataArray = this.state.pathname === "editpost" ? "newImagesData" : "selectedImageDataBase";
  if (this.state[imageDataArray].length + selectedImages.length > 6) {
      this.setState({ lengthOfImage: true,imageMessage:"",imgError:false }, () => {
              event.target.value = "";
          });
      return;
  }
if(this.state[imageDataArray].length<6){
      const remainingSpace = 6 -this.state[imageDataArray].length;
      const imagesToAdd = selectedImages.slice(0, remainingSpace);
      this.setState((prevState: any) => ({
              selectedImageDataBase: [...prevState.selectedImageDataBase, ...imagesToAdd],
              imageMessage: "",
              imgError:false,
              newImagesData: [...prevState.newImagesData, ...imagesToAdd],
              lengthOfImage:false
          }),
          () => {
              event.target.value = "";
          }
      );
  }
};

        handleRemovePdf = (index: number) => {
          const updatedPdfData = [...this.state.pdfData];
          updatedPdfData.splice(index, 1);
          this.setState({ pdfData: updatedPdfData })
          
        }
        

    handleRemovePdfUpload = (index: number) => {
      const updatedPdfData = [...this.state.pdfDataNew];
    const handle:any=  updatedPdfData.splice(index, 1);
    const deleteFileId = handle[0]?.id;
    
    const updatedDeleteFiles = deleteFileId !== undefined? [...this.state.deletefiles, deleteFileId] : this.state.deletefiles;
    this.setState({ pdfDataNew: updatedPdfData,deletefiles:updatedDeleteFiles });
    };
    
    handlePdfUpload = (event: any) => {
      const selectedPdfs = event.target.files;
      const maxFileSize = 3 * 1024 * 1024; 
      const allowedExtensions = ["pdf", "jpg", "jpeg",'png'];
      const pdfFilesArray = Array.from(selectedPdfs);
      const invalidFilesExetnsion = pdfFilesArray.filter((pdfFile: any) => {
        const extension = pdfFile.name.split(".").pop()?.toLowerCase();
        return (
            !allowedExtensions.includes(extension)
        );
    });
    if(invalidFilesExetnsion.length>0){
      this.setState({ pdferror:true, }, () => {
        event.target.value = "";
    });
      return;
    }
      if (selectedPdfs.length > 0) {
          const pdfFilesArray = Array.from(selectedPdfs);
  
          const invalidFiles = pdfFilesArray.filter(
              (pdfFile:any) => pdfFile.size > maxFileSize
          );
  
          if (invalidFiles.length > 0) {
              this.setState({ pdferror: true ,lengthOfPdf:false}, () => {
                event.target.value = "";
            });
              return;
          }
          
          const pdfDataArray = this.state.pathname === "editpost" ? "pdfDataNew" : "pdfData";
          if (this.state[pdfDataArray].length + pdfFilesArray.length > 3) {
            this.setState({ lengthOfPdf: true , pdferror: false }, () => {
              event.target.value = "";
          });
            return;
        }
          if(this.state[pdfDataArray].length<3){
          
          this.setState((prevState: any) => ({
              pdfData: [...prevState.pdfData, ...pdfFilesArray],
              pdferror:false,
pdfDataNew:[...prevState.pdfDataNew, ...pdfFilesArray],
lengthOfPdf:false
          }),
           () => {
        event.target.value = "";
    }
          );
          
      }
     
    }
     
  };
  processArray = (arr: any[]) => {
    if (Array.isArray(arr)) {
        return arr.map((item: any) => {
            if (typeof item === 'string') {
                return item.split(',')
                    .map((s: string) => s.trim())
                    .map((s: string) => s.charAt(0).toUpperCase() + s.slice(1)) 
                    .filter(Boolean); 
            } else {
                return []; 
            }
        });
    } else {
        return [];
    }
};

handleJsonConfig=(configJsonData:any[],realData:string)=>{
return realData && configJsonData.find((item:any)=>item.name.toLowerCase()==realData.toLowerCase())?.name;
}
 handleResponseEdit=(responseJson:any)=>{
  const Data=responseJson?.data?.attributes;
  const processedArray1 = Data?.good_in_house_with||[];
const processedArray2 =Data?.temperament||[];
  let missingValues = processedArray1 && processedArray1.length > 0 && processedArray1.filter((value: any) => !configJSON.goodHome.some((item: any) => item.name === value));
    let newArrGoodsHouse=this.newArrGoodsHouse(processedArray1,missingValues)
    let newArrTemperament=this.newArrTempramentEdit(processedArray2)
    let skinValueObject=this.handleSkinDiseaseValueEdit(Data?.skin_diseases)
  this.setState({petName:Data?.name,
    hookLine:Data?.hook_line,
    VideoNewEditData:Data?.videos,
    typeOfAnimal:Data?.category?.id,
    typeOfAnimalName:Data?.category.name,
    breed:Data?.sub_category?.id ||"Select",
    location:this.handleJsonConfig( this.state.animalLocationData,Data?.location) ||"Select"
    ,
    gender:this.handleJsonConfig( configJSON.gender2,Data?.gender),
    size: this.handleJsonConfig( configJSON.size,Data?.size),
    Lifestyle:
    this.handleJsonConfig( configJSON.lifeStyle,Data?.life_style) ,
    fostered:Data?.pet_is_already_fostered,
    Temperament:newArrTemperament,
    GoodinHousewithValue:missingValues && missingValues[0] ||"",
    petStory:Data?.pet_story || '',
    Microchiped:Data?.microchiped?"Yes":"No",
    coatLength:
    this.handleJsonConfig( configJSON.coatLength,Data?.coat_length)||'Select',
    
    vaccineStatus:
    this.handleJsonConfig( configJSON.VaccineStatus,Data?.health_vaccine_status),
    activitiylevel:
    this.handleJsonConfig( configJSON.level,Data?.activity_level)||"Select",
    RehomingStrategy:
    this.handleJsonConfig( configJSON.RehomingStrategy,Data?.rehoming_strategy),
    FELVStatus:
    this.handleJsonConfig( configJSON.FIVStatus,Data?.fiv_status),
    age: moment.parseZone(Data?.age).format('MM/DD/YYYY'),
    primaryImage:
    Data?.images.findIndex((item:any) => item.id === Data?.primary_image),
    newImagesData:Data?.images,
    pdfDataNew :Data?.vaccination_books,
    marketing_strategy:Data?.marketing_strategy||'Select',
    cage_Size_Required:Data?.cage_size||"Select",
    noise_Level:Data?.noise_level,
    microchipped_number:Data?.microchip_number,
    microchipped_Confirm_number:Data?.microchip_number,
    wings_Clipped:this.handleYesNoForEdit(Data?.wings_clipped),
    pbfd_test:Data?.pbfd_test||'Select',
    Animal_determination:Data?.animal_of_determination,
    medical_Conditions:Data.medical_conditions,
    lifeStyle_bird:Data.life_style,
    Good_in_house:newArrGoodsHouse,
    dewormed:this.handleYesNoForDewormedEdit(Data?.dewormed),
    Spayed_Neutered:this.handleYesNoForEdit(Data?.spayed),
    ehrlichia:Data?.ehrlichia,
    parasite_Medication:Data?.parasite_medication,
    training:Data?.training,
    rabbit_VaccineStatus:Data?.health_vaccine_status,
    skin_Diseases:skinValueObject?.skin_Diseases,
    skin_Diseases_Value:skinValueObject?.skin_Diseases_Value,
    Lifestyle_Rabbit:Data?.life_style,
    habitat_Size_Required:Data?.habitat_size,
    lifeStyle_LittleCritter:Data?.life_style,
    lifeStyle_reptile:Data?.life_style,
    little_Critter_VaccineStatus:Data?.health_vaccine_status

  })
 }   
 handleHeding=()=>{
  return this.state.pathname!=="editpost"?"Create New Post":"Edit Post "
 }
 handleBorder=(error:boolean)=>{
  return error?"1px solid #F87171":"1px solid #CBD5E1"
 }
 handleButtonName=()=>{
  return this.state.pathname=="editpost"?"Save Changes and Submit": "Submit Post"
 }
 handlePdfErrorUPLOAD=()=>{
  return this.state.pathname=="editpost"?this.state.pdfDataNew.length <3:this.state.pdfData.length < 3
 }
 
 handlePdfError=()=>{
  return this.state.pathname=="editpost"?this.state.pdfDataNew.length <=2:this.state.pdfData.length <=2
 }

 handlePaginationPdf=()=>{
  return this.state.pathname=="editpost"?this.state.pdfDataNew.length >0:this.state.pdfData.length > 0
 }
    handleVideoUpload = (event:any) => {
      const selectedVideos = Array.from(event.target.files);
    
      const videoDataArray = this.state.pathname === "editpost" ? "VideoNewEditData" : "selectVideoDataBase";
        const videoFilesArray = Array.from(selectedVideos);
        const maxFileSize = 5 * 1024 * 1024; 
        const allowedExtensions = ["mp4", "webm"];
        const invalidFilesExetnsion = videoFilesArray.filter((imageFile: any) => {
          const extension = imageFile.name.split(".").pop()?.toLowerCase();
          return (
              !allowedExtensions.includes(extension)
          );
      });
        if(invalidFilesExetnsion.length>0){
          this.setState({ videoMessage
            : "Invalid file format. Accepted formats: MP4, WebM",lengthOfVideo: false,videoError:false, }, () => {
            event.target.value = "";
        });
          return;
        }
        const invalidFiles = selectedVideos.filter(
            (videoFile:any) => videoFile.size > maxFileSize
        );
  
        if (invalidFiles.length > 0) {
            this.setState({ videoMessage: "Maximum file size is 5 MB",lengthOfVideo: false,videoError:false, }, () => {
              event.target.value = "";
          });
            return;
        }
        if (this.state[videoDataArray].length + selectedVideos.length > 2) {
          this.setState({ lengthOfVideo: true,videoError:false,videoMessage:"" }, () => {
            event.target.value = "";
        });
          return;
      }
        if(this.state[videoDataArray].length <2){
        this.setState((prevState: any) => ({
          selectVideoDataBase: [...prevState.selectVideoDataBase, ...videoFilesArray],
          videoNewUpload:[...prevState.videoNewUpload,...videoFilesArray],
            VideoNewEditData:[...prevState.VideoNewEditData,...videoFilesArray],
            lengthOfVideo:false,
          videoError:false,
          videoMessage:""
        }),
        () => {
          event.target.value = "";
      }
        );
      }
      
    };
    handlefostered=() => {
      this.setState({ fostered: !this.state.fostered })
  }
  removeVideo = (index:number) => {
    const removeFromBase64=[...this.state.selectVideoDataBase];

    removeFromBase64.splice(index, 1);

    this.setState({
       selectVideoDataBase:removeFromBase64});
  };
  
    openVideo=(index:number)=>{
      
      
this.setState({openVideo:true,
lengthOfVideo:false,

  urlVideo:this.state.pathname=="editpost"?this.state.VideoNewEditData[index] :this.state.selectVideoDataBase[index]})
    }
    closeVideo=()=>{
      this.setState({openVideo:false})
          }
    removeEditVideo = (index:number) => {
      
      const updatedVideosData = [...this.state.VideoNewEditData];
      const updatedVideosData2 = [...this.state.videoNewUpload];
      
      
      const handle:any=   updatedVideosData.splice(index, 1);
      const deleteFileId = handle[0]?.id;
      const updatedDeleteFiles =deleteFileId !== undefined ? [...this.state.deletefiles, deleteFileId] : this.state.deletefiles;
     updatedVideosData2.splice(index, 1);
   

      this.setState({videoNewUpload:updatedVideosData2,
lengthOfVideo:false,

         VideoNewEditData: updatedVideosData,deletefiles:updatedDeleteFiles});
    };
    
    removeImage = (index: number) => {

      const removeImage=[...this.state.selectedImageDataBase]
      
      removeImage.splice(index, 1);
     
      this.setState({ selectedImageDataBase:removeImage,
        imgError: removeImage.length === 0 ,
        lengthOfImage:false,
        primaryImage:this.state.primaryImage!==0?this.state.primaryImage-1:0

      });
      if(index==this.state.primaryImage){
        this.setState({primaryImage:0})
       }
    };
    removeEditImage = (index: number) => {
      const updatedImagesData = [...this.state.newImagesData];
      const updatedImagesData2 = [...this.state.newImageBaseUpload];
    const handle:any=  updatedImagesData.splice(index, 1);
this.state. newImageBaseUpload.splice(index, 1);
const deleteFileId = handle[0]?.id;

const updatedDeleteFiles = deleteFileId !== undefined ? [...this.state.deletefiles, deleteFileId] : this.state.deletefiles;
this.setState({ newImagesData: updatedImagesData,
        newImageBaseUpload:updatedImagesData2,
deletefiles:updatedDeleteFiles,
lengthOfImage:false,
        imgError: updatedImagesData.length === 0,
        primaryImage:this.state.primaryImage!==0?this.state.primaryImage-1:0

       });

        if(index==this.state.primaryImage || handle[0]?.id==this.state.primaryImage){
          this.setState({primaryImage:0})
         }
    };

    getAnimalData = async () => {
      this.setState({loader:true})
      const header = {
        "content-Type": "application/json",
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getAnimalApiId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_categories/categories`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
    getAnimalLocationData = async () => {
      this.setState({loader:true})
      const header = {
        "content-Type": "application/json",
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getAnimalLocationApiId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/account_block/dropdowns/emirates`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
    getBreedData = async () => {
      const header = {
      "content-Type": "application/json"
      };
      
    const url=this.state.typeOfAnimal!==null?`/bx_block_categories/sub_categories?category_id=${JSON.stringify([this.state.typeOfAnimal])}`:`/bx_block_categories/sub_categories?category_id`
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getBreedApiId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
   DeletePreviewAPI = async (deletFiles:any[]) => {
      const header = {
      "content-Type": "application/json",
      "token":await storage.get("auth_token")
      };
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.previewDeleteId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/delete_temp_attachments?ids=${JSON.stringify(deletFiles)}`
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };
    openDatePicker() {
      this.setState({
        datePickerIsOpen: !this.state.datePickerIsOpen,
      });
    }
    handleChanges = (selectedDate:any) => {
      this.setState({ age: moment(selectedDate).format('MM/DD/YYYY') ,datePickerIsOpen:false,ageError:false});
    };
    convertFormat=()=>{
   return   this.state.age && moment.parseZone(this.state.age).format('DD/MM/YYYY')
    }

    convertUrl=()=>{
      return this.state.urlVideo?.url ? `${baseUrl.baseURL}${ this.state.urlVideo.url}` : URL.createObjectURL( this.state.urlVideo)
    }
    checkGoodInHouse=()=>{

      const goodHomeNames = configJSON.goodHome.map((item :any)=> item.name);
  
      let updatedGoodinHousewith;
      
      if (this.state.GoodinHousewith.includes('Other')) {
        updatedGoodinHousewith = [...this.state.GoodinHousewith,this.state.GoodinHousewithValue]
      } else {
        updatedGoodinHousewith = [
          ...this.state.GoodinHousewith.filter(item => goodHomeNames.includes(item)),
        ];
      }
      return updatedGoodinHousewith
    }
    returnFormdataCat=()=>{
      const formData = new FormData();
      {this.state.vaccineStatus!=='Select'&&formData.append("health_vaccine_status", this.state.vaccineStatus);}
      formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))
      formData.append("fiv_status", this.state.FELVStatus);
   
      formData.append("location", this.state.location);
      formData.append("gender", this.state.gender);
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
      formData.append("spayed", this.handelSpayedYesNoFunc());
      formData.append("dewormed",this.handleDewormedCheck());
      formData.append("life_style", this.state.Lifestyle);
      formData.append("size",this.handleCheckForSubmitFormData(this.state.size))
      formData.append("coat_length",this.handleCheckForSubmitFormData(this.state.coatLength))
      const newArr = this.handleCheckGoodInHouseWithIncludeOther()
       { this.state.Good_in_house.length > 0 &&

        newArr.forEach((item: string) => { formData.append("good_in_house_with[]", item);})
        }
      {
        this.state.Temperament.forEach((item: Item) => {

          formData.append("temperament[]", item.name);
        })
      }
    
    formData.append("activity_level", this.handleCheckForSubmitFormData(this.state.activitiylevel))         

      

formData.append("primary_image", JSON.stringify(this.state.primaryImage));
formData.append("name", this.state.petName);
formData.append("category_id", this.state.typeOfAnimal);
formData.append("hook_line", this.state.hookLine);
formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
formData.append("microchip_number", this.handleMicrochipedFuncYesNo()=="true"?this.state.microchipped_number:'');

formData.append("sub_category_id", this.state.breed);
formData.append("microchiped", this.handleMicrochipedFuncYesNo());
formData.append("pet_story", this.state.petStory);
formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
formData.append("animal_of_determination",this.checkString()?this.state.Animal_determination:'')
{
  this.state.selectVideoDataBase.length > 0 &&
  this.state.selectVideoDataBase.forEach((item) => {
    formData.append("videos[]", item);
  })
}

formData.append("action_by_admin", String(true));{
  this.state.selectedImageDataBase.forEach((item) => {
    formData.append("images[]", item);
  })
  {
    this.state.pdfData.length > 0 &&
    this.state.pdfData.forEach((item) => {formData.append("vaccination_books[]", item);})
    return formData
   }
}


    }
    returnFormdataDog=()=>{

      const formData = new FormData();
      formData.append("name", this.state.petName);
      formData.append("primary_image", JSON.stringify(this.state.primaryImage));
      formData.append("hook_line", this.state.hookLine);
      formData.append("gender", this.state.gender);
      formData.append("ehrlichia", this.state.ehrlichia);
      formData.append("parasite_medication", this.state.parasite_Medication);
      formData.append("training", this.state.training);
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
      formData.append("spayed", this.handelSpayedYesNoFunc());
      formData.append("category_id", this.state.typeOfAnimal);
      formData.append("sub_category_id", this.state.breed);
      formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
      formData.append("microchip_number", this.handleMicrochipedFuncYesNo() == "true" ? this.state.microchipped_number : '');
      formData.append("microchiped", this.handleMicrochipedFuncYesNo());
     formData.append("animal_of_determination", this.checkString()?this.state.Animal_determination:'')
      formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
      formData.append("location", this.state.location);
      const newArr = this.handleCheckGoodInHouseWithIncludeOther()
      {
        this.state.Good_in_house.length > 0 &&
        
        newArr.forEach((item: string) => {
          
          formData.append("good_in_house_with[]", item);
        })
        
      }
      {
        this.state.Temperament.forEach((item: Item) => {

          formData.append("temperament[]", item.name);
        })
      }
      formData.append("size",this.handleCheckForSubmitFormData(this.state.size))
    formData.append("coat_length", this.handleCheckForSubmitFormData(this.state.coatLength))
     formData.append("activity_level", this.handleCheckForSubmitFormData( this.state.activitiylevel))
      formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))
formData.append("pet_story", this.state.petStory);
{this.state.vaccineStatus!=='Select'&&formData.append("health_vaccine_status", this.state.vaccineStatus);}
formData.append("action_by_admin", String(true));{
  this.state.selectedImageDataBase.forEach((item: any) => {
    formData.append("images[]", item);
  })
}
{
  this.state.selectVideoDataBase.length > 0 &&
  this.state.selectVideoDataBase.forEach((item: any) => {
    formData.append("videos[]", item);
  })
}

{
  this.state.pdfData.length > 0 && this.state.pdfData.forEach((item) => {
    
    formData.append("vaccination_books[]", item);
  })
 
  return formData
}
    }
    returnFormdataRabbit=()=>{
   
      const formData = new FormData();
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
      formData.append("category_id", this.state.typeOfAnimal);
      formData.append("name", this.state.petName);
      formData.append("primary_image", JSON.stringify(this.state.primaryImage));
      formData.append("hook_line", this.state.hookLine);
      formData.append("sub_category_id", this.state.breed);
      formData.append("location", this.state.location);
      formData.append("gender", this.state.gender);
      formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
      formData.append("microchiped", this.handleMicrochipedFuncYesNo());
      formData.append("microchip_number", this.handleMicrochipedFuncYesNo()=="true"?this.state.microchipped_number:'');
      formData.append("animal_of_determination", this.checkString()?this.state.Animal_determination:'')
      formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
      formData.append("life_style", this.state.Lifestyle_Rabbit)
      formData.append("size",this.handleCheckForSubmitFormData(this.state.size))
      formData.append("coat_length", this.handleCheckForSubmitFormData(this.state.coatLength))
       formData.append("activity_level", this.handleCheckForSubmitFormData( this.state.activitiylevel))
      formData.append("spayed", this.handelSpayedYesNoFunc());
      formData.append("skin_diseases",this.handleSkinDiseasValue())
      const newArr = this.handleCheckGoodInHouseWithIncludeOther()
      {
        this.state.Good_in_house.length > 0 &&

        newArr.forEach((item: string) => {

          formData.append("good_in_house_with[]", item);
        })

      }
   
{this.state.Temperament.forEach((item:Item)=>{
   formData.append("temperament[]", item.name);
  })
}
   


formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))
formData.append("pet_story", this.state.petStory);
formData.append("action_by_admin", String(true));{
  this.state.selectedImageDataBase.forEach((item) => {
    formData.append("images[]", item);
  })
}
{
  this.state.selectVideoDataBase.length > 0 &&this.state.selectVideoDataBase.forEach((item) => {
    formData.append("videos[]", item);
  })
}
{this.state.rabbit_VaccineStatus!=='Select'&&formData.append("health_vaccine_status", this.state.rabbit_VaccineStatus);}
{
  this.state.pdfData.length > 0 && this.state.pdfData.forEach((item) => {
    formData.append("vaccination_books[]", item);
  })
 return formData
  
}
    }
    returnFormdataBirds=()=>{

      const formData = new FormData();
      formData.append("name", this.state.petName);
      formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
      formData.append("microchip_number", this.handleMicrochipedFuncYesNo() == "true" ? this.state.microchipped_number : '');
   
      formData.append("microchiped", this.handleMicrochipedFuncYesNo());
      formData.append("primary_image", JSON.stringify(this.state.primaryImage));
      formData.append("hook_line", this.state.hookLine);
      formData.append("animal_of_determination",this.checkString()?this.state.Animal_determination:'')
      formData.append("category_id", this.state.typeOfAnimal);
      formData.append("sub_category_id", this.state.breed);
      formData.append("location", this.state.location);
      formData.append("gender", this.state.gender);
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
       formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
      formData.append("life_style", this.state.lifeStyle_bird);
      formData.append("noise_level",this.state.noise_Level)
      formData.append("wings_clipped",this.handleWingClippedYesNo())
      formData.append("pbfd_test",this.handleCheckForSubmitFormData(this.state.pbfd_test))
      formData.append("cage_size",this.state.cage_Size_Required)
      const newArr = this.handleCheckGoodInHouseWithIncludeOther()
      {this.state.Good_in_house.length > 0 &&
newArr.forEach((item: string) => {

          formData.append("good_in_house_with[]", item);
        })
         }
         {
        this.state.Temperament.forEach((item: Item) => { formData.append("temperament[]", item.name);
        })
 }

    formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))
 formData.append("pet_story", this.state.petStory);
formData.append("action_by_admin", String(true));{
  this.state.selectedImageDataBase.forEach((item) => {
    formData.append("images[]", item);
  })
}      
 {
  this.state.selectVideoDataBase.length > 0 &&
  this.state.selectVideoDataBase.forEach((item) => {
    formData.append("videos[]", item);
  })
}
    {
  this.state.pdfData.length > 0 &&
  this.state.pdfData.forEach((item) => {
    formData.append("vaccination_books[]", item);
  })
  return formData
}
    }
    returnFormdataReptiles=()=>{

      const formData = new FormData();
      formData.append("name", this.state.petName);
      formData.append("primary_image", JSON.stringify(this.state.primaryImage));
      formData.append("microchiped", this.handleMicrochipedFuncYesNo());
      formData.append("hook_line", this.state.hookLine);
      formData.append("category_id", this.state.typeOfAnimal);
      formData.append("sub_category_id", this.state.breed);
      formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
      formData.append("microchip_number", this.handleMicrochipedFuncYesNo() == "true" ? this.state.microchipped_number : '');
     formData.append("animal_of_determination", this.checkString()?this.state.Animal_determination:'')
      formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
      formData.append("location", this.state.location);
      {
        this.state.Temperament.forEach((item:Item)=>{
          formData.append("temperament[]", item.name);
        })
      } 
      this.state.Temperament.length==0&&formData.append("temperament[]", '');
      formData.append("life_style", this.handleCheckForSubmitFormData(this.state.lifeStyle_reptile));
      formData.append("gender", this.state.gender);
      formData.append("habitat_size",this.state.habitat_Size_Required)
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
   
     formData.append("pet_story", this.state.petStory);
     {
       this.state.selectVideoDataBase.length > 0 &&
       this.state.selectVideoDataBase.forEach((item) => {
         formData.append("videos[]", item);
       })
     }
 formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))
formData.append("action_by_admin", String(true));{
  this.state.selectedImageDataBase.forEach((item) => {
    formData.append("images[]", item);
  })
}
{
  this.state.pdfData.length > 0 &&
  this.state.pdfData.forEach((item) => {
    formData.append("vaccination_books[]", item);
  })
  return formData
}
    }
    returnFormdataLittleCritter=()=>{
      const formData = new FormData();
      formData.append("name", this.state.petName);
      formData.append("animal_of_determination", this.checkString()?this.state.Animal_determination:'')
      formData.append("medical_conditions", this.checkString2()?this.state.medical_Conditions:'')
      formData.append("location", this.state.location);
      formData.append("gender", this.state.gender);
      formData.append("age", moment(this.state.age).format("DD/MM/YYYY"));
      formData.append("life_style", this.state.lifeStyle_LittleCritter);
      formData.append("habitat_size",this.state.habitat_Size_Required)
      
      {
        this.state.Temperament.forEach((item:Item)=>{
          
          formData.append("temperament[]", item.name);
        })
      }
      this.state.Temperament.length==0&&formData.append("temperament[]", '');
      formData.append("pet_story", this.state.petStory);
      formData.append("primary_image", JSON.stringify(this.state.primaryImage));
      formData.append("hook_line", this.state.hookLine);
      formData.append("category_id", this.state.typeOfAnimal);
      formData.append("sub_category_id", this.state.breed);
      formData.append("marketing_strategy", this.handleMarketingStrategyCondition()?this.state.marketing_strategy:'');
      formData.append("microchip_number", this.handleMicrochipedFuncYesNo() == "true" ? this.state.microchipped_number : '');
      formData.append("microchiped", this.handleMicrochipedFuncYesNo());
      formData.append("action_by_admin", String(true));{
        this.state.selectedImageDataBase.forEach((item) => {
          formData.append("images[]", item);
        })
      }
      {this.state.little_Critter_VaccineStatus!=='Select'&&formData.append("health_vaccine_status", this.state.little_Critter_VaccineStatus);}
       formData.append("pet_is_already_fostered", JSON.stringify(this.state.fostered))

{
  this.state.selectVideoDataBase.length > 0 &&
  this.state.selectVideoDataBase.forEach((item) => {
    formData.append("videos[]", item);
  })
}

{
  this.state.pdfData.length > 0 &&
  this.state.pdfData.forEach((item) => {
    formData.append("vaccination_books[]", item);
  })
  return formData
}
    }
    postCreateData = async () => {
      
      const header = {
      "token":await storage.get("auth_token")
      };
      const formData=this.returnFormDataFunc()
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCreatePostId = requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "/bx_block_posts/posts"
      );
  
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };


    handleResponsePreview=(responseJson:any)=>{
      const Data=responseJson?.data?.attributes;
      const images=responseJson?.images
      const video=responseJson?.videos
      const pdf=responseJson?.vaccination_books
      let postDetails={"data": {
        "attributes": {
          "name": Data?.name,
          "location": this.state.location,
          "category": {
            "id":Data?.category?.id,
            "name": Data?.category?.name
          },
          "sub_category": {
            "id":Data?.sub_category?.id,
            "name":   Data?.sub_category?.name
        
          },
          "hook_line": Data?.hook_line,
          "gender": Data?.gender,
          "size":Data?.size,
          "age": Data?.age,
          "formatted_age": {
            "years":Data?.formatted_age?.years,
            "months":Data?.formatted_age?.months,
          },
          "activity_level":Data?.activity_level,
          "life_style":  Data?.life_style,
          "coat_length":  Data?.coat_length,
          "special_needs":  Data?.special_needs,
          "pet_story":   Data?.pet_story,
          "pet_is_already_fostered":  Data?.pet_is_already_fostered,
          "health_vaccine_status":   Data?.health_vaccine_status,
          "temperament": Data?.temperament,
          "good_in_house_with":   Data?.good_in_house_with,
          "fiv_status":  Data?.fiv_status,
          "microchiped": Data?.microchiped,
          "primary_image": Data?.primary_image,
          "rejected_reason":Data?.rejected_reason,
          "disabled_reason":Data?.disabled_reason,
          "status": Data?.status,
          "created_at":  Data?.created_at,
          "updated_at":  Data?.updated_at,
          "published_at":  Data?.published_at,
          "fostered_at":   Data?.fostered_at,
          "adopted_at":   Data?.adopted_at,
          "rejected_at":  Data?.rejected_at,
          "submitted_at":  Data?.submitted_at,
          "archived_at":   Data?.archived_at,
          "disabled_at":   Data?.disabled_at,
          "favourites":  Data?.favourites,
          "vaccination_books":pdf ,
          "images":images,
          "videos": video,
          "animal_of_determination":Data.animal_of_determination,
"medical_conditions":Data.medical_conditions,
"spayed":Data.spayed,
"dewormed": Data.dewormed,
"marketing_strategy": Data.marketing_strategy,
"microchip_number": Data.microchip_number,
"ehrlichia": Data.ehrlichia,
"parasite_medication": Data.parasite_medication,
"skin_diseases": Data.skin_diseases,
"noise_level": Data.noise_level,
"wings_clipped": Data.wings_clipped,
"pbfd_test": Data.pbfd_test,
"cage_size": Data.cage_size,
"habitat_size": Data.habitat_size,
"training":Data?.training
        }
      }
          }  
          storage.set("preview_data",JSON.stringify(postDetails))
          this.openWindow()
        
    }
    openWindow=()=>{
      const url="/previewProductDescription"
    let openWindow = window.open(url,"_blank")
      this.setState({openWindow:openWindow})
    }
    closeWindow=()=>{
      if(this.state.openWindow){
        this.state.openWindow.close();
        this.setState({openWindow:null})
      }

    }
    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
  
      // Customizable Area Start   
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.error) {
        switch (apiRequestCallId) {
          case this.getAnimalApiId: this.setState({animalData:responseJson.data})
          this.setState({loader:false})
          this.handleTypeOfAnimal(responseJson.data)
            break;
            case this.getAnimalLocationApiId: this.setState({animalLocationData:responseJson.data})
              break;
          case this.getBreedApiId: 
          let sortData=responseJson.data.sort((a:{name:string}, b:{name:string}) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
          this.setState({breedData:sortData})
            break;
            case this.getCreatePostId: 
             if(!responseJson?.data?.attributes?.errors){
              storage.remove("preview_button")
              
              storage.set("createPost",true)
              storage.set("Message","HANG TIGHT! Your post has been whisked away to the land of reviews. Check your notifications for updates. Stay tuned!")
              this.props.navigation.navigate("CataloguePage")
            }
            break;
            case this.editPostId:
          this.handleResponseEdit(responseJson)
          setStorageData('toastMessage',responseJson?.data?.attributes?.status)
          break;
          case this.editPUTid:
            if(responseJson?.data?.attributes){
              storage.remove("preview_button")
              storage.set("update_post_boolean",true)
               this.props.navigation.navigate("ProductDescription",{id:this.state.postID,postDetails:this.state.postDetails})
            }
break;       
case this.previewId:
  this.setState({loader:false})
  if(responseJson?.data?.attributes){
  this.handleResponsePreview(responseJson)
  }
break;            
         
case this.previewDeleteId:
  this.handleResponsePreviewDelete(responseJson)
  break;
  case this.getMarketingStrategyApi: this.setState({isShowMarketingStrategy:responseJson?.settings?.is_active})
    break;
        }
      }  
    }
 // Customizable Area End

  // Customizable Area Start
  handleHookLine=(e:any)=>{
    const maxWords = 5;
    
    const wordCount = e.target.value.split(/\s+/).filter((word:string) => word.length > 0).length;
    
    if (wordCount === 0) {
      this.setState({ hookLineError: true, hookLine: e.target.value});
    } else if (wordCount > maxWords) {
      const truncatedInput = e.target.value.split(/\s+/).slice(0, maxWords).join(' ');
      this.setState({ hookLine: truncatedInput, hookLineError: false });
    } else {
      this.setState({ hookLine: e.target.value, hookLineError: false });
    }
    
                             
  }
  handlePetStory=(event:React.ChangeEvent<{value:string}>,errName:string,stateName:string,isImp:boolean)=>{
    this.setState((prevState)=>({ ...prevState,[stateName]: event.target.value, [errName]: false }))
  }

  handleReasonForEditingName = (e: any) => {
    const inputValue = e.target.value.trim(); 
if(inputValue.length==0){
  this.setState({ Reason_for_Editing_Error: true,Reason_for_Editing: e.target.value.substring })
}
else  if ( (inputValue.length > 0 || inputValue === ' ')) {
        this.setState({ Reason_for_Editing: e.target.value, Reason_for_Editing_Error: false });
    } 
}
  handlePetName = (e: any) => {
    const inputValue = e.target.value.trim(); 
    const maxLength = 50;
if(inputValue.length==0){
  this.setState({ petNameError: true,petName: e.target.value.substring(0, maxLength) })
}
else  if ( (inputValue.length > 0 || inputValue === ' ')) {
        this.setState({ petName: e.target.value.substring(0, maxLength), petNameError: false });
    } 
}
handleGoodInHouseWithValue=(event:React.ChangeEvent<{value:string}>)=>{
  const maxWords = 50;
    
    const wordCount = event.target.value.split(/\s+/).filter((word:string) => word.length > 0).length;
    
    if (wordCount === 0) {
      this.setState({ othererror: true, GoodinHousewithValue: event.target.value});
    } else if (wordCount > maxWords) {
      const truncatedInput = event.target.value.split(/\s+/).slice(0, maxWords).join(' ');
      this.setState({ GoodinHousewithValue: truncatedInput, othererror: false });
    } else {
      this.setState({ GoodinHousewithValue: event.target.value, othererror: false });
    }


}
handleSkinDiseases=(e:React.ChangeEvent<{value:string}>)=>{
  const maxWords = 50;
    
    const wordCount = e.target.value.split(/\s+/).filter((word:string) => word.length > 0).length;
    
    if (wordCount === 0) {
      this.setState({ skin_Diseases_Value_Err: true, skin_Diseases_Value: e.target.value});
    } else if (wordCount > maxWords) {
      const truncatedInput = e.target.value.split(/\s+/).slice(0, maxWords).join(' ');
      this.setState({ skin_Diseases_Value: truncatedInput, skin_Diseases_Value_Err: false });
    } else {
      this.setState({ skin_Diseases_Value: e.target.value, skin_Diseases_Value_Err: false });
    }

 
}

  handleErroMessage=(state:any)=>{
return state&& "Please enter a value"
  }
 
  handleResponsePreviewDelete=(responseJson:any)=>{
    if(responseJson.message=="Done"){
      this.closeWindow()
      this.state.pathname=="editpost"?
      this.putPostData()
      :
      this.postCreateData()
    }
  }
handleChangeFunc=(event:React.ChangeEvent<any>,stateName:string,errName:string,heading:string)=>{
if(heading=="Type of Animal"){
  let animalName=this.state.animalData.find((item:any)=>{
    return item.id==event.target.value
   })?.name
  this.setState({typeOfAnimalName:animalName,typeOfAnimal:event.target.value,breed:"Select"},()=>this.handleRemoveTempramentErrFun())
}  
else{
  this.setState((prevState) => ({
    ...prevState,
    [stateName]: event.target.value,
    [errName]: false,
  }));
}
}
handleCloseToolTip=(isOpenToolTipStateName:string)=>{
  this.setState((prevState) => ({
    ...prevState,
    [isOpenToolTipStateName]:false,
  }));
}
hanldeToolTipOnMouseLeave=(isOpenToolTipStateName:string)=>{
  this.setState((prevState) => ({
    ...prevState,
    [isOpenToolTipStateName]:false,
  }));
}
handleOnMouseEnterToolTip=(isOpenToolTipStateName:string)=>{
  this.setState((prevState) => ({
    ...prevState,
    [isOpenToolTipStateName]:true,
  }));
}
handleClickToolTip=(isOpenToolTipStateName:string,isOpenToolTip:boolean|undefined)=>{
  this.setState((prevState) => ({
    ...prevState,
    [isOpenToolTipStateName]:!isOpenToolTip,
  }));
}
handleMultSelectChangeGoodHouse=(event:React.ChangeEvent<{}>,value:Item[])=>{
 this.setState({Good_in_house:value})
}
handleMultSelectChangeTemperament=(event:React.ChangeEvent<{}>,value:Item[])=>{
  this.setState({Temperament:value},()=>{
     if(this.state.Temperament.length>0){
      this.setState({TemperamentError:false})
     }else{
      this.setState({TemperamentError:this.handleTempramentErrFun()})
     }
  })
 }
 handleTypeOfAnimal=(data:Item[])=>{
  let idValue
  for(let i=0;i<data.length-1;i++){
      if(data[i]?.name=='Cats'){
       idValue=data[i]?.id
       
    }
  }
  this.setState({typeOfAnimal:idValue,typeOfAnimalName:"Cats"})
 }
 
 
 hanldeShowField=()=>{
  if(this.state.typeOfAnimalName=='Cats'||this.state.typeOfAnimalName=='Dogs'||this.state.typeOfAnimalName=='Rabbits'){
    return true
  }else{
    return false
  }
 }
 hanldeShowField3=()=>{
  if(this.state.typeOfAnimalName=='Cats'||this.state.typeOfAnimalName=='Dogs'){
    return true
  }else{
    return false
  }
 }
 hanldeShowFieldComm=(number:string)=>{
  if(this.state.typeOfAnimalName==number){
    return true
  }else{
    return false
  }
 }
 hanldeShowComm1=()=>{
  if(this.state.typeOfAnimalName=='Reptiles'||this.state.typeOfAnimalName=='Little Critters'){
    return true
  }else{
    return false
  }
 }
 hanldeShowComm_Little_critters=()=>{
  if(this.state.typeOfAnimalName=='Little Critters'){
    return true
  }else{
    return false
  }
 }
 handleShowField2=()=>{
  if(this.state.typeOfAnimalName=='Cats'||this.state.typeOfAnimalName=='Dogs'||this.state.typeOfAnimalName=='Rabbits'||this.state.typeOfAnimalName=='Birds'){
    return true
  }else{
    return false
  }
 }
 
 handleMicrochipNumber=(event: React.ChangeEvent<{value:string}>)=>{
  let inputValue=event.target.value.trim();
  let maxLength=20;
  if(inputValue.length==0){
    this.setState(
      { microchipped_number_Err: true,microchipped_number: inputValue.substring(0, maxLength) })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({
      microchipped_number: inputValue.substring(0, maxLength), microchipped_number_Err: false })
      if(this.state.microchipped_Confirm_number.length>0&&this.state.microchipped_Confirm_number!==inputValue){
                 this.setState({microchipped_Confirm_number_Err:true})
      }else if(this.state.microchipped_Confirm_number.length>0&&this.state.microchipped_Confirm_number==inputValue){
        this.setState({microchipped_Confirm_number_Err:false})

      }
}
 }
 handleMicrochipNumberConfirm=(event: React.ChangeEvent<{value:string}>)=>{
  let inputValue=event.target.value.trim();
  let maxLength=20;
  this.setState({microchipped_Confirm_number:inputValue.substring(0, maxLength)})
  if(inputValue.substring(0, maxLength)!==this.state.microchipped_number || inputValue.length==0 ){
    this.setState({microchipped_Confirm_number_Err:true})
  }else{
    this.setState({microchipped_Confirm_number_Err:false})
  }
 }
 handleMicroChippedMessErr=(text:string,error:boolean)=>{
  return error&&text
 }
 handleMicroChippedNumberErr=()=>{
  if((this.state.microchipped_number==""||this.state.microchipped_number==null)&&this.state.Microchiped=="Yes"){
    return true
  }else{
    return false
  }
 }
 handleMicroChippedConfirmNumberErr=()=>{
  if((this.state.microchipped_Confirm_number==""||this.state.microchipped_Confirm_number==null||this.state.microchipped_Confirm_number!==this.state.microchipped_number)&&this.state.Microchiped=="Yes"){
    return true
  }else{
    return false
  }
 }
 getMarketingStrategy=async()=>{
  const header = {
    "token": await getStorageData('auth_token')
  };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getMarketingStrategyApi = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.marketing_Strategy_endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType)

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
 }
 handleRabbitVaccineStatus=()=>{
  if(this.state.rabbit_VaccineStatus=="Select"&&this.state.typeOfAnimalName=='Rabbits'){
    return true
  }else{
    return false
  }
 }

 handleMicrochipedFuncYesNo=()=>{
  if(this.state.Microchiped=="Yes") {
    return 'true'
  }else{
    return 'false'
  }
 }
 handelSpayedYesNoFunc=()=>{
  if(this.state.Spayed_Neutered=="Yes") {
    return 'true'
  }else{
    return 'false'
  }
 }
 handleWingClippedYesNo=()=>{
  if(this.state.wings_Clipped=="Yes") {
    return 'true'
  }else{
    return 'false'
  }
 }

 handleDewormedCheck=()=>{
  if(this.state.dewormed=="Select") {
    return ''
  }
  else if(this.state.dewormed=="Yes") {
    return 'true'
  }else{
    return 'false'
  }
 }
 handleYesNoForEdit=(response:boolean)=>{
  if(response) {
    return 'Yes'
  }else {
    return 'No'
  }
 }
 handleYesNoForDewormedEdit=(response:boolean|null|undefined)=>{
  if(response==null||response==undefined){
    return 'Select'
  }
  else if(response) {
    return 'Yes'
  }else {
    return 'No'
  }
 }
 handleCheckGoodInHouseWithIncludeOther=()=>{
   const newArr=this.state.Good_in_house.map((item)=>{
     return item.name
   })
  
  const isOtherPresent=newArr.some((name:string)=>name=="Other")

  if(isOtherPresent) {
    const arrWithoutOther=newArr.filter((item:string)=>item!=="Other")
     arrWithoutOther.push(this.state.GoodinHousewithValue)
     return arrWithoutOther
  }
    
  return newArr
 }
 handleGoodInHouseOtherErr=()=>{
  let isOtherPresent=this.state.Good_in_house.some((item:Item)=>item.name=='Other')
  if(isOtherPresent&&(this.state.GoodinHousewithValue.trim().length==0 ||this.state.GoodinHousewithValue==null||this.state.GoodinHousewithValue==undefined)) return true
  return false
 }
 checkString=()=>{
  if(this.state.Animal_determination?.trim()?.length==0||this.state.Animal_determination==''||this.state.Animal_determination==null||this.state.Animal_determination==undefined) return false
  return true
 }
 checkString2=()=>{
  if(this.state.medical_Conditions.trim().length==0||this.state.medical_Conditions==''||this.state.medical_Conditions==null||this.state.medical_Conditions==undefined) return false
  return true
 }
 handleErrFunc=(stateValue:string,number:string)=>{
    if(stateValue=="Select"&&this.hanldeShowFieldComm(number)) return true
    return false
 }
 handleErrrFunc2=(stateValue:string,funct:Function)=>{
  if(stateValue=="Select"&&funct()) return true
  return false
 }
 returnFormDataFunc=()=>{
    if(this.state.typeOfAnimalName=="Cats") return this.returnFormdataCat()
    if(this.state.typeOfAnimalName=="Dogs") return this.returnFormdataDog()
    if(this.state.typeOfAnimalName=="Rabbits") return this.returnFormdataRabbit()
    if(this.state.typeOfAnimalName=="Birds") return this.returnFormdataBirds()
    if(this.state.typeOfAnimalName=="Reptiles") return this.returnFormdataReptiles()
    if(this.state.typeOfAnimalName=="Little Critters") return this.returnFormdataLittleCritter()
 }
 handleSkinDiseasErrFunc=()=>{
  if((this.state.skin_Diseases_Value.trim().length==0||this.state.skin_Diseases_Value==null||this.state.skin_Diseases_Value==undefined)&&this.hanldeShowFieldComm("Rabbits")&&this.state.skin_Diseases=="Yes") return true
  return false
 }
 handleSkinDiseasValue=()=>{
  if(this.state.skin_Diseases=="Yes"){
    return this.state.skin_Diseases_Value
  }else{
    return this.state.skin_Diseases
  }
 }
  newArrGoodsHouse = (responseArr: string[], missingValues: string[]) => {
    const orginalArr = configJSON.goodHome
    if(responseArr[0]!=='[]'){
    const matchValue = orginalArr.filter((item: Item) => responseArr.includes(item.name))
    if (missingValues?.length > 0) {
      matchValue.push({ id: 4, name: "Other" })
    }

    return matchValue  
    }else{
      return []
    }
    
  }
  newArrTempramentEdit=(resp:string[])=>{
    const orginalArr = configJSON.temperament
    if(resp.length>0){
      const matchValue = orginalArr.filter((item: Item) => resp.includes(item.name))
      return matchValue  
      }else{
        return []
      }
  }
  handleSkinDiseaseValueEdit=(response:string)=>{
    if(response=="No known issues"){
      return {skin_Diseases:response,skin_Diseases_Value:''}
    }else if(response!==null&&response!==undefined&&response!==''){
      return {skin_Diseases:"Yes",skin_Diseases_Value:response}
    }else{
      return {skin_Diseases:'',skin_Diseases_Value:''}
    }
  }
  handleMarketingStrategyCondition=()=>{
    if(this.state.marketing_strategy==''||this.state.marketing_strategy==null||this.state.marketing_strategy==undefined||this.state.marketing_strategy=='Select') return false
    return true
  }
  handleCheckForSubmitFormData=(state:string)=>{
    if(state==''||state==null||state==undefined||state=='Select') return ''
    return state
  }
  handleTempramentErrFun=()=>{
    if(this.state.typeOfAnimalName!=='Little Critters' && this.state.typeOfAnimalName!=='Reptiles'){
      return  this.state.Temperament.length === 0
    }
   else{
    return false
   }
  }
  handleRemoveTempramentErrFun=()=>{
    if(this.state.typeOfAnimalName=='Little Critters' || this.state.typeOfAnimalName=='Reptiles'){
      this.setState({TemperamentError:false})
    }
  }
  // Customizable Area End
}
