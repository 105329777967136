import React from "react";

// Customizable Area Start
import EmailAccountRegistrationController, {
  configJSON,
    Props,
    
} from "./EmailAccountRegistrationController";
import {
    FlipCameraAndroid
  } from "@material-ui/icons";

const baseUrl = require("../../../framework/src/config.js")
import storage from "../../../framework/src/StorageProvider";
import { CardHeading } from "../../../components/src/CardHeading.web";
import { Box, Grid, Typography, styled,Backdrop,Snackbar ,Container, CircularProgress } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import {  rotate, catFoot,dogKissing,coverBanner, arrowLeft, arrowRightLight,dog,cat, arrowLeftLight, arrowRight, arrow } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
import { Button } from "react-native-elements";
import {DiologComponent2} from '../../productdescription/src/ProductDescription.web'
// Customizable Area End

class LandingPageWeb extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
   
  
    
   async componentDidMount() {
      super.componentDidMount()
      await Promise.all([
        this.handleGetAllDogsData(),
        this.handleGetAllCatsData(),
        this.handleGetOurImpactData(),
        this.handleGetAllSponsersData(),
        this.handleGetBannerImageData(),
        this.handleGetAdsData(),
        this.handleGetBlogsApi(),
    ]);
      const user_id= await storage.get("auth_user_id")
      this.setState({user_id:user_id})
        const msg=this.props.navigation?.history?.location?.state?.msg;
        msg=="Thank you for getting in touch. We'll get back to you faster than a lizard running across the sand!" && msg!==null && this.setState({
            contactSnackBar:true,
            contactSnackBarmsg:msg
        })

    }

    renderSponsersList = ()=>{
      return this.state?.sponserData.length ? ( <BannerGrid container style={{background:"rgba(244, 255, 251, 1)",alignItems:"center"}}>
      <Grid item xs={12} sm={12} md={3} style={{justifyContent:"center", alignItems:"center"}}>
<SponserTitle>
Our Sponsors
</SponserTitle>
        </Grid>
        {this.state.sponserData.map((data:any)=>{
            return (
              <Grid item xs={2} sm={2} md={2} key={data.attributes.logo.url}>
              <BoxGap style={{display:"flex",
              }}>  
              <img  src={data.attributes.logo.url} data-test-id="sponser-logo" onClick={()=>{this.handleClick(data.attributes.hyperlink)}} style={{width:"100%",height:"24px",mixBlendMode:"multiply",objectFit:"contain", cursor:"pointer"}} />

                  </BoxGap>
              </Grid>
            )
          })}
        
      </BannerGrid>) : null
    }
    navigateToPets = async(item:string)=>{
      await setStorageData("animal",item);
      this.props.navigation.navigate("SearchAdvance")
    }
    getPetAge = (data:any)=>{
      return data.years < 1 ? `${data.months} Month${this.showMultipleLetter(data.months)}` : `${data.years} Year${this.showMultipleLetter(data.years)}`
    }
    showMultipleLetter = (count:number)=>{
      return count > 1 ? "s" : "";
    }
    handleClick = (link:string) => {
            window.open(link, '_blank'); 
    };
    handleBannerClick = (newTab:boolean, url:string)=>{
      if(newTab&&url?.length>0){
        window.open(url,'_blank')
      }else{
        if(url?.length>0)window.location.href = url;
        
      }
    }

    renderImpactData = ()=>{
      return this.state.impactData.length ? (<OurImpactGap style={{ background:`url(${catFoot}) left `,backgroundColor:"rgba(244, 255, 251, 1)"}}>
      <ImpactHeading>
            Our <span style={{color:"#FF5E5B"}}>{configJSON.impactText}</span>
            </ImpactHeading>
            <ImpactGrid container spacing={3} >
              {this.state.impactData.map((data:any)=>{
                return (
                  <Grid item md={4} sm={6} xs={12} key={data.background} >
              <CardStyled style={{background:data.background}}>
                <CardHeading  descLength={data?.description?.length}>{data.description}</CardHeading>
                <CardSubHeading>{data.title}</CardSubHeading>
              </CardStyled>
            </Grid>
                )
              }) }
              </ImpactGrid>
              </OurImpactGap>) : null
    }
    getHoverColor = (data:boolean)=>{
      return data  ? "#0A2239" :"#FFFFFF"
    }

    renderCatCardData = ()=>{
      return (
        <Container style={{margin:"2rem auto"}}>
                  <SayHiBox style={{display:"flex", justifyContent:"space-between"}}>
                  <ImpactHeading style={{textAlign:"left",marginBottom:"2rem",position:"relative"}}>
                        SAY HI!! <span style={{color:"#FF5E5B"}}>TO OUR CATS</span>  <ArrowImg><img   src={arrow} style={{width:"100%"}} /></ArrowImg>
                        </ImpactHeading>
                        <ViewAllWrapper >
                          <ViewAllSTyle data-test-id="view-all-peets"  onClick={() => {this.navigateToPets("cats")}}>View all</ViewAllSTyle>
                          <PetArrows>
                            {this.state.catsCurrentPage != 0 ? <img  src={arrowLeft} onClick={this.catPrevious} alt="prev" style={{cursor:"pointer"}} /> :  <img src={arrowLeftLight}  />}
                            {((this.state.catsCurrentPage + 1 ) * 4 >= this.state.catsData.length) ? <img  src={arrowRightLight}  /> : 
                              <img src={arrowRight} onClick={this.catNext}  style={{cursor:"pointer"}} />
                            }

                          </PetArrows>
                        </ViewAllWrapper>
                  </SayHiBox>
                    <PetsMainWrapper container spacing={3}>
                    {this.state.catsData.length ? this.state.catsData.slice(this.state.catsCurrentPage*4,this.state.catsCurrentPage*4 + 4 ).map((data:any,i:number)=>{
                      return (
                        this.renderCatDetails(data,i)
                      )
                    }) : null}
                    </PetsMainWrapper>
                    <PetsCarouselWrapper>
                      <Carousel>
                        {this.state.catsData.length ? this.state.catsData.map((data:any,i:number)=>{
                          return (
                            this.renderCatDetails(data,i)
                          )
                        }) : null}
                      </Carousel>
                    </PetsCarouselWrapper>
                  </Container>
      )
    }

    renderCatDetails= (data:any,i:number)=>{
      return (
        <Grid xs={12} md={3} sm={12} item >
                         {!this.state.selectedCat[data.id] ? (<PetsCards data-test-id='petsCards' onClick={()=>{this.handlePetNavigation(data)}}>
                      <AnimalCardGradient></AnimalCardGradient>
                          <PetName>
                          {data.attributes.name.length>=16 ?data.attributes.name.slice(0,16)+'...':data.attributes.name}

                      </PetName>
                          <Box style={{...Allstyle.iconContainer as React.CSSProperties,justifyContent:this.state.user_id!=data?.attributes?.account_id?'space-between':'flex-end'}}>
                    { this.state.user_id!=data?.attributes?.account_id && <span
                      style={Allstyle.cardIcons as React.CSSProperties}  data-test-id='petsCardsSpan' onClick={(event)=>event?.stopPropagation()} >
                      {!data.attributes.favourites ? <img src={rotate} data-test-id='heartIconFav' onClick={()=>this.handleFavorite(data.id,data.attributes.favourites,'cat',i )} /> : (
<svg width="40" data-test-id='heartIconRemoveFav' onClick={()=>this.handleFavorite(data.id,data.attributes.favourites,'cat',i )} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 30.4063C19.7083 30.4063 19.4115 30.3542 19.1094 30.25C18.8073 30.1458 18.5417 29.9792 18.3125 29.75L16.1562 27.7813C13.9479 25.7604 11.9531 23.7552 10.1719 21.7656C8.39062 19.776 7.5 17.5833 7.5 15.1875C7.5 13.2292 8.15625 11.5938 9.46875 10.2813C10.7813 8.96875 12.4167 8.3125 14.375 8.3125C15.4792 8.3125 16.5208 8.54688 17.5 9.01563C18.4792 9.48438 19.3125 10.125 20 10.9375C20.6875 10.125 21.5208 9.48438 22.5 9.01563C23.4792 8.54688 24.5208 8.3125 25.625 8.3125C27.5833 8.3125 29.2188 8.96875 30.5313 10.2813C31.8438 11.5938 32.5 13.2292 32.5 15.1875C32.5 17.5833 31.6146 19.7813 29.8438 21.7813C28.0729 23.7813 26.0625 25.7917 23.8125 27.8125L21.6875 29.75C21.4583 29.9792 21.1927 30.1458 20.8906 30.25C20.5885 30.3542 20.2917 30.4063 20 30.4063Z" fill="#FF5E5B"/>
</svg>) }
                      </span>}
                      <span style={Allstyle.cardIcons as React.CSSProperties} data-test-id='rotateButton'   onClick={(event)=>{event?.stopPropagation();this.handleSelectedCat(parseInt(data.id))}} >
                      <FlipCameraAndroid style={{color:"#fff"}} />
                      </span>
                      </Box>
                      <img src={`${baseUrl.baseURL}${data.attributes.image.url}`} 
                        onClick={()=>{this.handlePetNavigation(data)}}
            data-test-id="rorateNaviagteCat"
                        
                      style={Allstyle.itemImageStyle}  />
                          </PetsCards> ): (<PetsRotatedCard>
                          
                          <img src={cat} height="100" width="100" />
                          <PetsRotateTextTitle>{data.attributes.name}</PetsRotateTextTitle>
                          <PetsRotateTextTitleDescription>{data.attributes.sub_category.name}</PetsRotateTextTitleDescription>
                          <Box style={{display:"flex", justifyContent:"center",gap:"20px", width:"100%"}}>
                          <PetsRotateBreed style={{flex:1, textAlign:"center"}}>{this.getPetAge(data.attributes.formatted_age)}</PetsRotateBreed>
                          <PetsRotateBreed> | </PetsRotateBreed>
                          <PetsRotateBreed style={{flex:1, textAlign:"center"}}>{data.attributes.gender}</PetsRotateBreed>
                          </Box>
                          <button style={{...Allstyle.knowMoreBtn, backgroundColor:"#FF5E5B"}} data-test-id='knowmoreButton'  onClick={()=>{this.handlePetNavigation(data)}}>Know More</button>
                          <PetsRotateIcon onClick={()=>{this.handleSelectedCat(parseInt(data.id))}}>
                            <span style={Allstyle.cardIconsRotate as React.CSSProperties}>
                      <FlipCameraAndroid style={{color:"#fff"}} />
                            </span>
                          </PetsRotateIcon>
                        </PetsRotatedCard>)}
                     
                      
                      </Grid>
      )
    }
    renderDogCardData = ()=>{
      return (
        <Container style={{margin:"2rem auto"}}>
        <SayHiBox style={{display:"flex", justifyContent:"space-between"}}>
        <ImpactHeading style={{textAlign:"left", marginBottom:"2rem", position:"relative"}}>
              MEET OUR <span style={{color:"#FF5E5B"}}> DOGS</span>  <ArrowImg><img src={arrow} style={{width:"100%"}} /></ArrowImg>
              </ImpactHeading>
              <ViewAllWrapper>
                <ViewAllSTyle  data-test-id="view-all-pets" onClick={ () => {this.navigateToPets("dogs")}}>View all</ViewAllSTyle>
                <PetArrows>
                  {this.state.dogsCurrentPage != 0 ? <img src={arrowLeft} onClick={this.dogPrevious} alt="prev" style={{cursor:"pointer"}} /> :  <img src={arrowLeftLight}  />}
                  {((this.state.dogsCurrentPage + 1 ) * 4 >= this.state.dogsData.length) ? <img src={arrowRightLight}  /> : 
                    <img src={arrowRight} onClick={this.dogNext}  style={{cursor:"pointer"}} />
                  }
                </PetArrows>
              </ViewAllWrapper>
        </SayHiBox>
          <PetsMainWrapper container spacing={3}>
          {this.state.dogsData.length ? this.state.dogsData.slice(this.state.dogsCurrentPage*4,this.state.dogsCurrentPage*4 + 4).map((data:any,i:number)=>{
            return (
              this.renderDogDetails(data,i)
            )
          }) : null}
          </PetsMainWrapper>
          <PetsCarouselWrapper >
            <Carousel>
              {this.state.dogsData.length ? this.state.dogsData.map((data:any,i:number)=>{
                return (
                  this.renderDogDetails(data,i)
                )
              }) : null}
            </Carousel>
          </PetsCarouselWrapper>
        </Container>
      )
    }

    renderDogDetails = (data:any,i:number)=>{
      return (
        <Grid xs={12} md={3} sm={12} item >
                {!this.state.selectedDog[data.id] ? (<PetsCards data-test-id='petsCards2'  onClick={()=>{this.handlePetNavigation(data)}}>
                <Box style={{...Allstyle.iconContainer as React.CSSProperties,justifyContent:this.state.user_id!=data?.attributes?.account_id?'space-between':'flex-end'}}>
                { this.state.user_id!=data?.attributes?.account_id &&    <span
            style={Allstyle.cardIcons as React.CSSProperties} data-test-id='petsCardsSpan2' onClick={(event)=>event?.stopPropagation()} >
            {!data.attributes.favourites ? <img src={rotate} data-test-id='heartIconFav' onClick={()=>this.handleFavorite(data.id,data.attributes.favourites,'dog', i )}  /> : (
<svg width="40" data-test-id='heartIconRemoveFav' onClick={()=>this.handleFavorite(data.id,data.attributes.favourites, 'dog', i )} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20 30.4063C19.7083 30.4063 19.4115 30.3542 19.1094 30.25C18.8073 30.1458 18.5417 29.9792 18.3125 29.75L16.1562 27.7813C13.9479 25.7604 11.9531 23.7552 10.1719 21.7656C8.39062 19.776 7.5 17.5833 7.5 15.1875C7.5 13.2292 8.15625 11.5938 9.46875 10.2813C10.7813 8.96875 12.4167 8.3125 14.375 8.3125C15.4792 8.3125 16.5208 8.54688 17.5 9.01563C18.4792 9.48438 19.3125 10.125 20 10.9375C20.6875 10.125 21.5208 9.48438 22.5 9.01563C23.4792 8.54688 24.5208 8.3125 25.625 8.3125C27.5833 8.3125 29.2188 8.96875 30.5313 10.2813C31.8438 11.5938 32.5 13.2292 32.5 15.1875C32.5 17.5833 31.6146 19.7813 29.8438 21.7813C28.0729 23.7813 26.0625 25.7917 23.8125 27.8125L21.6875 29.75C21.4583 29.9792 21.1927 30.1458 20.8906 30.25C20.5885 30.3542 20.2917 30.4063 20 30.4063Z" fill="#FF5E5B"/>
</svg>) }
            </span>
    }
            <span style={Allstyle.cardIcons as React.CSSProperties} data-test-id='rotateButton2' onClick={(event)=>{event?.stopPropagation();this.handleSelectedDog(parseInt(data.id))}}>
            <FlipCameraAndroid style={{color:"#fff"}} />
            </span>
    
            </Box>
            <AnimalCardGradient></AnimalCardGradient>
            <PetName>
            {data.attributes.name.length>=16 ?data.attributes.name.slice(0,16)+'...':data.attributes.name}

            </PetName>
            <img 
            data-test-id="rorateNaviagteDog"
             onClick={()=>{this.handlePetNavigation(data)}}
             src={`${baseUrl.baseURL}${data.attributes.image.url}`} style={Allstyle.itemImageStyle} />
                </PetsCards>) :(
                <PetsRotatedCard>
                
                <img src={dog} height="100" width="100" />
                <PetsRotateTextTitle>{data.attributes.name}</PetsRotateTextTitle>
                <PetsRotateTextTitleDescription>{data.attributes.sub_category.name}</PetsRotateTextTitleDescription>
                <Box style={{display:"flex", justifyContent:"center",gap:"20px", width:"100%"}}>
                <PetsRotateBreed style={{flex:1, textAlign:"center"}}>{this.getPetAge(data.attributes.formatted_age)}</PetsRotateBreed>
                <PetsRotateBreed> | </PetsRotateBreed>
                <PetsRotateBreed style={{flex:1, textAlign:"center"}}>{data.attributes.gender}</PetsRotateBreed>
                </Box>
                <button style={{...Allstyle.knowMoreBtn, backgroundColor:"#FF5E5B"}} onClick={()=>{this.handlePetNavigation(data)}} >Know More</button>
                <PetsRotateIcon onClick={()=>{this.handleSelectedDog(parseInt(data.id))}}>
                  <span style={Allstyle.cardIconsRotate as React.CSSProperties}>
            <FlipCameraAndroid style={{color:"#fff"}} />
                  </span>
                </PetsRotateIcon>
              </PetsRotatedCard>)}
                
              
            
            </Grid>
      )
    }

    renderBlogs = ()=>{
      return (
        this.state.blogsList.length ? <Container style={{margin:"2rem auto"}}>
        <DiscoverHeading>
        Discover how <span style={{color:"#FF5E5B"}}> you </span> can  <span style={{color:"#FF5E5B"}}> make an impact!</span>
        </DiscoverHeading>
<Grid container spacing={2} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          {this.state.blogsList.map((data:any)=>{
            return (
              <Grid  style = {{cursor:'pointer'}}item md={4} key={data.bg} onClick={() => {
                this.props.navigation.navigate("BlogPostManagementMain",{selectionId:data.id})
                
              }}>
                <DiscoverCards>
            <DiscoverContent style={{backgroundColor:`${data.bg}`}}>
              {data.attributes.name}
            </DiscoverContent>
            <DiscoverImage>
              <img   src={data.attributes.image} style={{objectFit:"cover", width:"100%", height:"100%", borderBottomLeftRadius:"16px", borderBottomRightRadius:"16px",}} />
            </DiscoverImage>
          </DiscoverCards>
              </Grid>
            )
          })}
          
        </Grid>
        </Container> : null
      )
    }

    renderAnimalBox = ()=>{
    return  this.state.allAnimalData.map((item:any,index:number)=>{
      const animalInfo = this.state.allAnimalData.find(animal => animal.name === item.name);
      return <ImageBox key={`${item.name}${index}`} data-test-id={`mouseEnter${index}`} onMouseEnter={() => this.handleMouseEnter(index)}
      onMouseLeave={() => this.handleMouseLeave(index)}>
      <img
      
      src={this.state.setHovered[index] ? animalInfo.imageSrcFilled : animalInfo.imageSrc}
      style={{paddingBottom:"10px", width:"100px", height:"100px"}}
      data-test-id="petname" onClick={()=>{
        this.props.navigation.navigate("SearchAdvance",{id:item.id})
      }}/>
      <AnimalTextTitle style={{
      color:this.getHoverColor(this.state.setHovered[index]),
      
      }}>
      {item.name}
      </AnimalTextTitle>
      </ImageBox>
      })
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const BackgroundBox = styled(Box)({
          background:`url(${this.state.genericFosterImage || dogKissing})`,
          backgroundSize:"cover",
          backgroundRepeat:"no-repeat",
          height:"500px",
          display:"flex",
          alignItems:"center",
          "@media (max-width:1100px)": {
            height:"450px"
        },
        "@media (max-width:960px)": {
          height:"400px"
      },
      "@media (max-width:800px)": {
        backgroundPosition:'right',
        height:"300px"
      },
      
        })
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={{overflow:"hidden"}}>
            {this.state.contactSnackBar && <DiologComponent2 data-test-id='DiologComponent2'
              success={this.state.contactSnackBar} handleClose={this.handleClosediologComponent2}
              firstHeading={"SUBMITTED"}
              secondHeading={"Thank you for getting in touch. We'll get back to you faster than a lizard running across the sand!"
              }
              secondbuttonname={"OKAY"}
              mainFunction={this.handleClosediologComponent2}
            />}
                <Backdrop
                    style={{ zIndex: 700 }}
                    open={this.state.isSnackbarOpenForGenFosRequest}
                    data-test-id='backdrop2'
                ><SnackBarStylIng
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.isSnackbarOpenForGenFosRequest}
                    style={{ zIndex: 1000 }}
                    autoHideDuration={3000}
                    data-test-id='snackBarForRequestSuccess'
                    onClose={()=>this.setState({isSnackbarOpenForGenFosRequest:false,isSnackbarOpenForGenFosRequestMessage:''})}
                >
                        <Typography style={Allstyle.popupstyle as React.CSSProperties}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
                            </svg>
                            {this.state.isSnackbarOpenForGenFosRequestMessage}
                        </Typography>
                    </SnackBarStylIng>
                </Backdrop>
              
                <HomeContainer>
                  <CustomContainer disableGutters  >
                   <Grid container   className="CustomContainerChild">
                    
                    <Grid item  className="CustomContainerChild">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      onMouseEnter={() => {this.handleBannerHoverIn()}}
                      onMouseLeave={() => {this.handleBannerHoverOut()}}
                      overflow="hidden"
                      data-test-id="ad-box"
                      style={{width:'100%', margin:0,padding:0}}
                    >
                       <div style={{position:'relative', width:'100%'}}>
                      {this.state.adsList.length ? this.state.adsList.map((image, index) => (
                    <CustomImg
                      key={index}
                      src={image.attributes.image} 
                      alt={`Carousel Image ${index + 1}`}
                      onLoad={this.handleImageLoad}
                      data-test-id="bannerimg" onClick={()=>{this.handleBannerClick(image.attributes.open_in_new_browser,image.attributes.web_url)}}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        display: this.state.currentIndex === index ? 'block' : 'none',
                        filter: this.state.isImageLoading ? 'blur(10px)' : 'none',
                      
                      }}
                    />  
                  ))
                  : null}
                   { <><LeftControl onClick={this.moveLeft} data-test-id="left-move">
                          <svg fill="#FFFFFF" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

                            <g data-name="Layer 2">

                              <g data-name="arrow-ios-back">

                                <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0" />

                                <path d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z" />

                              </g>

                            </g>

                          </svg>
                        </LeftControl><RightControl onClick={this.moveRight} data-test-id="right-move">
                            <svg fill="#FFFFFF" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

                              <g data-name="Layer 2">

                                <g data-name="arrow-ios-forward">

                                  <rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0" />

                                  <path d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z" />

                                </g>

                              </g>

                            </svg>
                          </RightControl></>}
                  </div>
                  
                </Box>
                  
                    </Grid>
                   </Grid>
                  </CustomContainer>
                 {this.state.is_visible_sponsor_section&&this.renderSponsersList()}
                
                  <FindOurGap style={{background: 'linear-gradient(180deg, #33475A 0%, rgba(10, 34, 57, 0.90) 100%)',}}>
                  <ImpactHeadingFindOur >
                         <span style={{color:"#FF5E5B"}}>Find your </span> 
                         <span style={{color:"#FFFFFF"}}>  new companion</span>
                        </ImpactHeadingFindOur>
                        <SubHeadingText dangerouslySetInnerHTML={{ __html: this.state.briefText }} />
                         
                        <ImpactGridAnimals container style={{justifyContent:"center"}} spacing={2}>
                          <Grid item xs={12} >
                          <AllAnimalWrapper>
                        {this.renderAnimalBox()}
</AllAnimalWrapper>
<AllAnimalCarousel>
</AllAnimalCarousel>
</Grid>
                        </ImpactGridAnimals>
                  </FindOurGap>
                  {this.renderCatCardData()}
                 {this.renderDogCardData()}
                  <BackgroundBox>
                    <BoxYellow>
                    <YelloCardHeading>
                    Ready to Welcome a New Friend into <span style={{color:"#FF5E5B"}}> Your Home? </span>
                    </YelloCardHeading>
                    <YellowBoxContent>
                      {this.state.genericPosterDescription}
                    </YellowBoxContent>
                    <KnowmoreButton style={{ backgroundColor:"#074F57"}} onClick={this.navigateToRequestManagment}> Know More </KnowmoreButton>
                    </BoxYellow>
                  </BackgroundBox>
                  
                    {this.renderBlogs()}
                    {this.renderImpactData()}
                </HomeContainer>
  
 
            </Box>
            // Customizable Area End
        );
    }
}
export default LandingPageWeb;

// Customizable Area Start
const ImageBox=styled(Box)({
  width:"175px",height:"180px",
  cursor:"pointer",
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems:"center",
"&:hover":{
background:"whitesmoke",
},


   borderRadius:"16px",
   "@media (max-width:959px)":{
    margin:"auto"
   },
   "@media (max-width:412px)":{
    width:'unset',
    height:'unset'
   }
   
})

const ControlButton = styled(Box) ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  border: 'none',
  padding: '10px',
  cursor: 'pointer',
  zIndex: 1000,
  textAlign:"center",
  borderRadius:"50%",
  "&:hover":{
    display: "block"
  }
})

const LeftControl = styled(ControlButton)({
  left: 0,
});

const RightControl = styled(ControlButton)({
  right: 0,
});

const CardStyled = styled(Box)({
  padding:"1rem",
  boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  borderRadius:"8px",
  height:'157px',
  display:"flex", 
  alignItems:"center", 
  justifyContent:"center",
  flexDirection:"column",
  margin:"auto",
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100%', 
})
const HomeContainer = styled(Box)({
  minHeight: "calc(100vh - 232px)",
})
const SnackBarStylIng = styled(Snackbar)({
    "&.MuiSnackbar-anchorOriginTopCenter":{
      top:"113px",
    }
    })
    const BannerGrid= styled(Grid)({
      maxHeight:"72px",
      alignItems:"center",
      justifyContent:"center",
      padding: "26.22px 135px 26.78px 124px",
      '@media (max-width:1398px) and (min-width:960px)': {
          padding: "26.22px 40px  26.78px 40px;"
      },
      '@media (max-width:960px) and (min-width:800px)': {
          gap:"12px",
          maxHeight:"unset",
          padding: "26.22px 40px  26.78px 40px"
      },
      '@media (max-width:800px )': {
          maxHeight:"unset",

          gap:"12px",
          padding: "10.22px 10px  10.78px 10px"
      },
    
  
  })
  const BoxGap=styled(Box)({
    gap:"12px",
    '@media (max-width:800px )': {
    gap:"2px",

    }
  })
  const AllAnimalWrapper = styled(Box)({
    display:"flex",
    gap:"23px",
    flexWrap:"wrap",
    justifyContent:"center",
  })
  export const AllAnimalCarousel = styled(Box)({
    "@media (min-width:960px)":{
      display:"none"
       
     }
  })
  const ImpactGrid = styled(Grid)({
    alignItems:"center",
      justifyContent:"center",
      flexWrap:"wrap",
      padding: "19px 135px 26.78px 135px",
      '@media (max-width:1398px) and (min-width:960px)': {
          padding: "26.22px 40px  26.78px 40px;"
      },
      '@media (max-width:960px) and (min-width:800px)': {
          gap:"12px",
          maxHeight:"unset",
          padding: "26.22px 40px  26.78px 40px"
      },
      '@media (max-width:800px )': {
          maxHeight:"unset",
          gap:"unset",
          padding: "0px 20px  10.78px 20px"
      },
  })
  const ImpactGridAnimals = styled(Grid)({
    justifyContent:"center",
    flexWrap:"wrap",
    padding: "19px 135px 26.78px 135px",
    alignItems:"center",
      '@media (max-width:1398px) and (min-width:960px)': {
          padding: "26.22px 40px  26.78px 40px;"
      },
      '@media (max-width:960px) and (min-width:800px)': {
        maxHeight:"unset",
          gap:"12px",
      },
      '@media (max-width:800px )': {
        gap:"unset",
        padding: "0px 20px  10.78px 20px",
          maxHeight:"unset",
      },
  })
  const ImpactHeadingFindOur = styled(Typography)({
    fontSize:"60px",
    fontWeight:700,
    fontFamily:"Cardenio Modern",
    lineHeight:"60px",
    letterSpacing:"0.72px",
    textAlign:"center",
    paddingTop:"4rem",
   color:"#074F57",
   textTransform:"capitalize",
   marginBottom:"1rem",
   '@media (max-width:1200px )': {
    fontSize:"40px"
},
'@media (max-width:900px )': {
  fontSize:"30px",
  lineHeight:"50px",
  paddingTop:'12px'
},
'@media (max-width:650px )': {
  fontSize:"27px",
  lineHeight:"40px",
},
  })

  const FindOurGap=styled(Box)({
    minHeight:"450px",
    padding:"3rem auto",
    '@media (max-width:959px )': {
      minHeight:"320px",
      padding:'1rem auto',
      marginTop:"12px",

    },

  })
  const ImpactHeading = styled(Typography)({
    fontSize:"60px",
    fontWeight:700,
    fontFamily:"Cardenio Modern",
    lineHeight:"60px",
    letterSpacing:"0.72px",
    textAlign:"center",
   color:"#074F57",
   textTransform:"capitalize",
   marginBottom:"1rem",
   '@media (max-width:1200px )': {
    fontSize:"40px"
},
'@media (max-width:900px )': {
  fontSize:"30px",
  lineHeight:"50px",
},
'@media (max-width:650px )': {
  fontSize:"27px",
  lineHeight:"40px",
},
  })
  const SayHiBox =styled(Box)({
    '@media (max-width:900px )': {
      marginBottom:'2rem'
    },
    '@media (max-width:550px )': {
      marginBottom:'unset'
    },
  })
  const ViewAllSTyle=styled(Typography)({
    color:"#03B5AA",fontFamily:"Lato", fontSize:"20px", fontWeight:700,lineHeight:"28px" ,cursor:"pointer",
    '@media (max-width:650px )': {
      fontSize:"14px",
      lineHeight:"40px",
    },

  })
  const OurImpactGap=styled(Box)({
    paddingTop:'54px',
    position: 'relative',
    zIndex: 1,

    '@media (max-width:800px )': {
      marginTop:"unset",
      paddingTop:'24px'
  },
  })
  const SubHeadingText = styled(Typography)({
    fontFamily:"Lato",
    fontWeight:400,
    lineHeight:"19.2px",
    color:"#ffffff",
    textAlign:"center",
    '@media (max-width:800px )': {
      fontSize:"14px"
  },
  '@media (max-width:700px )': {
    fontSize:"13px",
    lineHeight:"16px",
  },
  '@media (max-width:550px )': {
    fontSize:"12px",
    lineHeight:"14px",
  },})
  const ArrowImg = styled(Typography)({
    display:"inline",
    position:"absolute",
    transform:"translateY(50%)",
    width:"26px",
    '@media (max-width:550px )': {
      width:"14px"
    }
  })
  const DiscoverHeading = styled(ImpactHeading)({
    color:"#03B5AA"
  })
  const DiscoverCards = styled(Box)({
    height:"658px",
    borderRadius:"16px",
    '@media (max-width:1000px )': {
      height:"400px",

      },
      
  })
  const DiscoverContent = styled(Box)({
    height:"30%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    borderTopLeftRadius:"16px",
    borderTopRightRadius:"16px",
    fontFamily:"Cardenio Modern",
    fontSize:"44px",
    fontWeight:700,
    letterSpacing:"0.88px",
    lineHeight:"32px",
    color:"#0A2239",
    '@media (max-width:1300px )': {
  
        fontSize:"34px",},
    '@media (max-width:1000px )': {
    height:"15%",

      fontSize:"22px",
      lineHeight:"16px",
    },
    '@media (max-width:700px )': {
      height:"15%",
  
        fontSize:"18px",
        lineHeight:"16px",
      },

  })
  const DiscoverImage = styled(Box)({
    height:"70%",
    borderBottomLeftRadius:"16px",
    borderBottomRightRadius:"16px",
    '@media (max-width:1000px )': {
      height:"85%",
  
      },
  })
  const PetsCards = styled(Box)({
    cursor: 'pointer',
    height:"370px",
    maxWidth:"275px",
    borderRadius:"16px",
    position:"relative",
    margin:"auto",
    boxShadow:" 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  })
  const PetsRotatedCard = styled(Box)({
    backgroundColor:"#0A2239",
    margin:"auto",
    display:"flex",
    justifyContent:"center",
    maxWidth:"275px",
    height:"370px",
    position:"relative",
    borderRadius:"16px",
    flexDirection:"column",
    boxShadow:" 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    alignItems:"center",
  })
  const AnimalTextTitle = styled(Typography)({
    textAlign:"center",
      paddingBottom:"16px",
      fontFamily:"Cardenio Modern",
      fontWeight:"bolder",
      fontSize:"20px",
      lineHeight:"28px",
      letterSpacing:"0.4px",
    '@media (max-width:700px )': {
      fontSize:"14px",
      lineHeight:"14px",
    },})
  const PetsRotateTextTitle = styled(Typography)({
    fontSize:"24px",
    fontFamily:"Cardenio Modern",
    fontWeight:700,
    lineHeight:"32px",
    letterSpacing:"0.48px",
    textAlign:"center",
whiteSpace: 'nowrap',
    width: '90%', 
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color:"#FFFFFF",
    marginTop:"16px",
    '@media (max-width:700px )': {
      fontSize:"18px",
    },})

  const PetsRotateTextTitleDescription = styled(Typography)({
    fontFamily:"Lato",
    fontSize:"18px",
    fontWeight:400,
    lineHeight:"26px",
    wordBreak:'break-word',
    width:'90%',
    letterSpacing:"0.48px",
    textAlign:"center",
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    color:"#FFFFFF",
    marginTop:"16px",
    '@media (max-width:700px )': {
      fontSize:"14px",
    }
  })
  const PetsRotateText = styled(Typography)({
    fontSize:"24px",
    fontFamily:"Cardenio Modern",
    fontWeight:700,
    lineHeight:"32px",
    letterSpacing:"0.48px",
    textAlign:"center",
    color:"#FFFFFF",
    marginTop:"16px"
  })
  const PetsRotateBreed = styled(PetsRotateText)({
    fontFamily:"Lato",
    fontSize:"18px",
    fontWeight:400,
    lineHeight:"26px",
    "@media (max-width:700px)":{
      fontSize:"12px",
    
    }
  })
  const PetName = styled(Typography)({
    fontFamily:"Cardenio Modern",
    fontSize:"20px",
    fontWeight:700,
    lineHeight:"28px",
    letterSpacing:"1px",
    position:"absolute",
    bottom:0,
    color:"#ffffff",
    left:"50%",
    transform:`translateX(-50%)`,
    width:"100%",
    height:"140px",
    borderRadius: "20px",
display:"flex",
justifyContent:"center",
alignItems:"end",
paddingBottom:"24px",
zIndex:2,
"@media (max-width:700px)":{
  fontSize:"14px",

}
  })
  const AnimalCardGradient = styled(Box)({
    position:"absolute",
    bottom:0,
    left:"50%",
    transform:`translateX(-50%)`,
    width:"100%",
    height:"140px",
    borderRadius: "20px",
opacity: 0.7,
background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 44.4%, #000 100%)",

  })
  const PetsRotateIcon = styled(Box)({
    position:"absolute",
    top:16,
    right:16

  })
  
  const BannerArrow = styled(Box)({
    position:"absolute",
    transform:"translateY(50%)"
  })

  const PetsCarouselWrapper = styled(Box)({
    display:"none",
    "@media (max-width:959px)":{
      display:"block",
    }
  })
  const PetsMainWrapper = styled(Grid)({
    "@media (max-width:959px)":{
      display:"none"
    }
  })
  const ViewAllWrapper = styled(Box)({
    display:"flex",
    justifyContent:"space-between", 
    alignItems:"center", 
    width:"226px",
    marginBottom:"2rem",
    "@media (max-width:959px)":{
      justifyContent:"end",
    width:"75px",

    }

  })
  const PetArrows = styled(Box)({
    display:"flex",
    gap:"32px",
    "@media (max-width:959px)":{
      display:"none"
    }
  })
  const YelloCardHeading = styled(ImpactHeading)({
    textAlign:"justify",
    fontSize:"48px",
    lineHeight:"64.8px",
    fontWeight:700,
    color:"#0A2239",
    '@media (max-width:1200px )': {
      fontSize:"40px"
  },
  '@media (max-width:900px )': {
    fontSize:"24px",
    lineHeight:"unset",
    textAlign:"center",
  },
  '@media (max-width:650px )': {
    fontSize:"27px",
    lineHeight:"unset",
    textAlign:"center",
  },

  })
  const BoxYellow = styled(Box)({
    backgroundColor:"#E8D658",
    width:"450px",
    padding:"63px 72px 62px 60px",
    marginLeft: "2rem",
    borderRadius:"16px",
    "@media (max-width:1100px)": {
      padding:"43px 52px 42px 40px",
  },
  "@media (max-width:960px)": {
    padding:"1rem",
    maxHeight:"400px"
},"@media (max-width:768px)": {
  width:"300px",
  padding:"12px",


  minHeight:"230px"
},"@media (max-width:600px)": {
  width:"200px",
  marginLeft: "12px",
  minHeight:"200px",
  padding:"12px",

},
"@media (max-width:500px)": {
  width:"200px",
  marginLeft: "12px",
  minHeight:"200px",
  padding:"12px"
,
  margin:"16px"
},
"@media (min-width:1441px)": {
  position:'absolute',
 left:'5%'
},
"@media (min-width:1641px)": {
  position:'absolute',
 left:'10%'
},
"@media (min-width:1790px)": {
  position:'absolute',
 left:'15%'
},
"@media (min-width:2000px)": {
  position:'absolute',
 left:'18%'
},
"@media (min-width:2300px)": {
  position:'absolute',
 left:'23%'
},
"@media (min-width:2560px)": {
  position:'absolute',
 left:'25%'
},
  })
  const YellowBoxContent = styled(Typography)({
    fontFamily:"Lato",
    fontSize:"18px",
    fontWeight:400,
    lineHeight:"30px",
    color:"#074F57",
    "@media (max-width:768px)": {
    fontSize:"16px",

      lineHeight:"25px"
  },"@media (max-width:600px)": {
    fontSize:"12px",
    lineHeight:"20px"
  },
  })
  

    const TypographyPop=styled(Typography)({
        // minWidth:"730px",
     
        "@media (max-width:800px)": {
            minWidth: "500px",
        },
        "@media (max-width:612px)": {
            minWidth: "400px",
        },
        "@media (max-width:440px)": {
            minWidth: "320px",
        },

    })

  const CardSubHeading = styled(Typography)({
    fontSize:"30px",
    fontWeight:700,
    wordBreak:'break-all',
    textAlign:'center',
    fontFamily:"Lato",
    lineHeight:"35.37px",
    letterSpacing:"0.72px",
    color:"#0A2239",
    "@media(max-width:1000px)":{
      fontSize:"20px"

    },
    "@media(max-width:800px)":{
      fontSize:"18px"
    },
   
  })

    const SponserTitle = styled(Typography)({
      color:"#074F57",
fontFamily: "Lato",
fontSize: "16px",
fontWeight: 400,
"@media (max-width:960px)": {
  textAlign:"center"
},
    })
    const CustomContainer=styled(Container)({
      width: '100%',
      overflow:'hidden',
      minWidth:'100%',
      "& .CustomContainerChild":{
        width: '100%',
        overflow:'hidden',
      }
    })
    const CustomImg=styled('img')({
      objectFit:"contain",
      cursor:"pointer",
      minWidth:"100%",
      minHeight:"20vh",
     "@media (min-width: 768px)": {
      // height: '100%'
     },
     "@media (max-width: 767px)":{
      // height:"100%"
     }
    })
const Allstyle={ boldStyle: {
    color: "#03B5AA",
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight:"24px"
  },
  popupstyle: {
    display: "flex",
    color: "#0F172A",
    gap:"8px",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    fontSize: "16px",
  textAlign:'left',

    fontWeight: 400,
    padding:"9px 16px 9px 8px ",
    fontFamily: "Inter",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
  
    lineHeight: "24px",
  },
  headingFont : {
    fontSize:"52px",
   fontWeight:"bolder",
   fontFamily:"Cardenio Modern",
   lineHeight:"75.37px",
   letterSpacing:"0.72px",
   textAlign:"Justify",
  color:"#074F57",
  textTransform:"capitalize",
  margin:"0 2rem 2rem 2rem"
  },sponser:{
    color:"#074F57",
fontFamily: "Lato",
fontSize: "16px",
fontWeight: 400,
},
  subHeading:{
    fontSize:"28px",
   fontWeight:700,
   fontFamily:"Cardenio Modern",
   lineHeight:"65.37px",
   letterSpacing:"0.72px",
  color:"#FF5E5B"
  },
  itemStyle: {
    marginBottom: "24px",
    boxShadow:
      " 0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    borderRadius: "8px",
    height:"379px",
    position:"relative",
    cursor:"pointer",
  },
  itemImageStyle: {
    width:"100%",
    height:"100%",
    objectFit: "cover" as "cover",
    borderRadius: "16px",
  },
  cardIconsRotate:{
    borderRadius: "10px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    alignItems: "center",
    background: "rgba(10, 34, 57, 0.40)",
    display:"flex",
    justifyContent: "center",
    padding:'5px'
  },
  cardIcons:{
    padding: "5px",
    borderRadius: "10px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    alignItems: "center",
    background: "rgba(10, 34, 57, 0.40)",
    display:"flex",
    justifyContent: "center",
    marginLeft:"16px",
    marginRight:"16px"
  },
  iconContainer:{
    display:"flex",
    // justifyContent:"space-between",
    position:"absolute",
    top:"16",
    width:"100%",

  },
  logoStyle:{
      color:"#074F57",
fontFamily: "Lato",
fontSize: "18px",
fontWeight: 700,
lineHeight: "26px",
  },
  knowMoreBtn :{
    padding:"10px 32px",
    borderRadius:"8px",
    outline:"none",
    color:"#FFFFFF",
    marginTop:"1rem",
    border:"none",
    cursor:"pointer",
    fontSize:"16px",
    height:"44px",
    display:"inline-flex",
    alignItems:"center",
    fontFamily:"Lato",
    fontWeight:700,
    lineHeight:"24px",
    gap:"8px",
    flexShrink:0
    
  }

}
const KnowmoreButton=styled('button')({
  padding:"10px 32px",
    borderRadius:"8px",
    outline:"none",
    color:"#FFFFFF",
    marginTop:"1rem",
    border:"none",
    cursor:"pointer",
    fontSize:"16px",
    height:"44px",
    display:"inline-flex",
    alignItems:"center",
    fontFamily:"Lato",
    fontWeight:700,
    lineHeight:"24px",
    gap:"8px",
    flexShrink:0,
    "@media(max-width:600px)":{
      padding:"5px 16x",
    fontSize:"14px",
    height:"34px",
    marginTop:"12px",


    },

})



// Customizable Area End
