import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config.js");
import { checkBoxTrue, checkBoxFalse } from "../../../blocks/email-account-registration/src/assets";
import { LittleCritters, LittleCrittersFilled, Reptiles, ReptilesFilled, birds, birdsFilled, cat, catFilled, dog, dogFilled, rabbit, rabbitFilled } from "./assets";
import React from "react";
import { ArrowDown, ArrowUp } from "../../../blocks/catalogue/src/assets";
import storage from "../../../framework/src/StorageProvider";

export interface ICommunityObject {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    body: string;
    account_id: number;
    created_at: string;
    updated_at: string;
    model_name: string;
    account: {
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        email: string;
        type: string;
        created_at: string;
        updated_at: string;
        device_id: null;
        unique_auth_id: string;
        full_name: string;
        user_name: string;
        dob: null;
        country: null;
        platform: null;
        style: string;
        image: string;
      };
    };
  };
}
interface YourComponentState {
  // Define your state properties here
  categoryTypeOptionData: number[];
  animalTypeOptionData: number[];
 
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  toggle: boolean;
  isLoading: boolean;
  image:any
  token: string;
  postFlatlistItemSelect: ICommunityObject[];
  refreshing: boolean;
  allAnimalData:any[]
  serachTopic:string;
  animaltypeMenuRefCommunity:any;
  animalTypeOptionData:any[];
  animaltypeMenuSelect:any;
  categorytypeMenuRefCommunity:any;
   categoryTypeOptionData:any[];
   categorytypeMenuSelect:any;
   checkedByme:boolean;
   sortByMenuItemRef:any
   sortBymenuItem:any
   sortBymenu: any,
   total_page:number
   currentPage:number
   getAnimalData:any[]
   getCatergoryData:any[]
   getAllTopicData:any[]
total_topic:number
setHovered:any
loading:boolean


titleTopic:string;
contentTopic:string;
categoryIdTopic: string;
animalIdTopic:string;
animalTopicIdOther:string;
titleTopicError:boolean;
contentTopicError:boolean;
categoryIdTopicError:boolean;
animalIdTopicError:boolean;
animalTopicIdOtherError:boolean;
createTopic:boolean
infoMessage:string

topicId:number|null
pathName:string;
user_id:number|null
animalData:any
chatRoomBannerImage :  string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCreatedPostApiCallId: string = "";
getCategoriesId:string="";
getAnimalId:string="";
getAllTopicId:string="";
getSpecificTopiId:string=""
createTopicId:string="";
putEditTopic:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      toggle: false,
      currentPage:1,
      total_page:10,
      topicId:null,
      checkedByme:false,
      isLoading: true,
      animaltypeMenuSelect:false,
      animalTypeOptionData:[0],
      setHovered:"",
      image:"",
      user_id:null,
      pathName:"",
      loading:false,
      animaltypeMenuRefCommunity:React.createRef(),
      categorytypeMenuRefCommunity:React.createRef(),
      sortByMenuItemRef:React.createRef(),
      sortBymenuItem:null,
      sortBymenu:0,
      categoryTypeOptionData:[0],
      categorytypeMenuSelect:"",
      serachTopic:"",
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODc2LCJleHAiOjE3MTIzMDg2MTUsInRva2VuX3R5cGUiOiJsb2dpbiJ9.ygJHyd7yOT7k-FBLUezoBTmEaCYnSzVFmXgYkdaMpKJH0oUF8B5kuXAzYQYXdP2rat4YV4XDs9LihTiqKwRDmg",
      refreshing: false,
      postFlatlistItemSelect: [],
      allAnimalData:[
        {name:"Dogs",fill:true,imageSrc:dog,imageSrcFilled:dogFilled},
        {name:"Cats",fill:false,imageSrc:cat,imageSrcFilled:catFilled},
        {name:"Rabbits",fill:false,imageSrc:rabbit,imageSrcFilled:rabbitFilled},
        {name:"Birds",fill:false,imageSrc:birds,imageSrcFilled:birdsFilled},
        {name:"Reptiles",fill:false,imageSrc:Reptiles,imageSrcFilled:ReptilesFilled},
        {name:"Little Critters",fill:false,imageSrc:LittleCritters,imageSrcFilled:LittleCrittersFilled},
      ],
      getAnimalData:[],
      getCatergoryData:[],
      getAllTopicData:[],
total_topic:1,
titleTopic:"",
contentTopic:"",
categoryIdTopic:"Select category",
animalIdTopic:"Select animal type",
animalTopicIdOther:"",
titleTopicError:false,
contentTopicError:false,
categoryIdTopicError:false,
animalIdTopicError:false,
animalTopicIdOtherError:false,
createTopic:false,
infoMessage:"",
animalData:[],
chatRoomBannerImage : ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const add= [{
      id: 0,
      name: "All",
    },]
    const OtherData= [{
      id: null,
      name: "Other",
    },]
    if (responseJson && !responseJson.error) {
      switch (apiRequestCallId) {
        case this.createTopicId:
         this.handlecreateTopicId(responseJson)
          break;
          case this.putEditTopic:
            storage.set("createTopic",true)
            this.props.navigation.navigate("CreateComment",{id:this.state.topicId,infomsg:"TOPIC UPDATED! Your blog topic has been successfully edited."})
            break;
        case this.getAnimalId:
          const petName= this.props.navigation?.history?.location?.state?.petName;
          if(petName&&petName!==""){
           this.setState({animalTypeOptionData:[petName]})
          }
          this.setState({getAnimalData: [...add, ...responseJson.data,...OtherData],animalData:responseJson.data})
          break;
          case this.getCategoriesId:
          this.setState({getCatergoryData: [...add, ...responseJson.data]})
            break;
            case this.getAllTopicId:
this.setState({categorytypeMenuSelect :false,animaltypeMenuSelect:false, sortBymenuItem :false})
          
          this.setState({getAllTopicData:responseJson.data,isLoading:false})
          this.setState({
            total_topic:responseJson.meta.total_topics,
            total_page:responseJson.meta.last_page
          })
              break;
              case this.getSpecificTopiId:
                if(parseInt(responseJson?.data?.attributes?.account?.id)!==this.state.user_id){
                  this.props.navigation.navigate("PetootiCommunityFormTopic")
                }
                const {name,content,animal_type,custom_animal_type,community_category}=responseJson.data.attributes
                this.setState({titleTopic:name,
                  isLoading:false,
                  contentTopic:content,
                  animalTopicIdOther:custom_animal_type,
                  animalIdTopic:animal_type?.name ?animal_type?.name:"Other" ,
                  categoryIdTopic:community_category?.name})
                break;
      }
  }
    
    // Customizable Area End
  }
// Customizable Area Start
 handleChange=(event:any,page:number)=>{
this.setState({currentPage:page})
window.scrollTo(0,0);
}
handlecreateTopicId=(responseJson:any)=>{
  if(responseJson?.data?.attributes){
    
    storage.set("createTopic",true);
    this.props.navigation.navigate("CreateComment",{id:responseJson?.data?.id,createTopic:true,infomsg:"TOPIC CREATED! You've successfully created a new blog topic!"})
  }
}
handleMouseEnter = (index:number) => {
  this.setState((prevState:any) => ({
    setHovered: {
      ...prevState.setHovered,
      [index]: true,
    },
  }));
};
handlePageEmpty=()=>{
  const vaild= Array.isArray(this.state.animalTypeOptionData) &&
    this.state.animalTypeOptionData.includes(0) &&
    Array.isArray(this.state.categoryTypeOptionData) &&
    this.state.categoryTypeOptionData.includes(0) &&
    this.state.sortBymenu==0&&!this.state.checkedByme
    &&this.state.serachTopic=="" &&this.state.getAllTopicData.length==0;
  return vaild
  }
  handleCheckedMe=()=>{
    return this.state.checkedByme ? checkBoxTrue : checkBoxFalse
  }

handleMouseLeave = (index:number) => {
  this.setState((prevState:any) => ({
    setHovered: {
      ...prevState.setHovered,
      [index]: false,
    },
  }));
};
handleCloseCategory=(e:any)=>{
  if(e.target.id!='Category'&&e.target.classList[0]!='MuiButton-label'){

  this.setState({categorytypeMenuSelect:false})
  }
}
handleCloseAnimal=(e:any)=>{
  if(e.target.id!='Animal'&&e.target.classList[0]!='MuiButton-label'){
  this.setState({animaltypeMenuSelect:false})
  }
}
componentDidUpdate(prevProps: any, prevState: any) {
   if(this.state.serachTopic!==prevState.serachTopic
    ||this.state.animalTypeOptionData!==prevState.animalTypeOptionData
    ||this.state.categoryTypeOptionData!==prevState.categoryTypeOptionData
    ||this.state.checkedByme!==prevState.checkedByme
    ||this.state.sortBymenu!==prevState.sortBymenu
    ||this.state.currentPage!==prevState.currentPage
    ){
this.getAllTopicData()
    }
  }
  handleBorder=(index:number)=>{
    return index== this.state.getAllTopicData.length-1?"unset":"1px solid #E2E8F0"
  }

  clearAllOptions = () => {
    this.setState({
      animalTypeOptionData: [0],
      categoryTypeOptionData: [0],
    });
  };
  handlePlacement=(placement:string)=>{
  return   placement === 'bottom' ? 'center top' : 'center bottom' 
  }
handleConditionOfClear=()=>{
  return ((this.state.animalTypeOptionData.length > 0 && !this.state.animalTypeOptionData.includes(0)) ||
  (this.state.categoryTypeOptionData.length > 0 && !this.state.categoryTypeOptionData.includes(0)) )
 
}
clearOption = (optionName: string, indexToRemove: number |null) => {
  const updatedOptions = this.state[optionName as keyof YourComponentState].filter((index: number) => index !== indexToRemove);
  if (updatedOptions.length === 0) {
    updatedOptions.push(0);
  }

  this.setState({ [optionName]: updatedOptions } as Pick<YourComponentState, keyof YourComponentState>);
};
handleFisrtName=(item:any)=>{
    return this.state.user_id  === item?.attributes?.account?.id ?"Me" : item.attributes.account.full_name.split(' ')[0]
}


  handleSvg=(value:string)=>{
    return  value  ? ArrowUp:ArrowDown;
         }

         getAllCategoriesData = async () => {
         
          const header = {
            "Content-Type": configJSON.validationApiContentType,
                "token": this.state.token
          };
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getCategoriesId = requestMessage.messageId
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_communityforum/community_forum_categories`
          );
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
       
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
           "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        };
        getAnimalData = async () => {
          const header = {
            "Content-Type": configJSON.validationApiContentType,
                "token": this.state.token

          };
         
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getAnimalId = requestMessage.messageId
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_categories/categories`
          );
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
         
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        };
        handlePage=(handleAnimal:string,handleCategory:string,createdByme:boolean|string,serachTopic:string)=>{
          if(handleAnimal!=="" || handleCategory!==""||createdByme||serachTopic!==""){
            this.setState({currentPage:1})
            return 1

          }
          else{
            return this.state.currentPage
          }
        }
        handleEndpoint=()=>{
           const  handleAnimal= !this.state.animalTypeOptionData.includes(0)?`&animal_type_ids=${JSON.stringify(this.state.animalTypeOptionData)}`:""
           const  handleCategory= !this.state.categoryTypeOptionData.includes(0)?`&community_forums_category_ids=${JSON.stringify(this.state.categoryTypeOptionData)}`:""
           const  serachTopic= this.state.serachTopic!=="" && this.state.serachTopic !==null && this.state.serachTopic!==undefined?`&keyword=${this.state.serachTopic}`:""
           const  createdByme:boolean|string= this.state.checkedByme?`&cretaed_by_me=${this.state.checkedByme}`:""
           const  handleCurrenPage= this.state.currentPage!==null?`&page=${this.handlePage(handleAnimal,handleCategory,this.state.checkedByme,serachTopic)}`:""
          const url=
          `/bx_block_communityforum/community_forums?sort_by=${configJSON.sortByMenuItem[this.state.sortBymenu].value}${createdByme}${handleCategory}${serachTopic}${handleAnimal}&per_page=${String(10)}${handleCurrenPage}`
        
       return url;
        }
        async componentDidMount() {

          super.componentDidMount();
          const chatBannerImage=await storage.get("chat_room_banner_image")
          if(chatBannerImage){
            this.setState({chatRoomBannerImage:chatBannerImage})
          }
          this.getAllCategoriesData()
          this.getAnimalData()
          const searchTopic= this.props.navigation?.history?.location?.state?.searchTopic;
          const createTopic= this.props.navigation?.history?.location?.state?.createTopic;
          const infomsg= this.props.navigation?.history?.location?.state?.infomsg;
         
          if(createTopic){
          setTimeout(() => {
            this.setState((state, props) => ({
              infoMessage: infomsg,
              createTopic: createTopic
            }), () => {
              setTimeout(() => {
                this.setState(() => ({
      
                  infoMessage: "",
                  createTopic: false
                }))
                this.props.navigation.history.replace({
                  ...this.props.navigation.history.location,
                  state: {
                    ...this.props.navigation.history.location.state,
                    createTopic: '', 
                  },
                });
              }, 5000);
            })
          }, 100);
        }
          if(searchTopic&&searchTopic!==""){
          
              this.setState({serachTopic:searchTopic})
          }
          const token=await storage.get("auth_token");
          this.setState({token:token})
         
          
          this.getAllTopicData()
          const topicpathid=window.location.pathname.split("/")
          const topic_id=topicpathid[topicpathid.length - 1]
                 
          if(topicpathid[1]==="community-topics-edit"){
            this.setState({topicId:parseInt(topic_id),pathName:"community-topics-edit"})
                        this.getSpecinficTopicData(parseInt(topic_id))
                      }
          const id=await storage.get("auth_user_id");
          const emptySearchImage=await storage.get("posts_screen_with_no_results_based_on_search_image")
          this.setState({user_id:parseInt(id)})
          this.setState({image:emptySearchImage})
          
        

      }
      handleForSearchEnterKey=(event:any)=>{
        if(event.key==='Enter'&&this.state.serachTopic!==""){

          this.props.navigation.navigate("PetootiCommunityFormTopic" ,{ searchTopic: this.state.serachTopic })
        }
      }
        getAllTopicData = async () => {
          const header = {
            "Content-Type": configJSON.validationApiContentType,
                "token":  this.state.token

          };
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getAllTopicId = requestMessage.messageId
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            this.handleEndpoint()
            );
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
           "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        };
        getSpecinficTopicData = async (id:number|null) => {
          const header = {
            "Content-Type": configJSON.validationApiContentType,
                "token":  this.state.token

          };
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.getSpecificTopiId = requestMessage.messageId
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_communityforum/community_forums/${id}`
            );
      
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
           "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        };          
  handleStyleBackground=(value:string)=>{
    return  value ?"#03B5AA":"#FFF";
     }
     gotocreatePost =() =>{
    };
  
     getAllUpadatedPost_Api = async () => {
      this.setState({ isLoading: true, refreshing: true });
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
  
      this.setState({ isLoading: true });
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      this.getAllCreatedPostApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createPostEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.GET
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    };

     handleClickAnimalSelect = () => {
      this.setState({ animaltypeMenuSelect: !this.state.animaltypeMenuSelect });
    };

    handleClick = () => {
      this.setState({ sortBymenuItem: !this.state.sortBymenuItem });
    };
    handleListKeyDownmenu(event: any, stateName: any) {
      if (event.key === 'Tab') {
        event.preventDefault();
        this.setState({ [stateName]: false }as Pick<any, keyof any>);
      }
     }

    handleCloseMenu = (e:any) => {
      if(e.target.id!='sortby'&&e.target.classList[0]!='MuiButton-label'){
      this.setState({ sortBymenuItem: false });
      }
    };
    handleClickCategorySelect = () => {
      this.setState({ categorytypeMenuSelect: !this.state.categorytypeMenuSelect });
    };

     handleStyleColor=(value:string)=>{
       return  value  ?"#FFF":"#334155"
         }

         handleMenu = (index:number) => {
          this.setState({ sortBymenu: index,sortBymenuItem:false });
        };
        handleMenuCategory = (index: number) => {
          const { categoryTypeOptionData } = this.state;
        
          if (index === 0) {
            this.setState({ categoryTypeOptionData: [0] });
          } else {
            const updatedOptions = categoryTypeOptionData.includes(index)
              ? categoryTypeOptionData.filter((item: any) => item !== index)
              : [...categoryTypeOptionData, index];
        
            if (updatedOptions.length === this.state.getCatergoryData.length-1 && !updatedOptions.includes(0)) {
              this.setState({ categoryTypeOptionData: [0] });
            } 
            else if (updatedOptions.length === 0) {
              this.setState({ categoryTypeOptionData: [0] });
            }
            else {
              this.setState({ categoryTypeOptionData: updatedOptions.filter((option:any) => option !== 0) });
            }
          }
        };
        handleMenuAnimal = (index: number) => {
          const { animalTypeOptionData } = this.state;
        
          if (index === 0) {
            this.setState({ animalTypeOptionData: [0] });
          } else {
            const updatedOptions = animalTypeOptionData.includes(index)
              ? animalTypeOptionData.filter((item: any) => item !== index)
              : [...animalTypeOptionData, index];
        
            if (updatedOptions.length === this.state.getAnimalData.length-1 && !updatedOptions.includes(0)) {
              this.setState({ animalTypeOptionData: [0] });
            } 
            else if (updatedOptions.length === 0) {
              this.setState({ animalTypeOptionData: [0] });
            }
            else {
              this.setState({ animalTypeOptionData: updatedOptions.filter((option:any) => option !== 0) });
            }
          }
        };




handleStylePlaceholder=(state:any)=>{
  return state === "Select category" ||state=="Select animal type" ? "#94A3B8" : "#334155"
 }
handleBorderContent=()=>{
  return this.state.contentTopicError?"1px solid #F87171":"1px solid #CBD5E1"
 }
 handleContentValidation=(e:any)=>{
  const inputValue = e.target.value.trim();
  const maxLength = 1000;
  if(inputValue.length==0){
    this.setState({ contentTopicError: true,contentTopic: e.target.value.substring(0, maxLength) })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({ contentTopic: e.target.value.substring(0, maxLength), contentTopicError: false })
}
}
handleErroMessageCategory=()=>{
  return this.state.categoryIdTopicError&& "Please select an option"
    }
    handleErroMessageAnimal=()=>{
      return this.state.animalIdTopicError&& "Please select an option"
        }

handleTitleValidation=(e:any)=>{
  const inputValue = e.target.value.trim();
  const maxLength = 100;
  if(inputValue.length==0 ){
    this.setState({ titleTopicError: true,titleTopic: e.target.value.substring(0, maxLength) })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({ titleTopic: e.target.value.substring(0, maxLength), titleTopicError: false })
}

}
handleOtherValidation=(e:any)=>{
  const inputValue = e.target.value.trim();
  const maxLength = 20;
  if(inputValue.length==0){
    this.setState({ animalTopicIdOtherError: true,animalTopicIdOther: e.target.value.substring(0, maxLength) })
  }
else  if ((inputValue.length > 0 || inputValue === ' ')) {
    this.setState({ animalTopicIdOther:  e.target.value.substring(0, maxLength) , animalTopicIdOtherError: false })
}

}
putUpdateTopic = async (animalId:{name:string,id:number},categoryId:{name:string,id:number}) => {
         
  const header = {
        "token": this.state.token
  };

  

  const formdata=new FormData();
  formdata.append("content",this.state.contentTopic)
  formdata.append("name",this.state.titleTopic)
  formdata.append("custom_animal_type",this.state.animalTopicIdOther)
  formdata.append("category_id", animalId?.id ? animalId?.id.toString() : '');
  formdata.append("community_forums_category_id",categoryId?.id.toString())
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.putEditTopic = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_communityforum/community_forums/${this.state.topicId}`
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );



  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "PUT"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

postCreateTopic = async (animalId:{name:string,id:number},categoryId:{name:string,id:number}) => {
         
  const header = {
        "token": this.state.token
  };

  

  const formdata=new FormData();
  formdata.append("name",this.state.titleTopic)
  formdata.append("content",this.state.contentTopic)
 { this.state.animalIdTopic !=="Other" &&formdata.append("category_id",animalId?.id.toString())}
  formdata.append("community_forums_category_id",categoryId?.id.toString())
  {this.state.animalIdTopic =="Other" && formdata.append("custom_animal_type",this.state.animalTopicIdOther)}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createTopicId = requestMessage.messageId

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_communityforum/community_forums`
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );



  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "POST"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};
handleValidationSpace = (maxLength: number, value: any) => {
  const inputValue = (typeof value === 'string' || value instanceof String) ? value.trim() : '';

  if (inputValue.length <= maxLength && inputValue.length > 0) {
    return false;
  } else {
    return true;
  }
}
handleValidationCreateNewTopic=()=>{
  const check = this.state.animalIdTopic == 'Other';
  const valueCheck = this.handleValidationSpace(50,this.state.animalTopicIdOther);

  const errors = {
    titleTopicError  : this.handleValidationSpace(100,this.state.titleTopic),
    contentTopicError  : this.handleValidationSpace(1000,this.state.contentTopic),
    categoryIdTopicError  : this.state.categoryIdTopic=="Select category"?true:false ,
    animalIdTopicError  :  this.state.animalIdTopic=="Select animal type"?true:false ,
    animalTopicIdOtherError:(check&&valueCheck)?true:false
  }
  //@ts-ignore
  this.setState(errors);
          const hasErrors = Object.values(errors).some(error => error);
          const animalId= this.state.animalIdTopic !=="Other" && this.state.getAnimalData.find((item:{name:string,id:number})=>{
            return item?.name==this.state.animalIdTopic
          })
        
          const categoryId=this.state.getCatergoryData.find((item:{name:string,id:number})=>{
            return item?.name==this.state.categoryIdTopic
          })
          if(!hasErrors){
            if(this.state.pathName!=="community-topics-edit"){

              this.postCreateTopic(animalId,categoryId)
            }
            else{
              this.putUpdateTopic(animalId,categoryId)
            }
          }
}
handleErroMessage=(state:any)=>{
  return state&& "Please enter a value"
    }

  // Customizable Area End
}
