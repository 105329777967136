import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  styled,
  TextField,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Backdrop,
  Snackbar
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

import Loader from "../../../components/src/Loader.web";

import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { checkBoxFalse, checkBoxTrue, dropdown ,RightIcon} from "../../email-account-registration/src/assets";
import  { DrawerItemStyle } from "../../email-account-registration/src/Components/Header.web";
import {EmptyPostCategori} from "../../../components/src/EmptyPost.web";
const baseUrl = require("../../../framework/src/config.js")
// Customizable Area End


import CategoriesController, { Props, configJSON, YourComponentState} from "./CategoriesController.web";
import { Autocomplete } from "@material-ui/lab";
import { Dog, DownArrow, LeftArrow, LightArrow, leftArrowres } from "./assets";
import { Cross } from "../../catalogue/src/assets";
import { CustomMenu } from "../../email-account-login/src/CataloguePage.web";



export default class Categories extends CategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  createOption = (selectedOption: any,name:any,optionsKey:any) => {
    const isFosteredOnly = optionsKey === "Fostered only";
    //@ts-ignore
    const options = this.state[name];
   
    return (
      <BoxItem
        style={{
          paddingBlock: "8px",
          maxHeight:"321px",
          overflowY:"auto",
          background:
            "linear-gradient(0deg, rgba(205, 240, 238, 0.20) 0%, rgba(205, 240, 238, 0.20) 100%), var(--0, #FFF)",
        }}
      >
        {selectedOption.map((item: any, index: number) => {
          const isChecked =
          isFosteredOnly
            ? this.state.fosteredonlyOption
            : (this.state as any)[name].includes(item.id);

         return  <Box key={index} style={{ display: "flex", padding: "12px 16px", cursor: "pointer" }} id={`testidClear${name}`} onClick={()=>{
    this.setState({page:1})

            this.handleToggleOption(item,name,selectedOption.length,optionsKey,options)
          }}>
             <img src={isChecked ? checkBoxTrue : checkBoxFalse} />
            <Typography style={{ ...Allstyle.optionStyle, lineHeight: "normal !important", marginLeft: "8px" }}> {item.name}</Typography>
          </Box>
  })}
      </BoxItem>
    );
  };
handleMenuOption=(id:number)=>{
  return this.options.map((item: any,index:number) => {
    const itemname =
      item.replace(/\s+/g, "").toLowerCase() + "Select";
    return (
      <Box
        style={{
          borderBottom: "1px solid #E2E8F0",
        }}
      >
        <Box
           onClick={() => {
            this.handleItemClick(item);
          }}
          style={{
            gap: "8px",
            justifyContent: "space-between",
            padding: "12px 16px",
            display: "flex",
            cursor: "pointer",
          }}
          id={`testid${index}${id}`}
       
        >
          <Typography style={{...Allstyle.optionStyle,color:this.styleColor(),fontFamily:"Inter",}}>{item}</Typography>

          {(this.state as any)[itemname] ? (
            
          <img src={this.state.open ?dropdown:DownArrow} />
          ) : (
            <img src={this.state.open ?leftArrowres:LeftArrow}  />
          )}
        </Box>
        {this.handleOption(item)}
      </Box>
    );
  })
}
  handleCheap=()=>{
    return <>
     { this.handleConditionOfClear() &&<Box style={{ display: "flex",alignItems:"center",marginTop:"10px",marginBottom:"16px", gap: "16px", flexWrap: 'wrap', width:"100%",
               }}>
                 {this.state.fosteredonlyOption && 
                  <Box key={"Show fostered pets only"} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="fostered" onClick={() => { this.setState({fosteredonlyOption:false}) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>
                      {"Show fostered pets only"}
                                          </Typography>
                  </Box>
              }
              {this.state.animaltypeOption && this.state.animaltypeOption.length > 0 && !this.state.animaltypeOption.includes(0) &&
                this.state.animaltypeOption.map((index: any) => {
                 
                  const search=this.state.animalOptionData.find((ele:any)=>ele.id === parseInt(index))
                  
                return  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test1" onClick={() => { this.clearOption('animaltypeOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>
                      {search?.name}
                    </Typography>
                  </Box>
  })
              }
              {this.state.breedOption && this.state.breedOption.length > 0 && !this.state.breedOption.includes(0) &&
                this.state.breedOption.map((index: any) => {
                  const search=this.state.breedAllData.find((ele:any)=>ele.id === parseInt(index))

                return  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }}  id="test2"  onClick={() => { this.clearOption('breedOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>
                      {search?.name}
                    </Typography>
                  </Box>
  })
              }
              {this.state.genderOption && this.state.genderOption.length > 0 && !this.state.genderOption.includes(0) &&
                this.state.genderOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }}  id="test3"  onClick={() => { this.clearOption('genderOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>
                      {configJSON.gender[index].name}
                    </Typography>
                  </Box>
                ))
              }

              {this.state.ageOption && this.state.ageOption.length > 0 && !this.state.ageOption.includes(0) &&
                this.state.ageOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test4"  onClick={() => { this.clearOption('ageOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>

                      {configJSON.age[index].name}
                    </Typography>
                  </Box>
                ))
              }
  
                 {this.state.goodinhomewithOption && this.state.goodinhomewithOption.length > 0 && !this.state.goodinhomewithOption.includes(0) &&
                this.state.goodinhomewithOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test5"  onClick={() => { this.clearOption('goodinhomewithOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>

                      {configJSON.goodHome[index].name}
                    </Typography>
                  </Box>
                ))
              }
                 {this.state.temperamentOption && this.state.temperamentOption.length > 0 && !this.state.temperamentOption.includes(0) &&
                this.state.temperamentOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test4"  onClick={() => { this.clearOption('temperamentOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>

                      {configJSON.temperament[index].name}
                    </Typography>
                  </Box>
                ))
              }
               {this.state.activitylevelOption && this.state.activitylevelOption.length > 0 && !this.state.activitylevelOption.includes(0) &&
                this.state.activitylevelOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test4"  onClick={() => { this.clearOption('activitylevelOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>

                      {configJSON.level[index].name}
                    </Typography>
                  </Box>
                ))
              }
               {this.state.currentlocationOption && this.state.currentlocationOption.length > 0 && !this.state.currentlocationOption.includes(0) &&
                this.state.currentlocationOption.map((index: any) => (
                  <Box key={index} style={{ display: "flex", padding: "4px 12px 4px 6px", gap: "4px", alignItems: "center", background: "#FEE2E2", borderRadius: "16px" }}>
                    <img src={Cross} style={{ cursor: "pointer" }} id="test5"  onClick={() => { this.clearOption('currentlocationOption', index) }} />
                    <Typography style={{...Allstyle.optionStyle,color:"var(--Primary-color-3, #FF5E5B)"}}>

                      {configJSON.location[index].name}
                    </Typography>
                  </Box>
                ))
              }
              {this.handleConditionOfClear() &&
                <Typography style={{...Allstyle.clearStyle,color:this.state.open?"#FFF":"#64748B"}} data-test-id="test5"  onClick={this.clearAllOptions}>
                  Clear all
                </Typography>
              }


            </Box>}
    </>
  }
handleOption = (item: string) => {
  const name = item.replace(/\s+/g, "").toLowerCase() + "Select" as keyof YourComponentState;
  const store = item.replace(/\s+/g, "").toLowerCase() + "Option" as keyof YourComponentState;

  const optionMappings: Record<string, any> = {
    "Animal type": this.state.animalOptionData,
    "Breed": this.state.breedOptionData,
    "Gender": configJSON.gender,
    "Age": configJSON.age,
    "Good in home with": configJSON.goodHome,
    "Temperament": configJSON.temperament,
    "Activity level": configJSON.level,
    "Current location": configJSON.location,
    "Fostered only":configJSON.fostered
  };

  return this.state[name] ? this.createOption(optionMappings[item], store,item) : null;
};

 styleColor=()=>{
return this.state.open ?"#FFF":"#0F172A";
}
 
  renderAutocompleteComponent = () => {
    return (
      <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "48px", borderBottom: this.state.showEmptyPage ? '' : "1px solid #CBD5E1" }}>
        <AutocompleteComponent
          freeSolo
          id="free-solo"
          onInputChange={ this.handleFreeSolo}
          options={[]}
          data-test-id='searchInputOuter'
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Search by keywords"
              margin="normal"
              value={this.state.searchKeyword}
              data-test-id='searchInput'
              onKeyDown={this.handleForSearchEnterKey}

              onChange={(e: any) => {
                this.setState({ searchKeyword: e.target.value })
                this.setState({page:1}) 

                if (e.target.value === '') {
  

                  this.handleSearch()
                  this.setState({showResultText:false,showText:''})
                }
              }}
              InputProps={{
                ...params.InputProps, type: 'search', startAdornment: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.1865 14.4713H15.9765L20.2165 18.7313C20.6265 19.1413 20.6265 19.8113 20.2165 20.2213C19.8065 20.6313 19.1365 20.6313 18.7265 20.2213L14.4765 15.9713V15.1813L14.2065 14.9013C12.8065 16.1013 10.8965 16.7213 8.86646 16.3813C6.08646 15.9113 3.86646 13.5913 3.52646 10.7913C3.00646 6.56133 6.56646 3.00133 10.7965 3.52133C13.5965 3.86133 15.9165 6.08133 16.3865 8.86133C16.7265 10.8913 16.1065 12.8013 14.9065 14.2013L15.1865 14.4713ZM5.47646 9.97133C5.47646 12.4613 7.48646 14.4713 9.97646 14.4713C12.4665 14.4713 14.4765 12.4613 14.4765 9.97133C14.4765 7.48133 12.4665 5.47133 9.97646 5.47133C7.48646 5.47133 5.47646 7.48133 5.47646 9.97133Z"
                      fill="#94A3B8"
                    />
                  </svg>
                ),
              }}
            />
          )}
        />

        <ButtonStyle variant="contained" data-test-id='searchButton' style={{ borderRadius: "8px" }} onClick={() => this.handleSearch()}>
          Search
        </ButtonStyle>
      </Box>
    )
  }
  renderGrid = () => {
    return (
      <Grid container>
        <POstBox style={{ marginTop: "48px", marginBottom: "49px", display: "flex", justifyContent: "space-between", width: "100%" }}>
          {this.state.showResultText?<Typography style={{
            color: "#334155",
            fontFamily: "Inter",
            fontSize: "30px",
            fontWeight: 400,
            lineHeight: "40px",
            visibility: this.state.showEmptyPage ? 'hidden' : "visible"
          }}>
           Showing results for <span style={{...Allstyle.searchKeyword}}>‘{this.state.showText}’</span>
          </Typography>:
          <Typography style={{
            color: "#334155",
            fontSize: "30px",
            fontFamily: "Inter",
            lineHeight: "40px",
            visibility: this.state.showEmptyPage ? 'hidden' : "visible",
            fontWeight: 400,
          }}>
          Showing all results
          </Typography>}
          <Typography style={{ ...Allstyle.hedingStyle, color: "#64748B" }}>{this.state.totalPost}{this.state.totalPost!==1?" Posts":" Post"} </Typography>
        </POstBox>
        {!this.state.showEmptyPage && <POstBox style={{ marginBottom: "25px", display: "flex", justifyContent: "space-between", width: "100%" }}>
          <TypoFilter style={{ ...Allstyle.hedingStyle, fontFamily: "Inter", color: "#64748B", fontWeight: 700 }}>FILTERS</TypoFilter>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              cursor: "pointer",
            }}
            //@ts-ignore
            ref={this.state.sortItemRef}
            aria-haspopup="true"
            aria-controls={this.state.sortItem ? 'menu-list-grow' : undefined}
            onClick={this.handleClick}

          >
            <span
              style={{
                ...Allstyle.sortStyle,
                marginRight: "4px",
                fontWeight: 400,
              }}
            >
              Sort by:
            </span>
            <Typography
              style={{
                ...Allstyle.sortStyle,
                fontWeight: 700,
                display: "flex",
                cursor: "pointer",
              }}
            >
              {configJSON.menuItem[this.state.sortItemOption]?.name}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                  fill="#334155"
                />
              </svg>
            </Typography>
          </Box>
          <Popper open={this.state.sortItem} anchorEl={this.state.sortItemRef.current} role={undefined} transition disablePortal style={{ zIndex: 1111 }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper style={{ width: "274px", borderRadius: "8px", background: "#FFF" }}>
                  <ClickAwayListener onClickAway={this.handleCloseMenu}>
                    <CustomMenu autoFocusItem={Boolean(this.state.sortItem)} id="menu-list-grow" onKeyDown={(event: any) => this.handleListKeyDownmenu(event, 'sortItem')}>
                      {configJSON.menuItem.map((item: any, index: number) => {
                        return (
                          <MenuItem
                            onClick={() => this.handleMenu(index)}
                            style={{
                              ...Allstyle.menuItemStyle,
                              background:
                                this.state.sortItemOption == index
                                  ? "linear-gradient(0deg, rgba(205, 240, 238, 0.50) 0%, rgba(205, 240, 238, 0.50) 100%), #FFF"
                                  : "",
                            }}
                          >
                            {this.state.sortItemOption == index ? (
                              <img src={RightIcon} />
                            ) : (
                              <span
                                style={{
                                  marginRight: "8px",
                                  width: "24px",
                                  height: "24px",
                                }}
                              ></span>
                            )}
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </CustomMenu>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </POstBox>}
      </Grid>
    )
  }
  renderMainGrid = () => {
    return (
      !this.state.showEmptyPage && <MainGRID
        style={{
          display: "flex",
          gap: "24px",
        }}
      >
        <FirstBox

        >
          {
            this.handleCheap()
          }
          <Box style={{
            borderTop: "1px solid #E2E8F0",
            

          }}key={''}>

            {this.handleMenuOption(1)}
          </Box>
        </FirstBox>

        <ItemGrid container style={{ height: "fit-content",marginBottom:"44px", gap: "24px",justifyContent:"center" }}>
          {this.state.petsData.map((item: any, index: any) => {
            return (
              <Grid xs={12} md={3} sm={3} item style={{...Allstyle.itemStyle,cursor:"pointer"}} key={index}   >
                <Box
                  style={{ ...Allstyle.itemImageStyle, position: "relative" }}
                >
                  <img src={`${baseUrl.baseURL}${item.attributes.image.url}`} style={Allstyle.itemImageStyle}  onClick={()=>{
                    this.handleNavigation(item?.attributes?.account_id,item.attributes.id)
                  }}/>
                 {this.state.auth_user_id!=item.attributes?.account_id && <span
                    style={{
                      padding: "5px",
                      borderRadius: "10px",
                      position: "absolute",
                      cursor: "pointer",
                      top: 17,
                      width: "30px",
                      height: "30px",
                      alignItems: "center",
                      background: "rgba(10, 34, 57, 0.40)",
                      justifyContent: "center",
                      display: "flex",
                      right: 17,
                    }}
                   
                  >
                    {!item.attributes.favourites ? <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none" 
                      data-test-id='heartIconFav'
                      onClick={()=>this.handleFavorite(item.id,item.attributes.favourites )}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.0002 6.58402C17.2002 4.00902 21.2752 2.95902 24.5752 5.20902C26.3252 6.40902 27.4252 8.43402 27.5002 10.559C27.6627 15.409 23.3752 19.2966 16.8127 25.2466L16.6752 25.3716C15.7252 26.2466 14.2628 26.2466 13.3128 25.3841L13.1878 25.2716L13.1125 25.2032C6.59241 19.2793 2.32594 15.403 2.50027 10.5715C2.57527 8.43402 3.67527 6.40902 5.42527 5.20902C8.72527 2.94652 12.8003 4.00902 15.0002 6.58402ZM15.0003 23.5332L15.1253 23.4082C21.0753 18.0207 25.0003 14.4582 25.0003 10.8457C25.0003 8.3457 23.1253 6.4707 20.6253 6.4707C18.7003 6.4707 16.8253 7.7082 16.1753 9.4207H13.8378C13.1753 7.7082 11.3003 6.4707 9.37531 6.4707C6.87531 6.4707 5.00031 8.3457 5.00031 10.8457C5.00031 14.4582 8.9253 18.0207 14.8753 23.4082L15.0003 23.5332Z"
                        fill="white"
                      />
                    </svg> :
                    <svg width="40"data-test-id='heartIconRemoveFav'    onClick={()=>this.handleFavorite(item.id,item.attributes.favourites )} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 30.4063C19.7083 30.4063 19.4115 30.3542 19.1094 30.25C18.8073 30.1458 18.5417 29.9792 18.3125 29.75L16.1562 27.7813C13.9479 25.7604 11.9531 23.7552 10.1719 21.7656C8.39062 19.776 7.5 17.5833 7.5 15.1875C7.5 13.2292 8.15625 11.5938 9.46875 10.2813C10.7813 8.96875 12.4167 8.3125 14.375 8.3125C15.4792 8.3125 16.5208 8.54688 17.5 9.01563C18.4792 9.48438 19.3125 10.125 20 10.9375C20.6875 10.125 21.5208 9.48438 22.5 9.01563C23.4792 8.54688 24.5208 8.3125 25.625 8.3125C27.5833 8.3125 29.2188 8.96875 30.5313 10.2813C31.8438 11.5938 32.5 13.2292 32.5 15.1875C32.5 17.5833 31.6146 19.7813 29.8438 21.7813C28.0729 23.7813 26.0625 25.7917 23.8125 27.8125L21.6875 29.75C21.4583 29.9792 21.1927 30.1458 20.8906 30.25C20.5885 30.3542 20.2917 30.4063 20 30.4063Z" fill="#FF5E5B"/>
                      </svg>
                    }
               
                  </span>}
                 
                </Box>
                <Box
                  style={{
                    padding: "12px 16px 16px 16px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={()=>{
                    this.handleNavigation(item?.attributes?.account_id,item.attributes.id)
                  }}
                >
                  <Typography
                    style={{
                      ...Allstyle.subHeadingItemStyle,
                      marginBottom: "4px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    <span style={Allstyle.headingItemStyle}>{item.attributes.name} - </span>
                    {item.attributes.hook_line}
                  </Typography>
                  <Typography
                    style={{
                      ...Allstyle.hedingStyle2,
                      marginBottom: "4px",
                      color: "#64748B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {item.attributes.category.name}, {item.attributes.sub_category.name}
                  </Typography>

                  <Box
                    style={{
                      display: "flex",
                      gap: "16px",
                    }}
                  >
                    <Typography style={{ ...Allstyle.styleText }}>
                      {item.attributes.gender}
                    </Typography>
                    |
                    <Typography data-test-id="ageText" style={{ ...Allstyle.styleText }}>
                      {this.dateConverter(item.attributes.age )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })
          
        }

      { this.handlePostEmpty() && <EmptyPostCategori navigation={''} image={this.state.emptyImage}/>}
        </ItemGrid>
      </MainGRID>
    )
  
}

handleRetrunSnackBar=()=>{
  return(
    <Backdrop
    open={this.state.isSnackOpen}
    style={{ zIndex: 700 }}
    data-test-id='backdrop1'

  ><SnackBarStyle
    anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    open={this.state.isSnackOpen}
    onClose={()=>this.setState({messageFav:'',isSnackOpen:false})}
    data-test-id='snackbar1'
    autoHideDuration={3000}
    style={{ zIndex: 1000 }}

  >
      <Typography style={{...Allstyle.popup as React.CSSProperties}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13 23.3996C18.7438 23.3996 23.4 18.7434 23.4 12.9996C23.4 7.25585 18.7438 2.59961 13 2.59961C7.25622 2.59961 2.59998 7.25585 2.59998 12.9996C2.59998 18.7434 7.25622 23.3996 13 23.3996ZM17.8192 11.3188C18.3269 10.8112 18.3269 9.98805 17.8192 9.48037C17.3116 8.97269 16.4884 8.97269 15.9808 9.48037L11.7 13.7611L10.0192 12.0804C9.51154 11.5727 8.68842 11.5727 8.18074 12.0804C7.67306 12.588 7.67306 13.4112 8.18074 13.9188L10.7807 16.5188C11.2884 17.0265 12.1115 17.0265 12.6192 16.5188L17.8192 11.3188Z" fill="#34D399" />
        </svg>
        {this.state.messageFav}
      </Typography>
    </SnackBarStyle>
    </Backdrop> 
  )
}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
         {this.handleRetrunSnackBar()}
        
        <Loader loading={this.state.isLoading } />

        <HomeGrid style={{width:this.state.showEmptyPage?'100%':''}}>
          <Box style={{ ...Allstyle.headingBoxStyle }}>
            <Box style={{ display: "flex" }}>

              <Typography data-test-id='backToHome' style={{ ...Allstyle.hedingStyle, paddingInline: "6px", cursor: "pointer" }} onClick={()=>{
      this.props.navigation.navigate("LandingPageWeb")
    }}>
                Home
              </Typography>
              <img src={LightArrow}   style={{
                width:"20px",height:"20px"
                }}/>

              <Typography
                style={{
                  ...Allstyle.hedingStyle,
                  color: "#0F172A",
                  paddingInline: "6px",
                }}
              >
                Search results
              </Typography>
            </Box>
            <FilterStyledCom>
              <Typography data-test-id="test2"style={{ ...Allstyle.hedingStyle, fontFamily:"Inter",color: "#64748B", fontWeight: 700, cursor: "pointer" }} onClick={()=>
                this.handleFilterOpen()
              }>FILTERS</Typography>

            </FilterStyledCom>
          </Box> 
          {this.renderAutocompleteComponent()}
        
         {this.renderGrid()}
        {this.renderMainGrid()}
          { this.state.petsData.length>0 && <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "20px", }}>

            <PaginationComponent data-test-id='paginationComp' page={this.state.page} count={this.state.totalPage} onChange={this.handleChangePage}/>
          </Box>}
          { !this.state.showEmptyPage&&    <DrawerItemStyle
            variant="persistent"
            anchor="right"
            open={this.state.open}

          >
            <div >
              <IconButton data-test-id="closeDrawer" onClick={()=>this.handleDrawerClose()}>
                <CancelIcon />
              </IconButton>
            </div>

            <Box style={{padding:"10px"}}>
            {
                this.handleCheap()
              }
              {this.handleMenuOption(2)}
            </Box>

          </DrawerItemStyle>}
        
          
        </HomeGrid>  {this.state.showEmptyPage&&  <EmptyPostCategori  bottom={"12.75rem"} navigation={''} image={this.state.emptyImage}/>}
        
      </>
      // Customizable Area End 
    )
  }
}
// Customizable Area Start
const BoxItem=styled(Box)({
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 5px #E2E8F0",
    borderRadius: "6px"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#94A3B8",
    borderRadius: "6px",
  },
})
const POstBox = styled(Box)({
  '@media (max-width:600px)': {
    flexWrap: "wrap"
  },
})

const ItemGrid=styled(Grid)({
  maxWidth:"894px",
  minWidth:"894px",
  '@media (max-width:1208px)': {
    justifyContent: "center",
    minWidth:"unset",
  },
 
})

const TypoFilter = styled(Typography)({
  '@media (max-width:890px)': {
    display: "none"
  },
})
const MainGRID = styled(Box)({
  '@media (max-width:890px)': {
    flexWrap: "wrap"
  },
})
const FilterStyledCom = styled(Box)({
  display: "none",

  '@media (max-width:890px)': {
    display: "flex"
  },
})
const FirstBox = styled(Box)({
  minWidth: "274px",
  maxWidth:"274px",
  maxHeight: "1560px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    borderRadius: "10px"
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#848FAC80",
    borderRadius: "10px",
  },
  '@media (max-width:890px)': {
    display: "none"
  },
});
const PaginationComponent = styled(Pagination)({
  marginBottom: "120px",
  '& .MuiPaginationItem-page.Mui-selected': {
    color: "#03B5AA",
    background: "unset",
    fontWeight: 700

  },
  '& .MuiPaginationItem-root': {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400
  },
  '@media(max-width: 1200px)': {
    marginBottom: "60px",
  },
  '@media(max-width: 800px)': {
    marginBottom: "40px",
  },
  '@media(max-width: 600px)': {
    marginBottom: "20px",
  },
})
export const AutocompleteComponent = styled(Autocomplete)({
  width: "644px",
  marginRight: "16px",
  "& .MuiFormControl-marginNormal":{
    marginTop:"unset",
    marginBottom:"unset",
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
    padding: "0px 0px 0px 16px"
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
    padding: "18px 16px 18px 9px"
  },
  "& .MuiOutlinedInput-root": {
    height: "56px",

    "&:hover fieldset": {
      borderColor: "#CBD5E1",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#CBD5E1",
    },
  },
  "& .MuiInputBase-root": {
    color: "#334155"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CBD5E1",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiAutocomplete-endAdornment":{
    display:"none"
  }

})

const SnackBarStyle = styled(Snackbar)({
  "&.MuiSnackbar-anchorOriginTopCenter":{
    top:"113px",
  }
  })
const ChipBox=styled(Box)({
  padding: '2px 8px  2px 8px',
  background:"#F0E5FF",
  borderRadius:"40px",
  position:"absolute" as "absolute",
  left:17,
  top:17,
  height:'22px',
  width:'77px',
  display:'flex',
  alignItems:'center',
  justifyContent:'center'
})

export const Allstyle = {
  searchKeyword:{
    fontFamily:'Inter' as 'Inter',
    fontWeight: 700,
    fontSize:'30px',
    lineHeight:'40px'
  },
  popup: {
    backgroundColor: "#ffffff",
    display: "flex",
    gap:"8px",
   
    textAlign:"center",
    color: "#0F172A",
    borderRadius: "8px",
    padding:"9px 16px 9px 8px ",
    
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,

    lineHeight: "24px",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
  
  },

  chipStyle : {
    color: 'var(--Primary-Purple-500, #83F)',
    fontFamily: 'Lato' as 'Lato',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '18px', 
    textTransform: 'uppercase' as 'uppercase',
   
  },
  mainGridStyle: {
    marginTop: "48px",
    maxWidth: "1168px",
    marginInline: "auto"
  },
  menuItemStyle: {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    padding: "12px 16px"
  },
  styleText: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  StrategyStyle: {
    color: "#64748B",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "6px",
  },
  statusStyle: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: "0.14px",
  },

  headingItemStyle: {
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  hedingStyle: {
    color: "#475569",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  subHeadingItemStyle: {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  optionStyle: {
    color: "#0F172A",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },

  itemStyle: {
    boxShadow:
      "0px 9.067px 36.269px 0px rgba(0, 0, 0, 0.06), 0px 4.534px 9.067px 0px rgba(0, 0, 0, 0.03)",
    minWidth: "274px",
    minHeight: "342px",
    maxHeight: "342px",
    maxWidth:"274px",
    borderRadius: "8px",
  },
  itemImageStyle: {
    objectFit: "cover" as "cover",
    width: "274px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "230px",
  },
  clearStyle: {
    fontWeight: 700,
    cursor: "pointer",
    fontFamily: "Lato",
    lineHeight: "22px",
    fontSize: "14px",
  },
 
  statusSTyle: {
    color: "#D97706",
    padding: "2px 8px",
    justifyContent: "center",
    borderRadius: "48px",
    alignItems: "center",
    fontFamily: "Lato",
    background: "#FEF3C7",
    fontWeight: 700,
    display: "flex",
    fontSize: "12px",
    lineHeight: "18px",
  },
  nameSTyle: {
    fontFamily: "Lato",
    lineHeight: "26px",
    fontWeight: 700,
    color: "#0F172A",
    fontSize: "18px",
  },
  headingBoxStyle: {
    flexDirection: "row" as "row",
    justifyContent: "space-between",
    display: "flex" as "flex",
  },
  hedingStyle2: {
    lineHeight: "22px",
    color: "#475569",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  mainHedingStyle: {
    fontWeight: 700,
    letterSpacing: "-0.15px",
    lineHeight: "40px",
    fontFamily: "Lato",
    color: "#0F172A",
    fontSize: "30px",
  },
  btnStyle: {
    color: "#FFF",
    fontFamily: "Lato" as "Lato",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    height: "44px",
    width: "163px",
    borderRadius: "8px",
    padding: "10px 16px",
    background: "#03B5AA",
    textTransform: "inherit" as "inherit",
  },
 
  optionBtnStyle: {
    color: "#334155",
    fontSize: "16px",
    fontFamily: "Lato" as "Lato",
    borderRadius: "8px",
    fontWeight: 400,
    textTransform: "inherit" as "inherit",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    height: "44px",
    padding: "4px 8px 4px 16px",
  },
  sortStyle: {
    color: "#334155",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Inter",
  },

  subImgStyle: {
    maxWidth: "274px",
    boxShadow:
    "0px 9.067px 36.269px 0px rgba(0, 0, 0, 0.06), 0px 4.534px 9.067px 0px rgba(0, 0, 0, 0.03)",
    marginBottom: "24px",
    borderRadius: "8px",
  },
};

export const ButtonStyle = styled(Button)({
  minWidth: "112px",
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  color: "#FFF",
  height: "56px",
  textTransform: "inherit",
  background: "#03B5AA",
  "&.MuiButton-contained": {
    boxShadow: "unset"
},
  "&.MuiButton-contained:hover": {
    boxShadow: "unset",
    backgroundColor: "#03B5AA",
  }
})
const HomeGrid = styled(Grid)({
  marginTop: "48px",
  maxWidth: "1168px",
  marginInline: "auto",
  '@media(max-width: 1209px)': {
    maxWidth: "unset",
    marginInline: "20px",
  },
  '@media(max-width: 400px)': {
    maxWidth: "unset",
    marginInline: "10px",
  },

})
// Customizable Area End
