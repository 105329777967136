import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface commentArray {
  comment: string;
  commentable_type: string;
}
import storage from "../../../framework/src/StorageProvider";

interface reportReasonArray {
  id: number;
  name: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  showModalOpen:boolean
  commentData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  comment: string;
  commentsArray: any;
  replyCommentId: any;
  edit: boolean;
  updateId: number | null
  updateComment: string;
  perPage: number
  openDeletePopup: boolean
  commentDeleteId: any
  snackbarMessage: string
  newToken: any;
  showSnackbar: boolean
  topicId: number | null;
  topicDetail: any;
  user_id: number | null;
  reporte_pop_up: boolean;
  report_brief: string;
  report_reason: string;
  report_reason_data: {
    name: string,
    id: number
  }[],
  report_reasonError: boolean;
  otherreportError: boolean
  deleteTopiPopUp: boolean
  reportReasonArray: reportReasonArray[]
  profileData: any;
  openReportPopup: boolean;
  selectedReportReason: string;
  reportDescription: string;
  reportDescriptionError: boolean;
  reportReasonError: boolean;
  selectedReportId: any;
  commentReportableId: any
  focusUpdateInput: boolean
  totalCount: number,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class CommentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCommentItemCallId: string = "";
  commentApiCallId: string = "";
  likeCommentId: string = "";
  commentApiCallWebID: string = "";
  postCommnetApiCall: string = "";
  deleteCommentApiCall: string = "";
  putCommnetApiCall: string = "";
  getTopicDetailsId: string = "";
  deleteTopicId: string = ""
  reportCommentApiCall: string = "";
  getReportReasonsApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      showModalOpen:false,
      errorMsg: "",
      token: "",
      newToken: "",
      loading: false,
      comment: "",
      commentsArray: [] as commentArray[],
      reportReasonArray: [],
      replyCommentId: null,
      edit: false,
      deleteTopiPopUp: false,
      updateId: null,
      report_reasonError: false,
      updateComment: "",
      openDeletePopup: false,
      commentDeleteId: null,
      snackbarMessage: "",
      showSnackbar: false,
      perPage: 10,
      topicId: null,
      topicDetail: {},
      user_id: null,
      reporte_pop_up: false,
      report_brief: "",
      report_reason: "Select reason",
      report_reason_data: [],
      otherreportError: false,
      profileData: {},
      openReportPopup: false,
      selectedReportReason: 'Select reason',
      reportDescription: "",
      selectedReportId: null,
      commentReportableId: null,
      focusUpdateInput: false,
      reportDescriptionError: false,
      reportReasonError: false,
      totalCount: 0,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    } else {
      this.getToken();
    }
    // Customizable Area Start
 
    const createTopic = this.props.navigation?.history?.location?.state?.createTopic
    const infomsg = this.props.navigation?.history?.location?.state?.infomsg
    if(createTopic=="true"){
      setTimeout(() => {
        this.setState((state, props) => ({
          snackbarMessage: infomsg,
          showSnackbar: createTopic
        }), () => {
          setTimeout(() => {
            this.setState(() => ({

              snackbarMessage: "",
              showSnackbar: false
            }))
            this.props.navigation.history?.replace({
              ...this.props.navigation.history?.location,
              state: {
                ...this.props.navigation.history?.location?.state,
                createTopic: '', 
              },
            });
          }, 5000);
        })
      }, 100);
    }
    const userId=await storage.get("auth_user_id")
    const token= await storage.get("auth_token");
    const id=window.location.pathname.split("/")
    const topic_id=id[id.length - 1] 
    this.setState({topicId:parseInt(topic_id),user_id:userId,token:token})
    userId &&this.setState({user_id:userId})
    this.getReportReasons()
     this.userProfile()
    this.getTopicDetailsData(parseInt(topic_id))
    this.getCommentApiCall(parseInt(topic_id));
    this.setState({ token: token })
    this.userProfile()
    userId && this.setState({ user_id: userId })
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getCommentData();
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      switch (apiRequestCallId) {
        case this.getReportReasonsApiId:
          this.setState({ reportReasonArray: responseJson.data })
          break;
        case this.reportCommentApiCall:
          setTimeout(() => {
            this.setState((state, props) => ({
              snackbarMessage: "COMMENT REPORTED! Your report has been successfully submitted.",
              showSnackbar: true
            }), () => {
              setTimeout(() => {
                this.setState(() => ({
                  snackbarMessage: "",
                  showSnackbar: false
                }))
              }, 5000);
            })
          }, 100);
          this.setState({
            reportDescription: "",
            selectedReportReason: 'Select reason',
          })
          this.getCommentApiCall(this.state.topicId)
          break;
        case this.commentApiCallWebID:
          this.HandleResponseData(responseJson)
          break;
        case this.postCommnetApiCall:
          this.handlepostCommnetApiCallresponse(responseJson)

          break;
        case this.deleteCommentApiCall:
          setTimeout(() => {
            this.setState((state, props) => ({
              snackbarMessage: "COMMENT GONE! Like it was never even there...",
              showSnackbar: true
            }), () => {
              setTimeout(() => {
                this.setState(() => ({

                  snackbarMessage: "",
                  showSnackbar: false
                }))
              }, 5000);
            })
          }, 100);
          this.getCommentApiCall(this.state.topicId);
          break;
        case this.putCommnetApiCall:
          if (responseJson ) {
            setTimeout(() => {
              this.setState((state, props) => ({
                snackbarMessage: "COMMENT UPDATED! Your comment has been successfully edited.",
                showSnackbar: true
              }), () => {
                setTimeout(() => {
                  this.setState(() => ({
                    snackbarMessage: "",
                    showSnackbar: false
                  }))
                }, 5000);
              })
            }, 100);
            this.setState({
              updateComment: "",
              updateId: null,
              edit: false
            })
            this.getCommentApiCall(this.state.topicId);
          }
          break;
        case this.getTopicDetailsId:
          this.setState({ topicDetail: responseJson?.data })
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if focusInput changed from false to true
    if (this.state.focusUpdateInput && !prevState.focusUpdateInput) {
      // Focus on the input element
      const input = window.document.getElementById('update-comment-input');
      if (input) {
        input.focus();
      }
    }
  }


 
  handlepostCommnetApiCallresponse = (responseJson: any) => {
    if (responseJson) {
      this.setState({showModalOpen:true})
      setTimeout(() => {
        this.setState((state, props) => ({
          snackbarMessage: "Comment is created successfully",
          showModalOpen:true
        }), () => {
          setTimeout(() => {
            this.setState(() => ({
              snackbarMessage: "",
            }))
          }, 1500);
        })
      }, 100);
      this.setState({
        comment: "",
      })
      this.getCommentApiCall(this.state.topicId);
    }
  }


  getCommentData(): boolean {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCommentItemCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.commentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  validationSpace = (maxLength: number, value: any) => {
    const inputValue = (typeof value === 'string') ? value.trim() : '';

    if (inputValue.length <= maxLength && inputValue.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  handleStylePlaceholder = (state: any) => {
    return state === "Select reason" ? "#94A3B8" : "#1E293B"
  }
  async getTopicDetailsData(id: number | null) {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_communityforum/community_forums/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  
  setComment = (comment: string) => {
    this.setState({ comment });
  };

  likeChildComments = (commentId: any) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: this.state.token,
    };
    const data = {
      attributes: {
        comment_id: commentId,
      },
    };
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.likeCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.likeCommentEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getCommentApiCall = (id: number | null) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token:this.state.token,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.commentApiCallWebID = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsApi}?topic_id=${id}&page=1&per_page=${this.state.perPage}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  postCommentApiCall = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("auth_token"),
    };
    const body = {
      commentable_id: this.state.topicId,
      comment: this.state.comment,
      commentable_type: "BxBlockCommunityforum::CommunityForum"
    }
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCommnetApiCall = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsApi}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  handleInputChange = (e: any) => {
    const trimmedValue = e.target.value.replace(/^\s+/g, '');

    const sanitizedValue = trimmedValue.replace(/\s+/g, ' ');
    this.setState({
      comment: sanitizedValue,
    })
   
      if (e.key === 'Enter') {
        this.postCommentApiCall();
    } 
  }
  handleUpdateComment = (e: any) => {
    const trimmedValue = e.target.value.replace(/^\s+/g, '');

    const sanitizedValue = trimmedValue.replace(/\s+/g, ' ');
    this.setState({
      updateComment: sanitizedValue,
    })
    
    if (e.key === "Enter") {
      this.updateCommentApiCall();
    }
  }
  getCommentText(comment: any) {
    try {
      return JSON.parse(comment.replace("=>", ":"))?.text;
    } catch {
      return comment;
    }
  }
  handleEdit = (elm: any) => {
    this.setState({ edit: true, updateComment: elm.attributes?.comment, updateId: elm.attributes?.id, focusUpdateInput: true })
  }

  handleDelete = (id: number) => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("auth_token"),
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCommentApiCall = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsApi}/${id}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentDeleteAPiMethod
    );
    runEngine.sendMessage(message.id, message);
    return true;

  }
  updateCommentApiCall = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("auth_token"),
    };
    const body = {
      comment: this.state.updateComment,
    }
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.putCommnetApiCall = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCommentsApi}/${this.state.updateId}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPutAPiMethod
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  getReportReasons = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportReasonsApiId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReportReasonsApi}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );
    runEngine.sendMessage(message.id, message);
    return true;
  }

  handleReportComment = () => {
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: localStorage.getItem("auth_token"),
    };
    const body = {
      reportable_id: this.state.commentReportableId,
      reportable_type: "BxBlockComments::Comment",
      report_reason_id: this.state.selectedReportId,
      description: this.state.reportDescription
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportCommentApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reportCommentApi}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleDeletePopUp = () => {
    this.setState({
      openDeletePopup: false
    })
  }
  handleDeletePopUpOpen = (id: any) => {
    this.setState({
      openDeletePopup: true,
      commentDeleteId: id
    })
  }

  handleDeleteCommentPopup = () => {

    this.setState({
      openDeletePopup: false
    }, () => {
      this.handleDelete(this.state.commentDeleteId)
    });

  }


  handleLoadMore = () => {
    this.setState(() => ({
      perPage: this.state.perPage + 10,
    }), () => {
      this.getCommentApiCall(this.state.topicId);
    });
  }

  handleReportSelection = (event: any) => {
    const selectedValue = event.target.value;
    const selectedReason = this.state.reportReasonArray.find((reason) => reason.name === selectedValue);
    this.setState({
      selectedReportReason: selectedValue,
      selectedReportId: selectedReason ? selectedReason.id : null,
      reportDescriptionError: false,
      reportReasonError: false
    });
  };

  handleReportDescription = (e: any) => {
    const inputValue = e.target.value.trim();
    const maxLength = 500;

    this.setState({ reportDescription: e.target.value.substring(0, maxLength) })
    if (this.state.selectedReportReason == "Other") {
      if (inputValue.length == 0) {
        this.setState({ reportDescriptionError: true, })
      }
      else {
        this.setState({ reportDescriptionError: false })
      }
    }
  }

  handleReportPopup = () => {
    this.setState({
      openReportPopup: false,
      reportDescriptionError: false,
      reportReasonError: false,
      selectedReportId: '',
      selectedReportReason: 'Select reason',
      reportDescription: ''
    });

  };
  handleValidationPopup = () => {
    const errors = {
      reportReasonError: this.state.selectedReportReason == "Select reason" ? true : false,
      reportDescriptionError: (this.validationSpace(500, this.state.reportDescription) && this.state.selectedReportReason == "Other") ? true : false
    }
    this.setState(errors);
    const hasErrors = Object.values(errors).some(error => error);
    if (!hasErrors) {
      this.setState({ openReportPopup: false })
      this.handleReportComment()
    }
  }

  handleOpenReportPopup = (elm: any) => {
    this.setState({ openReportPopup: true, commentReportableId: !elm.attributes.created_by_me ? elm.id : null });
  };

  HandleResponseData = (response: any) => {
    this.setState({ commentsArray: response.data, totalCount: response.meta?.total_comments })
  }

  userProfile = async () => {
    const userData = await getStorageData("userDetail")
    let userDetailMain = JSON.parse(userData)
    this.setState({ profileData: userDetailMain })
  }
  handleCancelEdit = () => {
    this.setState({ edit: false })
  }

  handleResetAllEvent = () => {
    this.setState({ updateComment: '', edit: false, focusUpdateInput: false, updateId: null })
  }
handleCloseShowModal=()=>{
  this.setState({showModalOpen:false})
}
  // Customizable Area End
}
